import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	addValue,
	getPartyInfo,
	getSelectedCase,
	getSingleParty,
	removeValue,
	resetDoc,
	resetValue,
	setToggle,
	setToggle_2,
	toggleState,
	toggleState_2,
} from '../../../states/slices/partyInfo.slice';
import { TableComponent } from '../../../components/table/Table';
import {
	HEARING_COLUMNS,
	PARTY_COLUMNS,
	PARTY_COLUMNS_view,
	SINGLE_CASE_COLUMNS,
} from '../../../components/table/columns';
import Typography from '../../../components/Typography';
import { CHEVRON_LEFT, FLAG, MINUS, PDF, VIDEO } from '../../../assets';
import { formatCurrency, getJSONheader, sumPrices } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Api from '../../../config';
import { useGetPaymentsQuery } from '../../../services/apis/cases';
import { toast } from 'react-hot-toast';
import PartyInformation from '../../../components/modals/Partinformation';
import FillingFee from '../../../components/modals/FillingFee';
import { selectUser } from '../../../states/slices/authReducer';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Modal from '../../../components/UI/modal';
import { IconButton, StepConnector, StepIconProps, stepConnectorClasses } from '@mui/material';
import { FaPlusCircle, FaUsers } from 'react-icons/fa';
import Input from '../../../components/form/Input';
import { Form, Formik } from 'formik';
import { MdGroupAdd } from 'react-icons/md';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { handleHearing } from '../../../services/auth.service';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
	[`&.${stepConnectorClasses.alternativeLabel}`]: {
		top: 22,
	},
	[`&.${stepConnectorClasses.active}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)',
		},
	},
	[`&.${stepConnectorClasses.completed}`]: {
		[`& .${stepConnectorClasses.line}`]: {
			backgroundImage:
				'linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)',
		},
	},
	[`& .${stepConnectorClasses.line}`]: {
		height: 3,
		border: 0,
		backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
		borderRadius: 1,
	},
}));

const ColorlibStepIconRoot = styled('div')<{
	ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
	zIndex: 1,
	color: '#fff',
	width: 50,
	height: 50,
	display: 'flex',
	borderRadius: '50%',
	justifyContent: 'center',
	alignItems: 'center',
	...(ownerState.active && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	}),
	...(ownerState.completed && {
		backgroundImage:
			'linear-gradient( 136deg, rgb(30, 6, 245) 0%, rgb(1, 3, 69) 50%, rgb(0, 85, 255) 100%)',
	}),
}));

function ColorlibStepIcon(props: StepIconProps) {
	const { active, completed, className } = props;

	const icons: { [index: string]: React.ReactElement } = {
		1: <MdGroupAdd />,
		// 2: <GoLaw />,
		2: <FaUsers />,
	};

	return (
		<ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
			{icons[String(props.icon)]}
		</ColorlibStepIconRoot>
	);
}

const steps = ['Meeting  Details', 'Participants Involved'];

function ProcessPayment() {
	const getCase = useAppSelector(getSelectedCase);
	const user = useAppSelector(selectUser);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const navigate = useNavigate();
	const [callModal, setCallModal] = useState<any>(false);
	const [verdict, setVerdict] = useState<any>('');
	const [paymentID, setPaymentID] = useState<any>();
	const [remarkRequire, setRemarkRequire] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const toggleStatus = useAppSelector(toggleState);
	const toggleStatus_2 = useAppSelector(toggleState_2);
	const singlePartyInfo = useAppSelector(getSingleParty);
	const dispatch = useAppDispatch();
	const [products, setProducts] = useState<any[]>([getCase?.case_documents]);
	const partyInfo = useAppSelector(getPartyInfo);
	// const [selectedItems, setSelectedItems] = useState<any[]>([]);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [selectTitle, setSelectTitle] = useState<any>('');
	const [open, setOpen] = useState(false);
	const [openDoc, setOpenDoc] = useState(false);
	const [url, setUrl] = useState<any>(null);
	const [openVerdict, setOpenVerdict] = useState(false);
	const [step, setStep] = useState(0);

	useEffect(() => {
		fetchDataFromBackend();
	}, [getCase?.case_documents]);

	const fetchDataFromBackend = () => {
		// Simulated data from the backend

		const updatedProducts = getCase?.case_documents.map((data: any) => ({
			case_document_id: data.id,
			file_path: data.file_path,
			title: data.title,
			price: 0,
		}));
		// Initialize price as empty string

		setProducts(updatedProducts);
	};

	const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
		selectFromResult: ({ data, isLoading, isError }: any) => ({
			paymentData: data?.data,
			isLoadingCases: isLoading,
		}),
	});

	useEffect(() => {
		handlePaymentData(paymentData);
	}, [paymentData]);

	useEffect(() => {
		refetch();
	}, [page]);

	const handlePaymentData = (paymentData: any[]) => {
		paymentData?.map((item: any) => {
			if (item?.service?.id === getCase?.id) {
				// console.log(item, 'ddd');

				setPaymentID(item);
				return item;
			}
		});
	};

	const handleDateChange = (date: any) => {
		setSelectedDate(date);
	};
	const handleScheduleTitleChange = (e: any) => {
		setSelectTitle(e.target.value);
	};

	const handleConclude = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		Api.post(
			`/judge-magistrate/case-folders/conclude`,
			{
				case_status_id: getCase?.case_status?.id,
			},
			getJSONheader()
		)
			.then((res) => {
				toast.success(`${res?.data.message}`, {
					duration: 3000,
				});
				// console.log(res.data);
				setLoading(false);

				navigate('/judge/case-folders');
			})
			.catch((error: any) => {
				setLoading(false);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
			});
	};
	const handleSchedule = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		Api.post(
			`/judge-magistrate/case-folders/schedule`,
			{
				// user_id: user.id,
				case_status_id: getCase?.case_status?.id,
				status: selectTitle,
				schedule_date: selectedDate,
			},
			getJSONheader()
		)
			.then((res) => {
				toast.success(`${res?.data.message}`, {
					duration: 3000,
				});
				// console.log(res.data);
				setLoading(false);

				// navigate('/judge/case-folders');
				setOpen(false);
			})
			.catch((error: any) => {
				setLoading(false);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
			});
	};
	const handleVerdict = async (e: any) => {
		e.preventDefault();
		setLoading(true);
		Api.post(
			`/judge-magistrate/case-folders/give-verdict`,
			{
				suit_number: getCase?.suit_number,
				verdict,
			},
			getJSONheader()
		)
			.then((res) => {
				toast.success(`${res?.data.message}`, {
					duration: 3000,
				});
				// console.log(res.data);
				setLoading(false);

				// navigate('/judge/case-folders');
				setOpenVerdict(false);
			})
			.catch((error: any) => {
				setLoading(false);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
			});
	};

	const tp = sumPrices(getCase?.case_documents);

	const HandleOpenDoc = () => {
		return (
			<Modal
				open={openDoc}
				close={() => {
					setOpenDoc(false);
					setUrl(null);
				}}
				maxWidth='lg'
			>
				<iframe title='PDF Viewer' src={url} className='w-full ' height={900} />
			</Modal>
		);
	};
	const handleNext = async () => {
		setStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<>
			<div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
				<Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
			</div>
			<div className='w-full flex  justify-end items-center'>
				<div className='w-48 mr-4'>
					<Button
						isLoading={loading}
						icons={VIDEO}
						iconClassName='h-[0.9rem] w-5'
						bgColor={'bg-brand_indigo'}
						title={'Schedule Hearing'}
						onClick={(e: any) => setCallModal(true)}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-3 w-full !rounded'}
					/>
				</div>
			</div>

			<div className='pt-4 px-4 lg:px-8 mb-4'>
				<Typography textStyle='text-base font-medium mb-2'>Case Folder Information</Typography>
				<div className='mt-2 mb-6'>
					{getCase ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={[getCase]}
							COLUMNS={SINGLE_CASE_COLUMNS}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={() => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center mb-6'>
							No Case Folder Information
						</Typography>
					)}
				</div>
				<Typography textStyle='text-base font-medium mb-2'>Party Information</Typography>
				<div className='mt-2 mb-6'>
					{getCase?.parties?.length > 0 ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getCase?.parties}
							COLUMNS={PARTY_COLUMNS_view}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={(value: number) => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center mb-6'>
							No Party Information
						</Typography>
					)}
				</div>
				<div className='pt-8'>
					<Typography textStyle='text-base font-medium '>Uploaded Documents</Typography>
					<div className='my-2 lg:flex justify-between items-center flex-wrap '>
						{getCase?.case_documents?.length > 0 ? (
							getCase?.case_documents.map((item: any, index: number) => (
								<div key={index} className='w-full lg:w-[48%] '>
									<div className='flex justify-between items-center mb-3 border border-gray-200  p-3  rounded-lg'>
										<div className='flex justify-start items-center '>
											<img src={PDF} className='h-6 w-6' />
											<div className='ml-4'>
												<p className='text-left text-gray-400 text-xs '>Document Title</p>
												<p className=' text-black font-bold text-sm mt-1'>{item?.title}</p>
											</div>
										</div>

										<p className='text-[#22C55E] text-lg font-medium ml-4'>
											{`${formatCurrency({
												iso: 'en-ng',
												slug: 'NGN',
											}).format(item?.price)}`}
										</p>
									</div>
									<a
										onClick={() => {
											setUrl(item?.file_path);
											setOpenDoc(true);
										}}
										target='_blank'
										className='px-2 py-1 cursor-pointer bg-brand_indigo text-[#fff] text-xs  w-28  rounded-md'
									>
										View only
									</a>
								</div>
							))
						) : (
							<Typography variant='label' textStyle='font-medium text-center'>
								No Uploaded Documents
							</Typography>
						)}
					</div>
				</div>
				<div className={` justify-between lg:flex  items-end`}>
					<div className='ml-4 w-full lg:w-[30%] mt-2 lg:mt-4'>
						<Typography textStyle='text-[20px] font-medium text-center'>Total</Typography>

						{tp && (
							<p className='text-center text-green-600 bg-[#D9D9D91A] text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
								{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(tp)}
							</p>
						)}
					</div>
				</div>
				<div className='w-full md:flex justify-between items-end mt-10'>
					<div className='w-full lg:w-[45%] flex justify-between items-center flex-wrap'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/judge/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 hidden md:flex'}
						/>

						<div className='w-40 md:w-44 md:hidden'>
							<Button
								isLoading={loading}
								icons={FLAG}
								iconClassName='h-[0.9rem] w-3'
								bgColor={'bg-[#27AE60]'}
								title={'Conclude'}
								onClick={(e: any) => handleConclude(e)}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 w-full'}
							/>
						</div>
					</div>

					<div className='w-[30%] md:w-[80%] flex justify-between items-center mx-auto lg:mx-0 mt-3 lg:mt-0'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/judge/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 md:hidden flex'}
						/>

						<Button
							isLoading={loading}
							icons={FLAG}
							iconClassName='h-[0.9rem] w-3'
							bgColor={'bg-green-700'}
							title={'Conclude'}
							onClick={(e: any) => handleConclude(e)}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-44 hidden md:flex'}
						/>
					</div>
				</div>
				<PartyInformation
					open={toggleStatus}
					close={() => dispatch(setToggle(false))}
					data={singlePartyInfo}
				/>
				<FillingFee open={toggleStatus_2} close={() => dispatch(setToggle_2(false))} />
				<Modal
					open={openVerdict}
					close={() => {
						setOpenVerdict(false);
					}}
					back={() => {}}
					maxWidth='md'
				>
					<div className='px-4 h-[80vh]'>
						<Typography variant='h5'>Give Verdict</Typography>
						<form onSubmit={handleVerdict}>
							<div className='flex justify-center item-center mt-4 flex-wrap'>
								<div className='w-full lg:w-[60%]'>
									<Typography textStyle='text-[20px] font-medium mb-2'>
										<>
											Verdict <sup className='text-red-600 text-xs'>*</sup>
										</>
									</Typography>

									<textarea
										name='verdict'
										placeholder={'Give verdict'}
										className='bg-[#13BAED0D] h-40 w-full resize-none flex justify-start p-4 text-sm'
										value={verdict}
										onChange={(e) => {
											setVerdict(e.target.value);
											// setRemarkRequire('');
										}}
									/>
									<small className='text-red-600'>{remarkRequire}</small>
								</div>
							</div>
							<Button
								isLoading={loading}
								icons={FLAG}
								iconClassName='h-[0.9rem] w-3'
								bgColor={'bg-yellow-700'}
								title={'Schedule Case'}
								// type={'button'}
								// disabled={!selectTitle || selectedDate }
								// onClick={(e: any) => handleSchedule(e)}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 w-44 hidden md:flex my-4 mx-auto'}
							/>
						</form>
					</div>
				</Modal>

				<Modal
					open={callModal}
					close={() => {
						setCallModal(false);
					}}
					back={() => {}}
					maxWidth='md'
				>
					<div className='flex flex-col justify-center items-center pr-6 pb-12 bg-white max-md:flex-wrap max-md:pr-5 w-full bg-blue-50/50'>
						<div className='px-8 pt-4 pb-12  w-full  bg-white rounded-lg mt-8'>
							<div className='mt-2 ml-14 text-xl font-semibold text-black whitespace-nowrap  max-md:ml-2.5 text-center mb-6'>
								Schedule Hearing
							</div>
							<Stack sx={{ width: '100%' }} spacing={4}>
								<Stepper
									alternativeLabel
									activeStep={step} // Changed active step to 'step'
									connector={<ColorlibConnector />}
								>
									{steps.map((label) => (
										<Step key={label}>
											<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
										</Step>
									))}
								</Stepper>
							</Stack>
							<div className=''>
								<Formik
									initialValues={{
										title: '',
										type: '',
										full_name: '',
										phone_number: '',
										email: '',
										description: '',
										scheduled_at: '',
									}}
									// validationSchema={createCaseSchema}
									onSubmit={async (values, { setSubmitting, resetForm }) => {
										try {
											const payload: any = {
												case_folder_id: getCase?.id,
												title: values.title,
												type: values.type,
												description: values.description,
												scheduled_at: values.scheduled_at,
												status: selectTitle,
											};

											partyInfo?.forEach((item: any, i: number) => {
												payload.parties = payload?.parties || []; // Initialize payload.parties if it doesn't exist
												payload.parties[i] = {
													name: item.name,
													email: item.email,
													phone_number: item.phone_number,
												};
											});
											const response = await handleHearing(payload);
											if (response) {
												setStep(0);
												dispatch(resetDoc());
												dispatch(resetValue());
												resetForm();
												toast.success(response?.message, {
													duration: 3000,
												});
												setCallModal(false);
											}
										} catch (error: any) {
											toast.error(error?.response?.data?.message, {
												duration: 3000,
											});
										}
									}}
								>
									{({
										handleSubmit,
										setFieldValue,
										values,
										handleChange,
										touched,
										errors,
										isSubmitting,
										/* and other goodies */
									}) => (
										<Form className='flex flex-col pt-3 md:pt-4 px-4  lg:pt-6 '>
											<>
												<div className='mt-8 ml-14   whitespace-nowrap max-md:mt-10 max-md:ml-2.5 flex justify-between items-start flex-wrap'>
													<p className='text-xl font-semibold text-black'></p>
												</div>

												{step === 0 ? (
													<div className='w-full lg:w-1/2 mx-auto'>
														<div className='flex justify-start items-center flex-wrap'>
															<div className='flex flex-col   w-full mb-2 lg:mb-6'>
																<label className='mb-3 flex justify-start items-center'>
																	Meeting title
																</label>
																<Input type='text' placeholder='title' name={'title'} />
															</div>
															<div className='flex flex-col w-full mb-2 lg:mb-6'>
																<label className='mb-3 flex justify-start items-center'>
																	Meeting date and time
																</label>

																<input
																	name='scheduled_at'
																	type='datetime-local'
																	value={values.scheduled_at}
																	onChange={handleChange}
																	className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
																/>
															</div>

															<div className='flex flex-col w-full mb-2 lg:mb-6'>
																<label className='mb-3 flex justify-start items-center'>
																	Description
																</label>
																<textarea
																	name={'description'}
																	className='bg-[#13BAED0D] border-[#13baed4b] border rounded-3xl h-20 w-full resize-none flex justify-start p-4 text-sm'
																	value={values.description}
																	onChange={handleChange}
																/>
															</div>
															<div className='flex flex-col   w-full mb-2 lg:mb-6'>
																<label className='mb-3 flex justify-start items-center'>
																	Meeting type
																</label>
																<select
																	name='type'
																	value={values.type}
																	onChange={handleChange}
																	className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
																>
																	<option value='' className='text-gray-200'>
																		Select a meeting type
																	</option>
																	<option value='virtual'>Virtual</option>
																	<option value='physical'>Physical</option>
																</select>
																{touched.type && errors.type && (
																	<span className='text-red-500 text-sm'>{errors.type}</span>
																)}
															</div>
															<div className='flex flex-col   w-full mb-2 lg:mb-6'>
																<label className='mb-3 flex justify-start items-center'>
																	Status
																</label>
																<select
																	name='status'
																	id='name'
																	className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
																	onChange={handleScheduleTitleChange}
																>
																	<option value=''>Select status</option>
																	<option value='MENTION'>Mention</option>
																	<option value='PART HEARD'>Motion</option>
																	<option value='MOTION'>Part-Heard</option>
																</select>
															</div>
															<div className='flex justify-center w-full'>
																<Button
																	// isLoading={loading}
																	title='Next'
																	type={'button'}
																	bgColor={'bg-brand_indigo'}
																	onClick={() => {
																		handleNext();
																	}}
																	hoverColor={''}
																	textColor={'text-white'}
																	className={`py-4  w-80   mt-12 rounded-lg`}
																/>
															</div>
														</div>
													</div>
												) : (
													<>
														<div className='w-full lg:w-1/2 mx-auto'>
															<div className='mb-4 font-bold'>Add all participants involved</div>
															<div className='flex flex-col   w-full mb-2'>
																<Input type='text' placeholder='Name' name={'full_name'} />
															</div>

															<div className='flex flex-col w-full mb-2'>
																<Input type='text' placeholder='Email address' name={'email'} />
															</div>
															<div className='flex flex-col w-full mb-2'>
																<Input
																	type='text'
																	placeholder='Phone number'
																	name={'phone_number'}
																/>
															</div>
														</div>

														<div className='flex justify-end items-center'>
															<IconButton
																onClick={() => {
																	dispatch(
																		addValue({
																			name: values.full_name,
																			email: values.email,
																			phone_number: values.phone_number,
																		})
																	);

																	setFieldValue('full_name', '');
																	setFieldValue('email', '');
																	setFieldValue('phone_number', '');
																}}
															>
																<FaPlusCircle className='text-brand_indigo' />{' '}
																<span className='text-sm ml-2'>Add another participants</span>
															</IconButton>
														</div>

														{partyInfo?.length > 0 && (
															<TableComponent
																className='rounded-none rounded-t-0'
																DATA={partyInfo}
																COLUMNS={HEARING_COLUMNS}
																TITLE=''
																isExport={null}
																isPaginated={null}
																isSearch={null}
																size={size}
																setSize={setSize}
																setPageIndex={(value: number) => page + 1}
															/>
														)}
														<div
															className={` flex  justify-between  mt-12  items-center w-full lg:w-1/2 mx-auto flex-wrap`}
														>
															{step > 0 && (
																<Button
																	// isLoading={loading}
																	title='Back'
																	type={'button'}
																	bgColor={'bg-gray-400'}
																	onClick={() => {
																		handleBack();
																	}}
																	hoverColor={''}
																	textColor={'text-white'}
																	className={'py-4 w-40 lg:w-48 rounded-lg'}
																/>
															)}
															<Button
																isLoading={isSubmitting}
																title={step > 0 ? ' Submit' : 'Next'}
																type={'button'}
																bgColor={'bg-brand_indigo'}
																onClick={() => {
																	handleSubmit();
																}}
																hoverColor={''}
																textColor={'text-white'}
																className={`py-4  w-40 lg:w-48 rounded-lg`}
															/>
														</div>
													</>
												)}
											</>
										</Form>
									)}
								</Formik>
							</div>
						</div>
					</div>
				</Modal>
				{HandleOpenDoc()}
			</div>
		</>
	);
}

export default ProcessPayment;

import Typography from '../../../components/Typography';
import CourtImg from '../../../assets/icons/IMG-1266 2.png';
import { Fade } from 'react-reveal';

const Overview = () => {
	return (
		<div className='md:flex justify-around items-center  px-4 lg:px-12 py-16 w-full'>
			<Fade left>
				<img src={CourtImg} className='object-top' alt='court' />
			</Fade>
			<Fade right>
				<div className='text-justify lg:w-[50%] w-full'>
					<h3 className='text-[#55B7E8] text-3xl text-center my-[3%] font-bold xl:text-left font-luxia'>
						Rivers State Family Court Overview
					</h3>
					<p className='text-sm leading-[28px] text-[#3c3b59] py-2'>
						The Rivers State Family Court was inaugurated on the 19th of December, 2022. The concept
						of the Family Court in Rivers State is guided by the principles of restorative justice
						aimed at preserving healthier and more constructive relationships between children,
						their families and society, even in situations of disharmony and breakdown.
						Child-related disputes and offences are inherently different from other types of legal
						disputes and should not be dealt with in an adversarial manner. Therefore, the focus is
						placed on problem-solving towards arriving at workable and durable solutions that are
						best for the development of the child.
					</p>
					<p className='text-sm leading-[28px] text-[#3c3b59] py-2'>
						{' '}
						The idea of establishing a Family Court in Rivers State has become long overdue on
						account of the growing trend and numerous cases of abuse and violations of the rights of
						children, which manifest in different ways, as sexual abuse, female genital mutilation
						(FGM), domestic violence, neglect, physical and emotional abuse, child labour, street
						trading, physical bullying, cyber bullying, denial of basic education, abandonment,
						discrimination, underage marriage, ‘ underage pregnancy, economic exploitation,
						different forms of violence against children and other related vices. The Family Court
						also administers child-friendly justice on criminal matters concerning children.
					</p>
					<p className='text-sm leading-[28px] text-[#3c3b59] py-2'>
						The Rivers State Family Court Division has brought together a team of qualified
						professionals, including judicial officers, social workers, assessors, psychologists,
						family mediators, counsellors, programme facilitators, court administration personnel
						and other experts from multiple fields into a single unified Court Division. These
						professionals will all work together to provide Court users, especially children, with
						the help they need to resolve conflicts and improve their social status as law-abiding
						and functional citizens. They will deliver child justice administration at a very
						professional level.
					</p>
				</div>
			</Fade>
		</div>
	);
};

export default Overview;

import moment from 'moment';

export const CAUSELIST_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: 'Suit number',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.suit_number}</span>;
		},
	},
	{
		Header: 'Case number',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.case_number}</span>;
		},
	},
	{
		Header: 'Case title',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.title}</span>;
		},
	},
	{
		Header: 'Court',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.court}</span>;
		},
	},
	{
		Header: 'Date created',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.values.created_at).format('MMM Do YYYY');
		},
	},
];

export const CAUSELIST_COLUMNS_2 = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: "Judge's Name",
		Cell: ({ cell: { row } }: any) => {
			return (
				<span className='text-xs'>
					Hon {row?.original?.user.first_name} {row?.original?.user.last_name}
				</span>
			);
		},
	},
	{
		Header: 'Suit Number',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.suit_number}</span>;
		},
	},
	{
		Header: 'Case Number',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.case_number}</span>;
		},
	},
	{
		Header: 'Case Title',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.case_folder?.title}</span>;
		},
	},
	{
		Header: 'Scheduled Date',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.original.scheduled_at).format('MMM Do YYYY');
		},
	},
	{
		Header: 'Hearing Type',
		accessor: 'type',
	},
	{
		Header: 'Action',
		Cell: ({ cell: { row } }: any) => {
			return (
				<>
					{row.original?.type === 'physical' ? (
						<></>
					) : (
						<a
							target='_blank'
							href={row.original.join_url}
							className='px-4 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md'
							rel='noreferrer'
						>
							Join
						</a>
					)}
				</>
			);
		},
	},
	{
		Header: 'Court',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row?.original?.court}</span>;
		},
	},
];

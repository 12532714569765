import { AiOutlineMenu } from 'react-icons/ai';
import { LOGO } from '../../assets';

import { Link } from 'react-router-dom';
import '../../index.css';
import { selectRole } from '../../states/slices/authReducer';
import { useAppSelector } from '../../hooks';

interface header {
	onMenu?: any;
	handleShowFilter?: any;
}

function Header(props: header) {
	const role = useAppSelector(selectRole);
	return (
		<div>
			<div
				className={`${
					role !== 'LAWYER' ? 'h-[12vh]' : 'h-[16vh]'
				} flex justify-between bg-white border-b-brand_blue border-b-8 items-center flex-row w-full px-4 lg:px-8 pt-8 lg:pt-0 pb-4 lg:py-2`}
			>
				<div className='hidden lg:flex lg:flex-1 items-center gap-x-5 mr-3 '></div>
				<div className='flex flex-1 lg:flex-none shrink-0 gap-x-3 items-center h-full justify-start '>
					<Link
						to='/'
						className={`transition ease-in-out delay-150 py-2 px-3 w-16 flex justify-between items-center  hover:-translate-y-1 hover:scale-110  duration-300 text-xs `}
					>
						<img src={LOGO} className='h-full w-full rounded-full' alt='...logo' />
					</Link>
				</div>
				{role === 'LAWYER' && (
					<div className='py-2 h-full text-gray-500 text-xs flex justify-between'>
						<div className=' h-4 w-4 animate-ping rounded-full bg-brand_blue/70'></div>
						<div>
							<p className='text-black font-bold'>Rivers State Family Revenue Account</p>
							<p className='text-black font-bold'>Account Number: 1130100273</p>
							<p className=''>Branch: Port Harcourt Main Branch</p>
							<p className=''>Swift Code: ZEIBNGLA.</p>
							<p>Zenith Bank Plc</p>
						</div>
					</div>
				)}
				<div className='lg:hidden'>
					<AiOutlineMenu onClick={props.onMenu} className='h-10 text-4xl' />
				</div>
			</div>
		</div>
	);
}

export default Header;

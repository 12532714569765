import { Route, Routes } from 'react-router-dom';
import MainRoutes from './Routes';
import Home from './features/home';
import Login from './features/auth/Login';
import Signup from './features/auth/Signup';
import { Toaster } from 'react-hot-toast';
import { PrivateRoutes } from './Routes/private';
import AdminLogin from './features/auth/admin-login';
import { VerifyOtp } from './features/auth/verify';
import CauseList from './features/cause-list';
import DownloadForm from './features/forms';
import { ForgotPassword } from './features/auth/forgotPassword';
import { NonVerified } from './features/auth/non-verified';
import PasswordReset from './features/auth/PasswordReset';
import { useEffect, useState } from 'react';

import Modal from './components/UI/modal';
import { Zoom } from 'react-reveal';
import { BsExclamationCircle } from 'react-icons/bs';

function App() {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		const timer = setTimeout(() => {
			setOpen(true);
		}, 15000); // 15 seconds

		return () => clearTimeout(timer);
	}, []);
	// useEffect(() => {
	// 	// Store references to the original console methods
	// 	const originalLog = console.log;
	// 	const originalWarn = console.warn;
	// 	const originalError = console.error;

	// 	console.log = function () {};

	// 	// Override other console methods if needed
	// 	console.warn = function () {};
	// 	console.error = function () {};

	// 	// Cleanup function to restore original console methods
	// 	return () => {
	// 		console.log = originalLog;
	// 		console.warn = originalWarn;
	// 		console.error = originalError;
	// 	};
	// }, []);
	return (
		<>
			<Routes>
				<Route
					path='/*'
					element={
						<PrivateRoutes>
							<MainRoutes />
						</PrivateRoutes>
					}
				/>

				<Route path='/' element={<Home />} />
				<Route path='/forms' element={<DownloadForm />} />
				<Route path='/login' element={<Login />} />
				<Route path='/admin/login' element={<AdminLogin />} />
				<Route path='/signup' element={<Signup />} />
				<Route path='/cause-list' element={<CauseList />} />

				<Route path='/verify-email-otp' element={<VerifyOtp />} />
				<Route path='/non-verify' element={<NonVerified />} />
				<Route path='/email-Verification' element={<VerifyOtp />} />
				<Route path='/password-reset' element={<PasswordReset />} />
				<Route path='/forgot-password' element={<ForgotPassword />} />
			</Routes>
			<Toaster position='top-right' reverseOrder={false} />
			<Modal
				open={open}
				close={() => {
					setOpen(false);
				}}
				maxWidth='md'
			>
				<Zoom>
					<div className='text-brand_indigo text-base pb-10'>
						<div className='text-brand_indigo text-xl flex justify-center items-center'>
							<BsExclamationCircle className='mr-2' /> RIVERS STATE FAMILY COURT VIRTUAL HEARING
						</div>

						<div className='text-brand_indigo px-12 py-3 mt-5'>
							The Family Court virtual hearing solution aims at eliminating geographical barriers,
							offering a more accessible, efficient, and cost-effective way of conducting
							proceedings and improving convenience for Judges, Lawyers, and Litigants.
						</div>
						<div className='bg-brand_indigo text-white px-12 py-6 mt-5'>
							BENEFITS
							<div className='flex justify-between items-start w-full'>
								<ul className='list-disc'>
									<li>Increased Access to Justice</li>
									<li>Reduce Geographical Barriers Enhanced Safety and Privacy</li>
									<li>Reduce Court Backlogs</li>
								</ul>
								<ul className='list-disc'>
									<li>Reduced Costs</li>
									<li>Enhanced Safety and Privacy</li>
									<li>Improved Work-Life Balance for Families</li>
								</ul>
							</div>
						</div>
						<div className='text-brand_indigo px-12 py-3 mt-5'>
							<p className='mb-4 text-lg font-bold'>USER GUIDE</p>
							<ul className='list-disc'>
								<li>
									Go to the Rivers State Family Court Website (
									<a href='https://www.familycourt.rv.gov.ng' className=''>
										www.familycourt.rv.gov.ng
									</a>
									)
								</li>
								<li>Click on Cause List</li>
								<li>
									Fill in the fields, select the date your case was fixed for hearing, and click on
									the Search icon. You will be able to view your case details.
								</li>
								<li>
									Click on the <span className='font-bold'> “Join” </span> button to join a virtual
									court hearing.
								</li>
							</ul>
						</div>
					</div>
				</Zoom>
			</Modal>
		</>
	);
}

export default App;

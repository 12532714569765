import { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RxHamburgerMenu, RxCross1 } from 'react-icons/rx';
import { NavContext } from './context/NavbarContext';
import { useAppDispatch, useAppSelector, useMediaQuery } from '../../../hooks';
import { LOGO } from '../../../assets';
import { motion, AnimatePresence } from 'framer-motion';
import { selectRole, selectUser } from '../../../states/slices/authReducer';
import { resetDoc, resetValue, updateCase } from '../../../states/slices/partyInfo.slice';
import AuthService from '../../../services/auth';
const NavItems = [
	{ id: 1, name: 'Home', link: '#home' },
	{ id: 2, name: 'About Us', link: '#overview' },
	{ id: 3, name: 'Services', link: '#services' },
	{ id: 4, name: 'Forms', link: '/forms' },
	{ id: 5, name: 'FAQs', link: '#faqs' },
	{ id: 5, name: 'Cause List', link: '/cause-list' },
	{ id: 6, name: 'Sign In', link: '/login' },
	{
		id: 7,
		name: 'Create Account',
		link: '/signup',
	},
];

const NavBar = () => {
	const { isOpenNav, setIsOpenNav } = useContext(NavContext);
	const isMobileView = useMediaQuery('(max-width: 640px)');
	const isTabletView = useMediaQuery('(max-width: 840px)');
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toggleIcon = () => setIsOpenNav(!isOpenNav);
	const user = useAppSelector(selectUser);
	const role = useAppSelector(selectRole);

	const handleLogout = () => {
		dispatch(resetDoc());
		dispatch(resetValue());
		dispatch(updateCase(null));
		AuthService.logout();
		navigate('/login');
	};
	return (
		<div>
			{/* Mobile Nav */}
			{isMobileView || isTabletView ? (
				<div className='flex flex-row justify-between bg-white px-10 py-2 '>
					<div className='w-14'>
						<img src={LOGO} className='h-full w-full rounded-full my-auto' alt='logo' />
					</div>
					<div onClick={toggleIcon} className='my-auto z-20'>
						{!isOpenNav ? <RxHamburgerMenu size={30} /> : <RxCross1 size={30} color='white' />}
					</div>
					<AnimatePresence>
						{isOpenNav && (
							<motion.div
								initial={{ opacity: 0, y: '-50%' }}
								animate={{ opacity: 1, y: '0%' }}
								exit={{
									opacity: 0,
									y: '-50%',
									transition: { duration: '0.35' },
								}}
								transition={{
									type: 'just',
									stiffness: '100',
									duration: '0.75',
								}}
								className={`bg-brand_indigo flex h-[100%] w-[100%] top-0 right-0 fixed z-10`}
							>
								<motion.ul
									initial={{ opacity: 0, y: '-50%' }}
									animate={{ opacity: 1, y: '0%' }}
									exit={{
										opacity: 0,
										y: '-50%',
										transition: { duration: '0.35' },
									}}
									transition={{
										type: 'spring',
										stiffness: '100',
										duration: '0.75',
									}}
									className={`flex flex-col my-auto mx-auto`}
								>
									{NavItems.map((items: any) => (
										<li
											className={`mx-auto mt-5 hover:scale-x-105 [@media(max-width:840px)]:text-white ${items.style}`}
											key={items.id}
										>
											<Link onClick={() => setIsOpenNav(!isOpenNav)} to={items.link}>
												{items.name}
											</Link>
										</li>
									))}
								</motion.ul>
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			) : null}
			<div className='flex flex-row max-[840px]:hidden justify-between bg-white px-20 py-2'>
				<div className='w-14'>
					<img
						src={LOGO}
						className='h-full w-full rounded-full my-auto cursor-pointer'
						alt='logo'
						onClick={() => navigate('/')}
					/>
				</div>

				<div className='my-auto flex flex-row space-x-10'>
					<a className={` py-2`} href={'#home'}>
						{'Home'}
					</a>
					<a className={` py-2`} href={'#overview'}>
						About Us
					</a>
					<a className={` py-2`} href={'#services'}>
						Services
					</a>
					<a className={` py-2`} href={'#faqs'}>
						FAQs
					</a>
					<Link className={` py-2`} to={'/forms'}>
						Forms
					</Link>
					<Link className={` py-2`} to={'/cause-list'}>
						Cause List
					</Link>
					{user ? (
						<Link
							className={`bg-brand_indigo [@media(max-width:840px)]:bg-white [@media(max-width:849px)]:text-black [@media(max-width:840px)]:py-2 text-white px-4 rounded-r-full rounded-bl-full text-sm py-2`}
							to={
								role === 'LAWYER'
									? '/lawyer'
									: role === 'REGISTRAR 1'
									? '/registrar-1'
									: role === 'REGISTRAR 2'
									? '/registrar-2'
									: role === 'PRESIDING JUDGE'
									? '/presiding-judge'
									: role === 'MAGISTRATE'
									? '/magistrate'
									: role === 'PRESIDING MAGISTRATE'
									? '/presiding-magistrate'
									: role === 'JUDGE'
									? '/judge'
									: role === 'ADMIN'
									? '/admin/dashboard'
									: '/login'
							}
						>
							Dashboard
						</Link>
					) : (
						<>
							<Link className={` py-2`} to={'/login'}>
								Sign in
							</Link>
							<Link
								className={`bg-brand_indigo [@media(max-width:840px)]:bg-white [@media(max-width:849px)]:text-black [@media(max-width:840px)]:py-2 text-white px-4 rounded-r-full rounded-bl-full text-sm py-2`}
								to={'/signup'}
							>
								Create Account
							</Link>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default NavBar;

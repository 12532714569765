import React from 'react';
import slider1 from '../../../assets/icons/slider1.jpeg';
import slider2 from '../../../assets/icons/slider2.png';
import slider3 from '../../../assets/icons/slider3.png';
import slider4 from '../../../assets/icons/slider4.png';
import slider5 from '../../../assets/icons/slider5.png';
import Carousel from '../../../components/Carousel';
// import Carousel from '../../components/carousel/Carousel'

const paragraph = `I pledge to honour and protect the rights of children. <br> To provide guidance, counsel and love in the discharge of my duties to every child.<br> To shield them from public harm and private abuse. <br> To defend the sanctity of their innocence. <br>To be a role model, worthy of emulation. <br> To be their Family Court Advocate and Rights Defender. <br>And to leave no child behind. <br/>So, help me God`;
const sentences = paragraph
	.split('.')
	.map((sentence) => sentence.trim())
	.filter((sentence) => sentence !== '');
const lastSentence = sentences[sentences.length - 1];
const otherSentences = sentences.slice(0, -1);

const Hero = () => {
	return (
		<div id='/' className='bg-[#272656] px-2 lg:px-16'>
			<Carousel
				header={[
					{ head: 'THE RIVERS STATE FAMILY COURT', para: '...a child-friendly Court' },
					{
						head: 'ALTERNATIVE DISPUTE RESOLUTION',
						para: 'The Court as a rule explores and encourages full utilisation of alternative dispute resolution measures for expedited adjudication of all matters.',
					},
					{
						head: 'EXPERTISE AND RESOURCES',
						para: 'The Family Court creates confidence by the integrity, expertise and experience of Judges, Magistrates, registrars, child experts, social workers and Court staff.',
					},
					{
						head: 'CHILD WAITING ROOM',
						para: "The Family Court has a well-equipped waiting room with children's toys and play materials for the comfort of the child while Court proceedings are ongoing.",
					},
					{
						head: "CHILD'S RIGHTS PLEDGE",
						para: paragraph,
					},
					// {
					// 	head: "CHILD'S RIGHTS PLEDGE",
					// 	para: otherSentences.join('. ') + `. ${lastSentence}.`,
					// },
				]}
				images={[slider1, slider2, slider3, slider4, slider5]}
			/>
		</div>
	);
};

export default Hero;

import React, { useState } from 'react';
import { USER_ICON } from '../../../assets';
import { useAppSelector } from '../../../hooks';
import Api from '../../../config';
import { getJSONheader } from '../../../utils';
import PaginatedTable from '../../../components/table/Paginated-table';
import { getSelectedCase } from '../../../states/slices/partyInfo.slice';
import { PR_JUDGE_COLUMNS } from '../../../components/table/columns';
import Button from '../../../components/button';
import { useNavigate } from 'react-router-dom';

function Open() {
	const judge = useAppSelector(getSelectedCase);
	const [data, setData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setLoading] = useState(false);
	const navigate = useNavigate();

	const fetchData = async (page: any, pageSize: any) => {
		setLoading(true);
		try {
			const response = await Api.get(
				`/presiding/judges/${judge?.id}/case-folders?page=${page}`,
				getJSONheader()
			);
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
			setTotalPages(meta.last_page);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	const profilePic = (
		<img src={judge?.profile_photo} className={'w-[200px] h-[200px] rounded-full'} />
	);

	return (
		<div className='pb-16 lg:pb-0 '>
			<div className='bg-[#F8F8F9] text-left  py-3 flex items-center pl-[3%]'>
				<h3 className=''>{`Case Folders / ${judge?.first_name} ${judge?.last_name}`}</h3>
			</div>
			<div className='flex flex-col md:flex-row justify-center items-center mt-8 lg:px-[5%] '>
				<div className=' lg:mr-8 mb-4 lg:mb-0 bg-[#13BAED0D] w-full lg:w-[50%] flex flex-col items-center lg:h-[250px] py-4 rounded-lg'>
					<div className=' w-full px-6 py-3'>
						<div className={`flex justify-center items-center flex-col rounded-full mb-8`}>
							{judge?.profile_photo ? (
								profilePic
							) : (
								<>
									<img src={USER_ICON} alt='' className='h-[150px] w-[150px]' />
								</>
							)}
						</div>
					</div>
				</div>
				<div className='bg-[#fff] rounded-lg shadow shadow-indigo-500/40 w-full md:w-[55%] px-8 py-4  lg:pt-4  lg:h-[250px]'>
					<div className='flex flex-col text-left'>
						<label className='mb-[2%]'>First Name</label>

						<p className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'>
							{judge?.first_name}
						</p>
					</div>
					<div className='flex flex-col text-left mt-[2%]'>
						<label className='mb-[2%]'>Last Name</label>
						<p className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'>
							{judge?.last_name}
						</p>
					</div>
				</div>
			</div>
			<div className='w-full flex flex-row justify-end items-start  px-8 pt-8'>
				<Button
					iconClassName='h-[0.9rem] w-3'
					title='Assign Case Folder'
					bgColor={'bg-brand_indigo'}
					onClick={() => navigate('/presiding-judge/case-folders')}
					hoverColor={''}
					textColor={'text-white'}
					className={'py-2'}
				/>
			</div>

			<div className='mt-8 lg:flex justify-between items-end'>
				<div className=' w-full lg:mb-0'>
					<div className=' border-b  border-[#F0F0F0] rounded-b-xl'>
						<PaginatedTable
							columns={PR_JUDGE_COLUMNS}
							data={data}
							totalPages={totalPages}
							fetchData={fetchData}
							isLoading={isLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Open;

import Typography from '../../../components/Typography';

const Benefits = () => {
	return (
		<div className='bg-brand_hero flex flex-col py-10 px-5'>
			<div className='mx-auto m_small:text-center flex flex-col'>
				<Typography variant='h2' textStyle='text-brand_indigo text-center'>
					Benefits to Court Users
				</Typography>
				<Typography variant='h5' textStyle='mx-auto text-center text-brand_blue mt-4 font-bold'>
					Lawyers / Non-Lawyers
				</Typography>
			</div>
			<div className='mx-auto'>
				<div className='lg:flex mt-10  gap-20 mx-auto items-center'>
					<div className='text-[#464646] text-[18px] font-[400] font-[normal]'>
						<h4 className='text-[#77A1BF] font-bold lg:hidden'>Non-Lawyers</h4>
						<ul className='leading-loose list-disc list-inside lg:leading-[2.5] text-[#464646] text-[18px] font-[400] font-[normal] flex flex-col'>
							<li>24-hour access to case management dashboard.</li>
							<li>Automatic and secure document storage.</li>
							<li>Reduced paper documents and manual records.</li>
						</ul>
					</div>

					<div className='text-[#464646] text-[18px] font-[400] font-[normal]'>
						<h4 className='text-[#77A1BF] font-bold lg:hidden'>Non-Lawyers</h4>
						<ul className='leading-loose list-disc list-inside lg:leading-[2.5] text-[#464646] text-[18px] font-[400] font-[normal] flex flex-col'>
							<li>e-Filing - file processes remotely.</li>
							<li>Use of e-payment to effect payment for filing.</li>
							<li>Receipt of notifications from the Court concerning cases filed.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Benefits;

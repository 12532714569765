import { Dashboard, MyFiles, Notification, Payment, Profile } from '../features/lawyer';
import EditCases from '../features/lawyer/edit-cases';
import ProceedPayment from '../features/lawyer/proceed-payment';
import ViewCases from '../features/lawyer/view-cases';

export const lawyerRoutes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Dashboard,
		path: 'lawyer',
	},
	{
		id: '2',
		title: 'My Files',
		component: MyFiles,
		path: 'lawyer/my-files',
	},
	{
		id: '3',
		title: 'Notification',
		component: Notification,
		path: 'lawyer/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Profile,
		path: 'lawyer/profile',
	},
	// {
	// 	id: '5',
	// 	title: 'Payment',
	// 	component: Payment,
	// 	path: 'lawyer/payment',
	// },
	// {
	// 	id: '6',
	// 	title: 'Payment',
	// 	component: Payment,
	// 	path: 'lawyer/proceed',
	// },
	{
		id: '7',
		title: 'ProceedPayment',
		component: ProceedPayment,
		path: 'lawyer/proceed',
	},
	{
		id: '8',
		title: 'ViewCases',
		component: ViewCases,
		path: 'lawyer/view-case',
	},
	{
		id: '9',
		title: 'EditCases',
		component: EditCases,
		path: 'lawyer/edit-case',
	},
];

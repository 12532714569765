import moment from 'moment';
import { useAppDispatch } from '../../../hooks';
import { Link } from 'react-router-dom';
import { updateCase } from '../../../states/slices/partyInfo.slice';

export const ADMINSINGLE_CASE_COLUMNS = [
	{
		Header: 'Suit no',
		accessor: 'suit_number',
	},
	{
		Header: 'Case Title',
		accessor: 'title',
	},
	{
		Header: 'Date created',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.values.created_at).format('MMM Do YYYY');
		},
	},

	// {
	// 	Header: 'Action',
	// 	Cell: ({ cell: { row } }: any) => {
	// 		const dispatch = useAppDispatch();
	// 		return (
	// 			<>
	// 				<button
	// 					onClick={() => {
	// 						dispatch(setToggle_2(true));
	// 					}}
	// 					className={`px-2 py-1 text-white text-xs hover:opacity-70  rounded-md bg-brand_indigo w-16`}
	// 				>
	// 					View
	// 				</button>
	// 			</>
	// 		);
	// 	},
	// },
];

export const ADMIN_CASE_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: 'Suit no',
		accessor: 'suit_number',
	},
	{
		Header: 'Case Title',
		accessor: 'title',
	},
	{
		Header: 'Date created',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.values.created_at).format('MMM Do YYYY');
		},
	},
	{
		Header: 'Status',
		// accessor: '',
		Cell: ({ cell: { row } }: any) => {
			// console.log(row, 'ROW');

			return (
				<button
					className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
						row.original?.case_status?.status === 'PENDING'
							? 'bg-yellow-200'
							: row.original?.case_status?.status === 'MOTION'
							? 'bg-yellow-200'
							: row.original?.case_status?.status === 'IN COURT'
							? 'bg-[#F2994A]'
							: row.original?.case_status?.status === 'NOT SERVED'
							? 'bg-red-100'
							: row.original?.case_status?.status === 'REJECTED'
							? 'bg-red-100'
							: row.original?.case_status?.status === 'FRESH CASE'
							? 'bg-green-200'
							: 'bg-green-200'
					}`}
				>
					{row.original?.admin_case_status?.status}
				</button>
			);
		},
	},
	{
		Header: 'Action',
		Cell: ({ cell: { row } }: any) => {
			// console.log(row, 'ROW');
			const dispatch = useAppDispatch();

			return (
				<>
					{row.original?.case_status?.status === 'ACCEPTED' ? (
						<Link to={`/admin/proceed?id=${row.original?.id}`}>
							<button
								onClick={() => dispatch(updateCase(row.original))}
								className='px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md'
							>
								Proceed
							</button>
						</Link>
					) : row.original?.case_status?.status === 'REJECTED' ? (
						<Link to={`/admin/edit-case?id=${row.original?.id}`}>
							<button
								onClick={() => dispatch(updateCase(row.original))}
								className='px-1 py-1 bg-yellow-300 text-[#fff] text-xs  w-20  rounded-md'
							>
								Edit
							</button>
						</Link>
					) : (
						<Link to={`/admin/view-case?id=${row.original?.id}`}>
							<button
								onClick={() => dispatch(updateCase(row.original))}
								className='px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md'
							>
								View
							</button>
						</Link>
					)}
				</>
			);
		},
	},
];

export const ADMIN_USERS_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: 'Name',

		Cell: ({ cell: { row } }: any) => {
			return (
				<span className='text-xs'>{`${row?.original?.first_name} ${row?.original?.last_name}`}</span>
			);
		},
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Phone Number',
		accessor: 'phone_number',
	},

	{
		Header: 'Role',
		accessor: 'role',
	},
	// {
	// 	Header: 'Enrollment Number',
	// 	accessor: 'enrollment_number',
	// },

	{
		Header: 'Action',
		Cell: ({ cell: { row } }: any) => {
			// console.log(row, 'ROW');
			const dispatch = useAppDispatch();

			return (
				<>
					<Link to={`/admin/view-user?id=${row.original?.id}`}>
						<button
							onClick={() => dispatch(updateCase(row.original))}
							className='px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md'
						>
							View
						</button>
					</Link>
				</>
			);
		},
	},
];

export const ADMIN_PAYMENT_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: 'Name',

		Cell: ({ cell: { row } }: any) => {
			return (
				<span className='text-xs'>{`${row?.original?.user?.first_name} ${row?.original?.user?.last_name}`}</span>
			);
		},
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Method',
		accessor: 'method',
	},
	{
		Header: 'Status',
		// accessor: '',
		Cell: ({ cell: { row } }: any) => {
			// console.log(row, 'ROW');

			return (
				<button
					className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
						row.original?.status === 'pending'
							? 'bg-yellow-200'
							: row.original?.status === 'success'
							? 'bg-green-200'
							: 'bg-red-200'
					}`}
				>
					{row.original?.status}
				</button>
			);
		},
	},
	// {
	// 	Header: 'Action',
	// 	Cell: ({ cell: { row } }: any) => {
	// 		// console.log(row, 'ROW');
	// 		const dispatch = useAppDispatch();

	// 		return (
	// 			<>
	// 				<Link to={`/admin/view-user?id=${row.original?.id}`}>
	// 					<button
	// 						onClick={() => dispatch(updateCase(row.original))}
	// 						className='px-1 py-1 bg-brand_blue text-[#fff] text-xs  w-20  rounded-md'
	// 					>
	// 						View
	// 					</button>
	// 				</Link>
	// 			</>
	// 		);
	// 	},
	// },
];

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  getSingleParty,
  setToggle,
  setToggle_2,
  toggleState,
  toggleState_2,
} from "../../../states/slices/partyInfo.slice";
import { TableComponent } from "../../../components/table/Table";
import { PARTY_COLUMNS_view } from "../../../components/table/columns";
import Typography from "../../../components/Typography";
import {
  CHEVRON_LEFT,
  FLAG,
  MINUS,
  PDF,
  PLUS,
  QUESTION,
  UPLOAD,
} from "../../../assets";
import { formatCurrency, getJSONheader } from "../../../utils";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Api from "../../../config";
import { useGetPaymentsQuery } from "../../../services/apis/cases";
import { toast } from "react-hot-toast";
import PartyInformation from "../../../components/modals/Partinformation";
import FillingFee from "../../../components/modals/FillingFee";
import { PDFDocument, rgb, StandardFonts, degrees } from "pdf-lib";
import { selectUser } from "../../../states/slices/authReducer";
import Modal from "../../../components/UI/modal";

function ProcessPayment() {
  const getCase = useAppSelector(getSelectedCase);
  const user = useAppSelector(selectUser);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const navigate = useNavigate();
  const [remark, setRemark] = useState<any>("");
  const [paymentID, setPaymentID] = useState<any>();
  const [remarkRequire, setRemarkRequire] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [rjloading, setRjLoading] = useState<boolean>(false);
  const toggleStatus = useAppSelector(toggleState);
  const toggleStatus_2 = useAppSelector(toggleState_2);
  const singlePartyInfo = useAppSelector(getSingleParty);
  const dispatch = useAppDispatch();
  const [products, setProducts] = useState<any[]>([getCase?.case_documents]);
  const [totalPrice, setTotalPrice] = useState<number>();
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [open, setOpen] = useState(false);
  const [blobUrl, setBlobUrl] = useState<any>(null);
  const [serviceBailiff, setServiceBailiff] = useState<boolean>(false);

  const handleSelectChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  // console.log(serviceBailiff);

  const handleBailiffCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setServiceBailiff(true);
    } else setServiceBailiff(false);
  };

  // mark for service handler
  const handleChangeMark = (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = event.target.id;
    const isChecked = event.target.checked;

    const selectedItem = getCase?.case_documents.find(
      (item: any) => item.id === itemId
    );

    if (selectedItem) {
      if (isChecked) {
        setSelectedItems((prevSelectedItems) => [
          ...prevSelectedItems,
          {
            case_document_id: selectedItem.id,
            service_by_bailiff: serviceBailiff,
          },
        ]);
      } else {
        setSelectedItems((prevSelectedItems) =>
          prevSelectedItems.filter(
            (item) => item.case_document_id !== selectedItem.id
          )
        );
      }
    }
  };

  useEffect(() => {
    fetchDataFromBackend();
  }, [getCase?.case_documents]);

  const fetchDataFromBackend = () => {
    // Simulated data from the backend

    const updatedProducts = getCase?.case_documents.map((data: any) => ({
      case_document_id: data.id,
      file_path: data.file_path,
      title: data.title,
      price: 0,
    }));
    // Initialize price as empty string

    setProducts(updatedProducts);
  };

  const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
    selectFromResult: ({ data, isLoading, isError }: any) => ({
      paymentData: data?.data,
      isLoadingCases: isLoading,
    }),
  });

  useEffect(() => {
    handlePaymentData(paymentData);
  }, [paymentData]);

  useEffect(() => {
    refetch();
  }, [page]);

  const handlePaymentData = (paymentData: any[]) => {
    paymentData?.map((item: any) => {
      if (item?.service?.id === getCase?.id) {
        // console.log(item, 'ddd');

        setPaymentID(item);
        return item;
      }
    });
  };

  const handleAdvisePayment = async () => {
    setLoading(true);
    Api.post(
      `/registra2/case-folders/${getCase?.id}/assignment-ready`,
      {
        user_role: selectedOption,
        // service_by_bailiff: serviceBailiff,
        case_documents: selectedItems,
      },
      getJSONheader()
    )
      .then((res) => {
        toast.success(`${res?.data.message}`, {
          duration: 3000,
        });
        // console.log(res.data);
        setLoading(false);

        navigate("/registrar-2/case-folders");
      })
      .catch((error: any) => {
        setLoading(false);
        toast.error(error?.response?.data?.message, {
          duration: 3000,
        });
      });
  };
  const handleReject = () => {
    if (remark) {
      setRjLoading(true);
      Api.post(
        `/registra2/case-folders/${getCase?.id}/reject`,
        { remark: remark },
        getJSONheader()
      )
        .then((res) => {
          // console.log(res);
          navigate("/registrar-2/case-folders");
          toast.success(`${res?.data.message}`, {
            duration: 3000,
          });
          setRjLoading(false);
        })
        .catch((error) => {
          setRjLoading(false);
          toast.error(error?.response?.data?.message, {
            duration: 3000,
          });
        });
    } else {
      setRemarkRequire("A remark is required");
    }
  };

  const handleUpdateDoc = async (file: File, item: any) => {
    // console.log(item, 'na me be this');

    try {
      const formData = new FormData();

      // Convert the File into a Blob with a specific name
      const fileBlob = new Blob([file], { type: "application/pdf" });
      formData.append("file_path", fileBlob, "file.pdf");

      const res: any = await Api.post(
        `registra2/case-documents/${item?.id || item?.case_document_id}/update`,
        formData
        // getJSONheader() - If you need to set headers, you can add them here
      );
      console.log(res, "eeee");

      if (res) {
        toast.success(`stamped successfully. ${res?.data?.message}`, {
          duration: 3000,
        });
      }
      setOpen(true);
      setBlobUrl(res?.data?.data?.file_path);
    } catch (error: any) {
      toast.error(error?.response?.data?.message, {
        duration: 3000,
      });
    }
  };

  const HandleOpenDoc = () => {
    return (
      <Modal
        open={open}
        close={() => {
          setOpen(false);
          setBlobUrl(null);
        }}
        maxWidth='lg'
      >
        <iframe
          title='PDF Viewer'
          src={blobUrl}
          className='w-full '
          height={900}
        />
      </Modal>
    );
  };

  const addStamp = async (pdf: string, item: any) => {
    const url = pdf;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pages = pdfDoc.getPages();
    const lastPage = pages[pages.length - 1];

    // Check if the last page is the original last page
    const isLastPageOriginal = lastPage === pages[pages.length - 1];

    const pngUrl = user?.stamp_photo;
    const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
    const pngImage = await pdfDoc.embedPng(pngImageBytes);

    if (isLastPageOriginal) {
      lastPage.drawImage(pngImage, {
        x: 350,
        y: 100,
        width: 150,
        height: 150,
        rotate: degrees(0),
        opacity: 0.6,
      });
    }

    // Get today's date and format it as "10-12-2023"
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()}`;

    if (isLastPageOriginal) {
      lastPage.drawText(formattedDate, {
        x: 400, // Adjust the x-coordinate to position the text as desired
        y: 140, // Adjust the y-coordinate to position the text as desired
        size: 12, // Adjust the font size
        color: rgb(0, 0, 0), // Text color (black)
        opacity: 0.6,
      });
    }

    const pdfBytes = await pdfDoc.save();

    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const blobUrl = URL.createObjectURL(blob);

    const file = new File([blob], "stamped.pdf", { type: "application/pdf" });

    handleUpdateDoc(file, item);

    URL.revokeObjectURL(blobUrl);
  };

  // console.log('selectedItems', getCase?.case_documents);
  return (
    <>
      <div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
        <Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
      </div>

      <a
        type='button'
        className='text-sm my-4 px-4 lg:px-8 flex justify-end items-center w-full cursor-pointer'
        onClick={() => dispatch(setToggle_2(true))}
      >
        <sup className='animate-ping h-2 w-2 rounded-full bg-brand_blue/70'></sup>
        <img src={QUESTION} className='h-4' />{" "}
        <span className='mx-1'>{` Information on `}</span>
        <span className='text-brand_blue font-bold'>{` filing fees`}</span>
      </a>

      <div className='pt-4 px-4 lg:px-8 mb-4'>
        <Typography textStyle='text-base font-medium mb-2'>
          Party Information
        </Typography>
        <div className='my-2'>
          {getCase?.parties?.length > 0 ? (
            <TableComponent
              className='rounded-none rounded-t-0'
              DATA={getCase?.parties}
              COLUMNS={PARTY_COLUMNS_view}
              TITLE=''
              isExport={null}
              isPaginated={null}
              isSearch={null}
              size={size}
              setSize={setSize}
              setPageIndex={(value: number) => page + 1}
            />
          ) : (
            <Typography variant='label' textStyle='font-medium text-center'>
              No Party Information
            </Typography>
          )}
        </div>
        <div className='pt-8'>
          <Typography textStyle='text-base font-medium '>
            Uploaded Documents
          </Typography>
          <div className='my-2 lg:flex justify-between items-center flex-wrap '>
            {getCase?.case_documents?.length > 0 ? (
              getCase?.case_documents.map((item: any, index: number) => (
                <div className='w-full lg:w-[48%]'>
                  <div
                    key={index}
                    className='flex justify-between items-center mb-3 border border-gray-200 w-full  p-3  rounded-lg'
                  >
                    <div className='flex justify-start items-center '>
                      <a target='_blank'>
                        <img src={PDF} className='h-6 w-6' />
                      </a>
                      <div className='ml-4'>
                        <p className=' text-gray-400 text-xs '>
                          Document Title
                        </p>
                        <p className=' text-black font-bold text-sm mt-1'>
                          {item?.title}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mb-4'>
                    <a
                      href={item?.file_path}
                      target='_blank'
                      className='px-2 py-1 bg-brand_indigo text-[#fff] text-xs  w-28  rounded-md'
                    >
                      View only
                    </a>
                    <button
                      onClick={() => addStamp(item?.file_path, item)}
                      className='px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20 ml-4 rounded-md'
                    >
                      Append Stamp
                    </button>
                  </div>
                </div>
              ))
            ) : (
              <Typography variant='label' textStyle='font-medium text-center'>
                No Uploaded Documents
              </Typography>
            )}
          </div>
        </div>
        <div className='pt-8 mb-4'>
          <Typography textStyle='text-base font-medium mb-2'>
            Mark For Service
          </Typography>

          <div className='my-2 lg:flex justify-between items-center flex-wrap'>
            {getCase?.case_documents.map((item: any) => (
              <div className='relative flex justify-start w-full  md:w-[48%] lg:w-[28%]'>
                <label
                  htmlFor={item?.id}
                  className='flex  w-full px-4 py-2 border border-gray-300 justify-between items-center rounded-xl bg-white bg-opacity-90 backdrop-blur-2xl hover:bg-opacity-75 cursor-pointer transition'
                >
                  <div className='flex justify-start items-center'>
                    <input
                      type='checkbox'
                      name={item?.id}
                      id={item?.id}
                      className='peer'
                      onChange={handleChangeMark}
                    />
                    <>
                      <a href={item?.file_path} target='_blank'>
                        <img src={PDF} className='ml-1 h-6 w-6' />
                      </a>
                    </>

                    <Typography variant='label' textStyle='ml-4'>
                      {item.title}
                    </Typography>
                  </div>

                  <>
                    <span className='text-gray-400 text-xs font-medium ml-4'>
                      {`${formatCurrency({
                        iso: "en-ng",
                        slug: "NGN",
                      }).format(item?.price)}`}
                    </span>
                    {item?.price !== 0 && (
                      <p className='text-[#22C55E] text-sm font-semibold text-center'>
                        Paid
                      </p>
                    )}
                  </>
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className={` justify-between lg:flex  items-start mt-6`}>
          <div className='w-1/3'>
            <Typography textStyle='text-base font-medium mb-2'>
              Submit to User
            </Typography>

            <div className='relative '>
              <select
                value={selectedOption}
                onChange={handleSelectChange}
                className='block appearance-none w-full bg-white border border-gray-300 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500'
              >
                <option value=''>Select a Designation</option>
                <option value='PRESIDING JUDGE'>Presiding Judge</option>
                <option value='PRESIDING MAGISTRATE'>
                  Presiding Magistrate
                </option>
              </select>
              <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700'>
                <svg
                  className='fill-current h-4 w-4'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 20 20'
                >
                  <path
                    fillRule='evenodd'
                    d='M12.95 7.95a.5.5 0 01.7-.7l5 5a.5.5 0 010 .7l-5 5a.5.5 0 01-.7-.7L17.29 13H2.5a.5.5 0 010-1h14.79l-4.04-4.04a.5.5 0 01-.03-.65z'
                    clipRule='evenodd'
                  />
                </svg>
              </div>
            </div>

            <div className='relative mt-6'>
              <span className=''>Service to Bailiff</span>
              <input
                type='checkbox'
                name={"bailiffCheck"}
                id={"bailffCheck"}
                className='peer ml-2'
                onChange={handleBailiffCheck}
              />
            </div>
          </div>
          <div className='w-full lg:w-[40%]'>
            <Typography textStyle='text-base font-medium mb-2'>
              <>
                Remarks <sup className='text-red-600 text-xs'>*</sup>
              </>
            </Typography>

            <textarea
              name='remark'
              placeholder={"Kindly add remarks"}
              className='bg-[#13BAED0D] h-40 w-full resize-none flex justify-start p-4 text-sm'
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
                setRemarkRequire("");
              }}
            />
            <small className='text-red-600'>{remarkRequire}</small>
          </div>
        </div>
        <div className='w-full md:flex justify-between items-end mt-10'>
          <div className='w-full lg:w-[45%] flex justify-between items-center flex-wrap'>
            <Button
              icons={CHEVRON_LEFT}
              iconClassName='h-[0.9rem] w-3'
              title='Back'
              bgColor={"bg-brand_indigo"}
              onClick={() => navigate("/registrar-2/case-folders")}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-3 w-32 hidden md:flex"}
            />

            <Button
              isLoading={rjloading}
              icons={MINUS}
              iconClassName='h-[0.9rem] w-3'
              title='Reject  Folder'
              bgColor={"bg-red-600"}
              onClick={() => handleReject()}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-3 w-40 lg:w-44"}
            />
            <div className='w-40 md:w-44 md:hidden'>
              <Button
                isLoading={loading}
                icons={FLAG}
                iconClassName='h-[0.9rem] w-3'
                bgColor={"bg-[#27AE60]"}
                title={"Ready to Submit"}
                onClick={() => handleAdvisePayment()}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-3 w-full"}
              />
            </div>
          </div>

          <div className='w-[30%] md:w-44 mx-auto lg:mx-0 mt-3 lg:mt-0'>
            <Button
              icons={CHEVRON_LEFT}
              iconClassName='h-[0.9rem] w-3'
              title='Back'
              bgColor={"bg-brand_indigo"}
              onClick={() => navigate("/registrar-2/case-folders")}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-3 w-32 md:hidden flex"}
            />
            <Button
              isLoading={loading}
              icons={FLAG}
              iconClassName='h-[0.9rem] w-3'
              bgColor={"bg-[#27AE60]"}
              title={"Ready to Submit"}
              onClick={() => handleAdvisePayment()}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-3 w-full hidden md:flex"}
            />
          </div>
        </div>
        {HandleOpenDoc()} {/* Conditionally render the modal */}
        <PartyInformation
          open={toggleStatus}
          close={() => dispatch(setToggle(false))}
          data={singlePartyInfo}
        />
        <FillingFee
          open={toggleStatus_2}
          close={() => dispatch(setToggle_2(false))}
        />
      </div>
    </>
  );
}

export default ProcessPayment;

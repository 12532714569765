import { useState } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const data = [
	{ name: 'Group A', value: 400 },
	{ name: 'Group B', value: 300 },
];
const COLORS = ['#272556', '#13BAED'];

const PieCharts = () => {
	return (
		// <ResponsiveContainer width='100%' height='100%'>
		<PieChart width={400} height={200}>
			<Pie
				data={data}
				cx={120}
				cy={100}
				innerRadius={60}
				outerRadius={80}
				fill='#8884d8'
				// paddingAngle={5}
				dataKey='value'
			>
				{data.map((entry, index) => (
					<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
				))}
			</Pie>
		</PieChart>
		// </ResponsiveContainer>
	);
};

export default PieCharts;

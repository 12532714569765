import * as yup from 'yup';

export const signupSchema = yup.object().shape({
	email_ng: yup
		.string()
		.email('Invalid email format')
		.test(
			'nigerianbar',
			'Email must be your Legal Mail from the Nigerian Bar Association',
			(value) => {
				if (!value) return true; // Empty value is handled by `.required()` validation
				return value.endsWith('@nigerianbar.ng');
			}
		),
	// .required('Email is required'),

	email: yup
		.string()
		.email(
			"Please enter a valid email. 'Email must be without space either in front of it, within or after it.'"
		),
	// .required('Required'),
	first_name: yup.string().required('First name is required'),
	last_name: yup.string().required('Last name is required'),
	role: yup.string().required('Role is required'),
	gender: yup.string().required('Gender is required'),
	password: yup
		.string()
		.min(8, 'Password must be at least 8 characters')
		.required('Password is required'),
	confirm_password: yup
		.string()
		.oneOf([yup.ref('password'), undefined], 'Passwords must match')
		.required('Confirm password is required'),
	phone_number: yup.string().required('Phone number is required'),
	whatsapp: yup.string().required('WhatsApp is required'),
	// age: yup.number().required('Age is required').positive('Age must be a positive number'),
});

import { FaBell, FaHome, FaUserAlt } from 'react-icons/fa';
import { BsCreditCard2FrontFill, BsList } from 'react-icons/bs';
import { ImFilesEmpty } from 'react-icons/im';
import { MdMeetingRoom } from 'react-icons/md';

export const lawyer_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/lawyer',
	},
	{
		id: '2',
		title: 'My Files',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/lawyer/my-files',
	},

	{
		id: '4',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/lawyer/notification',
	},
	{
		id: '5',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/lawyer/profile',
	},
	// {
	// 	id: '6',
	// 	title: 'Payment',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/lawyer/payment',
	// },
];
export const nonlawyer_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/non-lawyer',
	},
	{
		id: '2',
		title: 'My Files',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/non-lawyer/my-files',
	},

	{
		id: '4',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/non-lawyer/notification',
	},
	{
		id: '5',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/non-lawyer/profile',
	},
	// {
	// 	id: '6',
	// 	title: 'Payment',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/lawyer/payment',
	// },
];

export const registrar_1_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/registrar-1',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/registrar-1/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/registrar-1/notification',
	},

	// {
	// 	id: '5',
	// 	title: 'Concluded Cases',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/registrar-1/concluded-cases',
	// },
	{
		id: '7',
		title: 'Payments',
		icon: BsCreditCard2FrontFill,
		alt: 'pay...',
		path: '/registrar-1/payments',
	},
	{
		id: '6',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/registrar-1/profile',
	},
];
export const registrar_2_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/registrar-2',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/registrar-2/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/registrar-2/notification',
	},

	// {
	// 	id: '5',
	// 	title: 'Concluded Cases',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/registrar-2/concluded-cases',
	// },
	{
		id: '6',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/registrar-2/profile',
	},
];
export const presiding_magistrate_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/presiding-magistrate',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/presiding-magistrate/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/presiding-magistrate/notification',
	},
	{
		id: '4',
		title: 'Magistrates',
		icon: FaBell,
		alt: 'bell...',
		path: '/presiding-magistrate/magistrates',
	},

	{
		id: '7',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/presiding-magistrate/profile',
	},
];
export const presiding_registrar_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/presiding-judge',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/presiding-judge/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/presiding-judge/notification',
	},
	{
		id: '4',
		title: 'Judges',
		icon: FaBell,
		alt: 'bell...',
		path: '/presiding-judge/judges',
	},
	// {
	// 	id: '5',
	// 	title: 'Assign',
	// 	icon: FaBell,
	// 	alt: 'bell...',
	// 	path: '/presiding-judge/assign',
	// },

	// {
	// 	id: '6',
	// 	title: 'History',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/presiding-judge/history',
	// },
	{
		id: '7',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/presiding-judge/profile',
	},
];
export const judge_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/judge',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/judge/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/judge/notification',
	},
	{
		id: '4',
		title: 'Cause list',
		icon: BsList,
		alt: 'bell...',
		path: '/judge/cause-list',
	},

	// {
	// 	id: '5',
	// 	title: 'Assign',
	// 	icon: FaBell,
	// 	alt: 'bell...',
	// 	path: '/presiding-judge/assign',
	// },

	// {
	// 	id: '6',
	// 	title: 'History',
	// 	icon: BsCreditCard2FrontFill,
	// 	alt: 'pay...',
	// 	path: '/presiding-judge/history',
	// },
	{
		id: '7',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/judge/profile',
	},
];
export const magistrate_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/magistrate',
	},
	{
		id: '2',
		title: 'Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/magistrate/case-folders',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/magistrate/notification',
	},
	{
		id: '4',
		title: 'Cause list',
		icon: BsList,
		alt: 'bell...',
		path: '/magistrate/cause-list',
	},

	{
		id: '7',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/magistrate/profile',
	},
];
export const bailiff_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/bailiff',
	},
	{
		id: '2',
		title: 'Service',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/bailiff/service',
	},

	{
		id: '3',
		title: 'Notifications',
		icon: FaBell,
		alt: 'bell...',
		path: '/bailiff/notification',
	},
	// {
	// 	id: '4',
	// 	title: 'Cause list',
	// 	icon: FaBell,
	// 	alt: 'bell...',
	// 	path: '/bailiff/cause-list',
	// },

	{
		id: '7',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/bailiff/profile',
	},
];
export const admin_sidebar = [
	{
		id: '1',
		title: 'Dashboard',
		icon: FaHome,
		alt: 'pie...',
		path: '/admin/dashboard',
	},
	{
		id: '2',
		title: 'All Case Folders',
		icon: ImFilesEmpty,
		alt: '..',
		path: '/admin/case-folders',
	},

	{
		id: '3',
		title: 'Manage Users',
		icon: FaBell,
		alt: 'bell...',
		path: '/admin/manage-users',
	},
	{
		id: '4',
		title: 'Payments',
		icon: BsCreditCard2FrontFill,
		alt: 'bell...',
		path: '/admin/payments',
	},
	{
		id: '5',
		title: 'Profile',
		icon: FaUserAlt,
		alt: 'user...',
		path: '/admin/profile',
	},
];
export interface SidebarDataTypes {
	id: string;
	title: string;
	icon: any;
	alt?: string;
	path: string;
}

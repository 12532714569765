import React from 'react';
import { NavProvider } from './NavigationBar/context/NavbarContext';
import NavBar from './NavigationBar';
import Hero from './Hero';
import Overview from './Overview';
import Benefits from './Benefits';
import How from './HowItWorks';
import FAQ from './FAQs';
import ContactForm from './ContactForms';
import Footer from './Footer';
import Help from './help';

function Home() {
	return (
		<NavProvider>
			<div className='sticky top-0  z-50 '>
				<NavBar />
			</div>
			<Hero />
			<section id='overview'>
				<Overview />
			</section>
			<section id='services'>
				<Benefits />
			</section>

			<How />

			<section id='faqs'>
				<FAQ />
			</section>

			<ContactForm />
			<Help />
			<Footer />
		</NavProvider>
	);
}

export default Home;

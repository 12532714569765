import React from "react";
import { DashboardLayout } from "../layouts/dashboard";
import { Route, Routes } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { selectRole, selectUser } from "../states/slices/authReducer";
import { registrarRoutes, registrar_2_Routes, lawyerRoutes } from "../data";
import { presiding_judge_Routes } from "../data/presiding-judge";
import { admin_Routes } from "../data/manage-users";
import { presiding_magistrate_Routes } from "../data/presiding-magistrate";
import { judge_Routes } from "../data/judge";
import { magistrate_Routes } from "../data/magistrate";
import { bailff_Routes } from "../data/bailiff";
import { NonlawyerRoutes } from "../data/nonLawyer";

// main routing modaules

function MainRoutes() {
  const user = useAppSelector(selectUser);
  const role = useAppSelector(selectRole);

  // console.log(user);

  return (
    <div>
      <DashboardLayout>
        <Routes>
          {role === "LAWYER" &&
            lawyerRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "NON LAWYER" &&
            NonlawyerRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "REGISTRAR 1" &&
            registrarRoutes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "REGISTRAR 2" &&
            registrar_2_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "PRESIDING JUDGE" &&
            presiding_judge_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "PRESIDING MAGISTRATE" &&
            presiding_magistrate_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "MAGISTRATE" &&
            magistrate_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "JUDGE" &&
            judge_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "BAILIFF" &&
            bailff_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}
          {role === "ADMIN" &&
            admin_Routes.map((item, index) => (
              <Route
                key={index}
                path={item.path}
                element={<item.component />}
              />
            ))}

          {/* {user.role === 'lawyer' && lawyerRoutes.map((item, index) => (
						<Route key={index} path={item.path} element={<item.component />} />
					))}
					{user.role === 'register' && lawyerRoutes.map((item, index) => (
						<Route key={index} path={item.path} element={<item.component />} />
					))} */}
        </Routes>
      </DashboardLayout>
    </div>
  );
}

export default MainRoutes;

import React from 'react';
import { partyInfoTypes } from '../../types';
import { Zoom } from 'react-reveal';
import Modal from '../UI/modal';
import Typography from '../Typography';
import { FormInput } from '../form/Input';

interface PartyInformationTypes {
	data: partyInfoTypes;
	close: () => void;
	open: boolean;
}
function PartyInformation(props: PartyInformationTypes) {
	return (
		<Modal open={props.open} close={props.close} maxWidth='md'>
			<>
				<div className='px-8'>
					<Zoom bottom>
						<div className='px-8  pb-12'>
							<Typography variant='h3'>Party Information</Typography>
							<form className=''>
								<div className='flex justify-between items-center flex-wrap'>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
										<label htmlFor='email' className='text-sm'>
											Title <small className='text-red-500'>*</small>
										</label>
										<select
											disabled
											defaultValue={props.data?.title}
											className={`mb-2 appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
										>
											<option value='' className='text-gray-200'>
												Select an option
											</option>
											<option value='CLAIMANT'>Claimant</option>
											<option value='DEFENDANT'>Defendant</option>
											<option value='APPLICANT'>Applicant</option>
											<option value='RESPONDENT'>Respondent</option>
											<option value='APPELLANT'>Appellant</option>
										</select>
									</div>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2'>
										<label htmlFor='email' className='text-sm'>
											First name
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your first name'
											disabled
											defaultValue={props.data?.first_name}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='lastname' className='text-sm'>
											Last name
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your last name'
											disabled
											defaultValue={props.data?.last_name}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Other name
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your other name'
											disabled
											defaultValue={props.data?.other_name}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='email' className='text-sm'>
											Email
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your email'
											disabled
											defaultValue={props.data?.email}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='phone' className='text-sm'>
											Phone / Whatsapp Number
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your phone number or whatsapp number'
											disabled
											defaultValue={props.data?.phone_number}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='contact' className='text-sm'>
											Contact Address
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your contact address'
											disabled
											defaultValue={props.data?.address}
										/>
									</div>
								</div>
							</form>
						</div>
					</Zoom>
				</div>
			</>
		</Modal>
	);
}

export default PartyInformation;

import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { MdExpandMore } from 'react-icons/md';
import Typography from '../../../components/Typography';
import { PARTY_COLUMNS, PARTY_COLUMNS_EXISTING } from '../../../components/table/columns';
import { TableComponent } from '../../../components/table/Table';
import Button from '../../../components/button';
import { FLAG, PDF, PLUS, UPLOAD } from '../../../assets';
import { FormInput } from '../../../components/form/Input';
import { partyInfoTypes } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	addDoc,
	addValue,
	getDocument,
	getPartyInfo,
	removeDoc,
	resetDoc,
	resetValue,
	setReload,
} from '../../../states/slices/partyInfo.slice';
import Api from '../../../config';
import { formatCurrency, getFormDataHeader, getJSONheader } from '../../../utils';
import { BsFillTrash2Fill } from 'react-icons/bs';
import { useDropzone } from 'react-dropzone';
import { CircularProgress, Stack } from '@mui/material';
import { ADMINSINGLE_CASE_COLUMNS } from '../../../components/table/columns/admin';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function CreateCases() {
	const [partyValue, setPartyValue] = useState<partyInfoTypes>({
		first_name: '',
		last_name: '',
		other_name: '',
		phone_number: '',
		email: '',
		title: '',
		address: '',
	});
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(false);
	const [userData, setUserData] = useState<any>(null);
	const [size, setSize] = useState(3);
	const dispatch = useAppDispatch();
	const partyInfo = useAppSelector(getPartyInfo);
	const [required, setRequired] = useState<string>('');
	const [partyErr, setPartyErr] = useState<string>('');
	const docUploadData = useAppSelector(getDocument);
	const [file, setFile] = useState<any>([]);
	const [title, setTitle] = useState<any>('');
	const [waiver, setWaiver] = useState<any>(0);
	const [caseType, setCaseType] = useState<any>('');
	const [caseTitle, setCaseTitle] = useState<any>('');
	const [caseTitleErr, setCaseTitleErr] = useState<any>('');
	const [caseTypeErr, setCaseTypeErr] = useState<any>('');
	const [suitNumber, setSuitNumber] = useState<any>('');
	const [suitNumErr, setSuitNumErr] = useState<any>('');
	const [caseData, setCaseData] = useState<any>('');
	const [from_user_id, setFrom_user_id] = useState<any>('');
	const [to_user_id, setTo_user_id] = useState<any>('');
	const [sendErr, setSendErr] = useState<any>('');
	const navigate = useNavigate();
	const [isLoading, setIsloading] = useState<boolean>(false);

	useEffect(() => {
		fetchDataFromBackend();
	}, [docUploadData]);

	useEffect(() => {
		if (!userData) {
			handleSearchUsers();
		}
	}, [userData]);

	const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSuitNumber(value);
		// Call the endpoint after typing finishes (debounced by 500ms)
	};

	const handleSuitSearch = useCallback(() => {
		setLoading(true);
		Api.get(`/admin/case-folders?search=${suitNumber}`, getJSONheader()).then((res) => {
			setLoading(false);
			setCaseData(res.data.data);
		});
	}, [suitNumber]);

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		maxSize: 50000000,
		accept: {
			'application/pdf': ['.pdf'],
		},
		onDrop: (acceptedFiles) => {
			const file = acceptedFiles[0];
			setFile(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const productFiles = file.map((item: any, index: number) => (
		<>
			<div className='flex justify-start items-center'>
				<img src={PDF} className='h-6 w-6 ' />
				<p className='text-center text-green-600 text-sm ml-4' key={index}>
					{item.name}
				</p>
			</div>
			<div className='flex justify-end'>
				<BsFillTrash2Fill size={'1em'} color='red' onClick={() => removeAll()} />
			</div>
		</>
	));

	const removeAll = () => {
		setFile([]);
	};

	const [products, setProducts] = useState<any>(docUploadData);
	const [totalPrice, setTotalPrice] = useState<number>();

	const handleDocumentUpload = (e: React.FormEvent) => {
		e.preventDefault();
		dispatch(addDoc({ title, file_path: file[0] }));
		setFile([]);
		setTitle('');
	};

	// Function to handle input value changes
	const handleInputChange = (index: any, event: any) => {
		const { value } = event.target;

		setProducts((prevProducts: any) => {
			const updatedProducts = [...prevProducts];
			updatedProducts[index].price = value;

			// Calculate the total sum
			const total = updatedProducts.reduce((sum, product) => {
				return sum + (parseFloat(product.price) || 0); // Convert price to a number before adding
			}, 0);

			setTotalPrice(total); // Update the total state variable

			return updatedProducts;
		});
	};

	const fetchDataFromBackend = () => {
		// Simulated data from the backend

		const updatedProducts = docUploadData.map((data: any) => ({
			file_path: data.file_path,
			title: data.title,
			price: 0,
		}));
		// Initialize price as empty string

		setProducts(updatedProducts);
	};

	const handlePartyHandler = (e: React.FormEvent) => {
		e.preventDefault();
		if (
			!partyValue.first_name ||
			!partyValue.title ||
			!partyValue.last_name ||
			!partyValue?.address
		) {
			setRequired('required');
		} else {
			dispatch(addValue(partyValue));
			setPartyValue({
				...partyValue,
				first_name: '',
				last_name: '',
				other_name: '',
				phone_number: '',
				email: '',
				address: '',
			});
			setRequired('');
		}
	};

	const handleSearchUsers = () => {
		setLoading(true);
		Api.get(`/admin/users`, getJSONheader()).then((res) => {
			setLoading(false);
			setUserData(res.data.data);
		});
	};

	const handleAddExistingParty = (e: any) => {
		e.preventDefault();
		let x = 5;
		if (
			!partyValue.first_name &&
			!partyValue.title &&
			!partyValue.last_name &&
			!partyValue?.address
		) {
			setRequired('required');
		} else {
			Api.post(
				`admin/case-folders/${caseData[0].admin_case_status}/add-party`,
				partyValue,
				getJSONheader()
			)
				.then((res) => {
					// console.log(res);
					setPartyValue({
						...partyValue,
						first_name: '',
						last_name: '',
						other_name: '',
						phone_number: '',
						email: '',

						address: '',
					});
					setRequired('');
					dispatch(setReload(x++));
					toast.success(res.data.message, {
						duration: 3000,
					});
				})
				.catch((err) => {
					toast.error(err.response.data.message, {
						duration: 3000,
					});
				});
		}
	};
	const handleAddExistingDoc = (e: any) => {
		e.preventDefault();
		let x = 5;
		if (file?.length < 1) {
			setRequired('required');
		} else {
			Api.post(
				`admin/case-folders/${caseData[0]?.id}/add-document`,
				{ title, file_path: file[0] },
				getFormDataHeader()
			)
				.then((res) => {
					// console.log(res);
					setFile([]);
					dispatch(setReload(x++));
					toast.success(res.data.message, {
						duration: 3000,
					});
					setRequired('');
				})
				.catch((err) => {
					toast.error(err.response.data.message, {
						duration: 3000,
					});
				});
		}
	};

	const handleDeleteExistingDoc = (docId: string) => {
		let x = 9;
		Api.delete(`/admin/case-documents/${docId}`, getJSONheader()).then((res) => {
			// console.log(res);
			dispatch(setReload(x++));
			toast.success(res.data.message, {
				duration: 3000,
			});
		});
	};

	const handleCreateExistingCase = async () => {
		const formData = {
			suit_number: suitNumber,
			parties: partyInfo,
			documents: products,
			case_type: caseType,
			require_payment: waiver === 1 ? 0 : 1,
			from_user_id: from_user_id?.id,
			to_user_id: to_user_id?.id,
			title: caseTitle,
		};
		// documents: data?.case_documents?.map((item: any) => {
		// 	const parties = { title: item?.title, file_path: item?.file_path };

		// 	return parties;
		// }),

		try {
			const res: any = Api.post(
				`/admin/case-folders/case-status/${caseData[0]?.id}/resubmit`,
				formData,
				getJSONheader()
			);

			toast.success('Case Created Successfully', {
				duration: 3000,
			});
			dispatch(resetDoc());
			dispatch(resetValue());

			setPartyErr('');
			// setUploadErr('');
			setSuitNumber(null);
		} catch (error) {
			// console.log(error);
			setPartyErr('');
			// setUploadErr('');
		}
	};

	const handleCreateCase = () => {
		setIsloading(true);
		const formData = {
			suit_number: suitNumber,
			parties: partyInfo,
			documents: products,
			case_type: caseType,
			require_payment: waiver === 1 ? 0 : 1,
			from_user_id: from_user_id?.id,
			to_user_id: to_user_id?.id,
			title: caseTitle,
		};

		if (!caseType) {
			setCaseTypeErr('case type is required');
		} else if (!suitNumber) {
			setSuitNumErr(
				'Suit number  is required. Suit number should be an existing suit number for defendents or new for other titles'
			);
		} else if (!caseTitle) {
			setCaseTitleErr('Case title is required');
		} else if (partyInfo.length < 1) {
			setPartyErr('Party information is required');
			// } else if (from_user_id?.id || to_user_id?.id) {
			// 	setSendErr('Recipient information is required');
		} else {
			Api.post(`/admin/case-folders`, formData, getFormDataHeader())
				.then((res) => {
					dispatch(resetDoc());
					dispatch(resetValue());
					setPartyErr('');
					setCaseTypeErr('');
					setSuitNumErr('');
					setCaseTitleErr('');
					setSendErr('');
					setIsloading(false);
					navigate('/admin/dashboard');
					toast.success(res?.data?.message, {
						duration: 3000,
					});
				})
				.catch((error: any) => {
					// console.log(error);
					setPartyErr('');
					dispatch(resetDoc());
					dispatch(resetValue());
					setCaseTypeErr('');
					setSuitNumErr('');
					setCaseTitleErr('');
					setSendErr('');
					setIsloading(false);
					// setUploadErr('');
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
				});
		}
	};

	const handleChangeFrom = (event: any, newValue: any) => {
		setFrom_user_id(newValue);
	};
	const handleChangeTo = (event: any, newValue: any) => {
		setTo_user_id(newValue);
	};

	return (
		<div className='pt-4 px-4 lg:px-8 h-full'>
			{/* case type */}
			{caseTypeErr && <small className='text-red-500 mb-2'>{caseTypeErr}</small>}
			<Accordion
				sx={{
					boxShadow: 'none',
					backgroundColor: '#13BAED0D',
					marginBottom: 2,
				}}
			>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Case Type</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className='bg-[#F3F4F6]/70 p-4 rounded-xl'>
						<Typography variant='label' textStyle='mb-2'>
							<>
								<span className='font-bold'>Select Case Type </span>(New Case or Concluded
								(Archived))
							</>
						</Typography>
						<div className=' w-full lg:w-[48%]'>
							<select
								value={caseType}
								onChange={(e) => {
									setCaseType(e.target.value);
									setCaseTypeErr('');
								}}
								className='block appearance-none w-full bg-white border outline-none border-[#13BAED0D] text-sm text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#13BAED0D]'
							>
								<option value=''>Select a case type</option>
								<option value='concluded'>Concluded</option>
								<option value='new'>New</option>
							</select>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			{caseTitleErr && <small className='text-red-500 mb-2'>{caseTitleErr}</small>}
			<Accordion
				square
				sx={{
					boxShadow: 'none',
					backgroundColor: '#13BAED0D',
					marginBottom: 2,
				}}
			>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel2a-content'
					id='panel2a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Lawyer Information</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className='bg-[#F3F4F6]/70 p-4 rounded-xl'>
						<Typography variant='label' textStyle='mb-2 font-bold'>
							Title
						</Typography>

						<div className=' w-full lg:w-[48%]'>
							<select
								value={caseTitle}
								onChange={(e) => {
									setCaseTitle(e.target.value);
									setCaseTitleErr('');
								}}
								className='block appearance-none w-full bg-white border outline-none border-[#13BAED0D] text-sm text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#13BAED0D]'
							>
								<option value='' className='text-gray-200'>
									Select an option
								</option>
								<option value='CLAIMANT'>Claimant</option>
								<option value='DEFENDANT'>Defendant</option>
								<option value='APPLICANT'>Applicant</option>
								<option value='RESPONDENT'>Respondent</option>
								<option value='APPELLANT'>Appellant</option>
							</select>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			{suitNumErr && <small className='text-red-500 mb-2'>{suitNumErr}</small>}

			<Accordion square sx={{ boxShadow: 'none', backgroundColor: '#13BAED0D', marginBottom: 2 }}>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel3a-content'
					id='panel3a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Suit Number </Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className='bg-[#F3F4F6]/70 p-4 rounded-xl'>
						<Typography variant='label' textStyle='mb-2 font-bold'>
							Input Suit Number
						</Typography>

						{caseTitle !== 'DEFENDANT' && (
							<>
								<div className='flex items-center    w-full lg:w-[60%] mb-2 lg:mb-6'>
									<input
										value={suitNumber}
										onChange={(e) => {
											setSuitNumber(e.target.value);
											setSuitNumErr('');
										}}
										type='text'
										className='appearance-none h-12 bg-white border outline-none border-[#13BAED0D] text-sm text-gray-700  px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#13BAED0D]'
										placeholder='PHC/000/x/xxxx'
									/>
								</div>
							</>
						)}
						{caseTitle === 'DEFENDANT' && (
							<>
								<div className='flex items-center    w-full lg:w-[60%] mb-2 lg:mb-6'>
									<input
										value={suitNumber}
										onChange={handleSearchInputChange}
										type='text'
										className='appearance-none h-12 bg-white border outline-none border-[#13BAED0D] text-sm text-gray-700  px-4 rounded leading-tight focus:outline-none focus:bg-white focus:border-[#13BAED0D]'
										placeholder='PHC/000/x/xxxx'
									/>
									<div className=' ml-4 my-4'>
										<Button
											iconClassName='h-[0.9rem] w-3'
											title='Search Case Folder'
											bgColor={'bg-brand_blue'}
											onClick={() => handleSuitSearch()}
											hoverColor={''}
											textColor={'text-white'}
											className={'py-4 rounded-lg w-40 '}
										/>
									</div>
								</div>
								{loading && (
									<div className='w-full justify-content items-center'>
										<Stack
											sx={{
												color: 'grey.500',
											}}
											spacing={2}
											direction='row'
											justifyContent={'center'}
											alignItems={'center'}
										>
											<CircularProgress color='primary' />
										</Stack>
									</div>
								)}
								{caseData !== null && caseData?.length > 0 ? (
									<div className='w-full justify-center items-center'>
										<Typography textStyle='text-[14px] font-bold'>Case folder found</Typography>
										<div className='my-2'>
											{caseData?.length > 0 ? (
												<TableComponent
													className='rounded-none rounded-t-0'
													DATA={caseData}
													COLUMNS={ADMINSINGLE_CASE_COLUMNS}
													TITLE=''
													isExport={null}
													isPaginated={null}
													isSearch={null}
													size={size}
													setSize={setSize}
													setPageIndex={() => page + 1}
												/>
											) : (
												<Typography variant='label' textStyle='font-medium text-center'>
													No Case Folder Information
												</Typography>
											)}
										</div>
									</div>
								) : caseData !== null && caseData?.length < 1 ? (
									<div className='w-full justify-center items-center'>No Case folder found</div>
								) : null}
							</>
						)}
					</div>
				</AccordionDetails>
			</Accordion>
			{partyErr && <small className='text-red-500 mb-2'>{partyErr}</small>}
			<Accordion square sx={{ boxShadow: 'none', backgroundColor: '#13BAED0D', marginBottom: 2 }}>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel4a-content'
					id='panel4a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Party Information </Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className='px-8 pt-4 pb-12'>
						<Typography variant='h3'>Party Information</Typography>
						<form className=''>
							{required && <small className='text-red-500'>All * information is compulsory</small>}
							<div className='flex justify-between items-center flex-wrap'>
								<div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
									<label htmlFor='email' className='text-sm'>
										Title <sup className='text-red-500'>*</sup>
									</label>
									<select
										onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
											const selectedOption = event.target.value;
											setPartyValue({
												...partyValue,
												title: selectedOption,
											});
										}}
										className={`mb-2 appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
									>
										<option value='' className='text-gray-200'>
											Select an option
										</option>
										<option value='CLAIMANT'>Claimant</option>
										<option value='DEFENDANT'>Defendant</option>
										<option value='APPLICANT'>Applicant</option>
										<option value='RESPONDENT'>Respondent</option>
										<option value='APPELLANT'>Appellant</option>
									</select>
								</div>
								<div className='flex flex-col   w-full lg:w-[48%] mb-2'>
									<label htmlFor='email' className='text-sm'>
										First name <sup className='text-red-500'>*</sup>
									</label>
									<FormInput
										isDefault={true}
										required={true}
										type='text'
										placeholder='Enter your first name'
										value={partyValue.first_name}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												first_name: text.target.value,
											});
										}}
									/>
								</div>
								<div className='flex flex-col w-full lg:w-[48%] mb-2'>
									<label htmlFor='lastname' className='text-sm'>
										Last name <sup className='text-red-500'>*</sup>
									</label>
									<FormInput
										isDefault={true}
										required={true}
										type='text'
										placeholder='Enter your last name'
										value={partyValue.last_name}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												last_name: text.target.value,
											});
										}}
									/>
								</div>
								<div className='flex flex-col w-full lg:w-[48%] mb-2'>
									<label htmlFor='othername' className='text-sm'>
										Other name
									</label>
									<FormInput
										isDefault={true}
										type='text'
										placeholder='Enter your other name'
										value={partyValue.other_name}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												other_name: text.target.value,
											});
										}}
									/>
								</div>
								<div className='flex flex-col w-full lg:w-[48%] mb-2'>
									<label htmlFor='othername' className='text-sm'>
										Email name <sup className='text-red-500'>*</sup>
									</label>
									<FormInput
										isDefault={true}
										type='text'
										placeholder='Enter your email'
										value={partyValue.email}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												email: text.target.value,
											});
										}}
									/>
								</div>
								<div className='flex flex-col w-full lg:w-[48%] mb-2'>
									<label htmlFor='othername' className='text-sm'>
										Phone / Whatsapp Number
									</label>
									<FormInput
										isDefault={true}
										type='text'
										placeholder='Enter your phone number or whatsapp number'
										value={partyValue.phone_number}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												phone_number: text.target.value,
											});
										}}
									/>
								</div>
								<div className='flex flex-col w-full lg:w-[48%] mb-2'>
									<label htmlFor='othername' className='text-sm'>
										Contact Address <sup className='text-red-500'>*</sup>
									</label>
									<FormInput
										isDefault={true}
										required={true}
										type='text'
										placeholder='Enter your contact address'
										value={partyValue.address}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setPartyValue({
												...partyValue,
												address: text.target.value,
											});
										}}
									/>
								</div>
							</div>

							{/* {caseType === 'new' && ( */}
							<>
								<div className='flex justify-end items-center'>
									<Button
										icons={PLUS}
										iconClassName='h-[0.9rem] w-3'
										title='Add Party Info'
										bgColor={'bg-brand_indigo'}
										onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePartyHandler(e)}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-4 rounded-lg'}
									/>
								</div>

								<div className='my-2'>
									{partyInfo?.length > 0 && (
										<TableComponent
											className='rounded-none rounded-t-0'
											DATA={partyInfo}
											COLUMNS={PARTY_COLUMNS}
											TITLE=''
											isExport={null}
											isPaginated={null}
											isSearch={null}
											size={size}
											setSize={setSize}
											setPageIndex={(value: number) => page + 1}
										/>
									)}
								</div>
							</>
							{/* )} */}
							{/* {caseType === 'concluded' && (
								<>
									<div className='flex justify-end items-center'>
										<Button
											icons={PLUS}
											iconClassName='h-[0.9rem] w-3'
											title='Add Party Info'
											bgColor={'bg-brand_indigo'}
											onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
												handleAddExistingParty(e)
											}
											hoverColor={''}
											textColor={'text-white'}
											className={'py-4 rounded-lg'}
										/>
									</div>

									<div className='my-2'>
										{caseData[0]?.parties?.length > 0 && (
											<TableComponent
												className='rounded-none rounded-t-0'
												DATA={caseData[0]?.parties}
												COLUMNS={PARTY_COLUMNS_EXISTING}
												TITLE=''
												isExport={null}
												isPaginated={null}
												isSearch={null}
												size={size}
												setSize={setSize}
												setPageIndex={(value: number) => page + 1}
											/>
										)}
									</div>
								</>
							)} */}
						</form>
					</div>
				</AccordionDetails>
			</Accordion>
			{sendErr && <small className='text-red-500 mb-2'>{sendErr}</small>}
			<Accordion square sx={{ boxShadow: 'none', backgroundColor: '#13BAED0D', marginBottom: 2 }}>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel5a-content'
					id='panel5a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Send To </Typography>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between items-center bg-[#F3F4F6]/70 p-4 rounded-xl mt-1'>
						<div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
							<Typography variant='label' textStyle='mb-2'>
								<>
									<span className='font-bold'> From </span>(sender)
								</>
							</Typography>

							<div className='w-full mt-2'>
								{userData && (
									<Autocomplete
										disablePortal
										id='combo-box-demo'
										options={userData}
										value={from_user_id}
										onChange={handleChangeFrom}
										getOptionLabel={(option: any) => option.first_name ?? 'Select a Personnel'} // Specify the label property to be displayed in the input field
										sx={{ width: 300 }}
										renderInput={(params) => (
											<TextField {...params} label='Search for required personnel' />
										)}
										renderOption={(props, option) => (
											<>
												{option?.role === 'NON LAWYER' && (
													<li {...props}>
														<div>
															<span className='text-sm'>{`${option?.first_name}   ${
																option?.last_name ?? ''
															} `}</span>{' '}
															{/* Render the label */}
															<span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>
																({option?.role}) {/* Render the year */}
															</span>
														</div>
													</li>
												)}
												{option?.role === 'LAWYER' && (
													<li {...props}>
														<div>
															<span className='text-sm'>{`${option?.first_name}   ${
																option?.last_name ?? ''
															} `}</span>{' '}
															{/* Render the label */}
															<span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>
																({option?.role}) {/* Render the year */}
															</span>
														</div>
													</li>
												)}
											</>
										)}
									/>
								)}
							</div>
						</div>

						<div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
							<Typography variant='label' textStyle='mb-2 '>
								<>
									<span className='font-bold'> To </span>(Recipient)
								</>
							</Typography>

							<div className='w-full mt-2'>
								{userData && (
									<Autocomplete
										disablePortal
										id='combo-box-demo'
										options={userData}
										value={to_user_id}
										onChange={handleChangeTo}
										getOptionLabel={(option: any) => option.first_name ?? 'Select a Personnel'} // Specify the label property to be displayed in the input field
										sx={{ width: 300 }}
										renderInput={(params) => (
											<TextField {...params} label='Search for required personnel ' />
										)}
										renderOption={(props, option) => (
											<>
												{option?.role !== 'LAWYER' && option?.role !== 'NON LAWYER' && (
													<li {...props}>
														<div>
															<span className='text-sm'>{`${option?.first_name}   ${
																option?.last_name ?? ''
															} `}</span>{' '}
															{/* Render the label */}
															<span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>
																({option?.role}) {/* Render the year */}
															</span>
														</div>
													</li>
												)}
											</>
										)}
									/>
								)}
							</div>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			<Accordion square sx={{ boxShadow: 'none', backgroundColor: '#13BAED0D', marginBottom: 2 }}>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel5a-content'
					id='panel5a-header'
				>
					<Typography textStyle='text-[16px] font-medium'>Upload Documents </Typography>
				</AccordionSummary>
				<AccordionDetails>
					<>
						<div className='mt-4'>
							<div className='flex flex-col   w-full lg:w-[48%]'>
								<label htmlFor='email' className='text-sm'>
									Title <small className='text-red-500'>*</small>
								</label>
								<FormInput
									type='text'
									placeholder='Enter your title'
									value={title}
									onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
										setTitle(text.target.value);
									}}
									className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
								/>
							</div>
						</div>
						<div className=' py-2 md:py-4' {...getRootProps()}>
							<div className='w-full'>
								{file.length > 0 ? (
									productFiles
								) : (
									<label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
										<img src={UPLOAD} alt='' className='h-12' />
										<p className='flex flex-col items-center space-x-2'>
											<span className='font-medium text-gray-600 mt-4 text-center text-sm'>
												Click to upload or drag and drop <br />
												Maxiumum file size 10 MB
											</span>
											<a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
												Browse file
											</a>
										</p>
										<span className='text-[#9CA3AF] text-xs mt-4 '>Supported PDF files only</span>
										<input type='file' name='file_upload' className='hidden' {...getInputProps()} />
									</label>
								)}
							</div>
						</div>

						<div className='flex justify-end items-center mt-4'>
							<Button
								icons={PLUS}
								iconClassName='h-[0.9rem] w-3'
								title='Add Document'
								bgColor={'bg-brand_indigo'}
								onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDocumentUpload(e)}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-4 rounded-lg'}
							/>
						</div>

						<div className='my-2 w-full lg:flex justify-between items-center flex-wrap '>
							{products?.length > 0 &&
								products.map((item: any, index: number) => (
									<>
										<div
											key={index}
											className='flex justify-between items-center mb-3 border border-gray-200 w-full lg:w-[48%] p-3  rounded-lg'
										>
											<div className='flex justify-start items-center '>
												<a href={item?.file_path} target='_blank'>
													<img src={PDF} className='h-6 w-6' />
												</a>
												<div className='ml-4'>
													<p className=' text-gray-400 text-xs '>Document Title</p>
													<p className=' text-black font-bold text-sm mt-1'>{item?.title}</p>
												</div>
											</div>

											<input
												className={`${
													waiver === 1 ? 'hidden' : 'visible'
												} border border-gray-300 p-3 rounded-md w-28 h-12`}
												value={item?.price}
												onChange={(event) => handleInputChange(index, event)}
											/>
											<button
												onClick={() => dispatch(removeDoc(item))}
												type='button'
												className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
											>
												Delete
											</button>
										</div>
									</>
								))}
						</div>

						<div className=' w-full flex justify-center items-center'>
							<div className=' w-full lg:w-1/2 '>
								<Typography textStyle='text-[20px] font-medium text-center'>Total</Typography>
								<p className='text-center text-green-600 bg-white text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
									{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(
										totalPrice ? totalPrice : 0
									)}
								</p>
							</div>
						</div>

						<div className='flex justify-center items-center mt-4'>
							<Button
								icons={FLAG}
								iconClassName='h-[0.9rem] w-3'
								title='Submit'
								bgColor={'bg-brand_blue'}
								onClick={() => handleCreateCase()}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-4 rounded-lg w-52'}
								isLoading={isLoading}
							/>
						</div>
					</>
				</AccordionDetails>
			</Accordion>
		</div>
	);
}

import * as yup from 'yup';

// const passwordRules = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@_#$%^&*.])(?=.{8,})');

export const loginSchema = yup.object().shape({
	email: yup
		.string()
		.email(
			"Please enter a valid email. 'Email must be without space either in front of it, within or after it.'"
		)
		.required('Required'),
	password: yup.string().min(8, 'password must be a minimum of 8 characters').required('Required'),
});

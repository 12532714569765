import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectUser } from '../../../states/slices/authReducer';
import { Link, useNavigate } from 'react-router-dom';
import {
	getSelectedCase,
	removeValue,
	setReload,
	setSingleParty,
	setToggle,
	setToggle_2,
	updateCase,
} from '../../../states/slices/partyInfo.slice';
import Api from '../../../config';
import { getJSONheader } from '../../../utils';
import { toast } from 'react-hot-toast';
import { FaUser } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

export const CASE_JUDGE_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},
	{
		Header: 'Case number',
		accessor: 'case_number',
	},
	{
		Header: 'Suit no',
		accessor: 'suit_number',
	},
	{
		Header: 'Case Title',
		accessor: 'title',
	},
	{
		Header: 'Date created',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.values.created_at).format('MMM Do YYYY');
		},
	},
	{
		Header: 'Amount Paid (₦)',
		accessor: 'amount_paid',
	},

	{
		Header: 'Status',
		Cell: ({ cell: { row } }: any) => {
			return (
				<button
					className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
						row.original?.case_status?.status === 'PENDING'
							? 'bg-yellow-200'
							: row.original?.case_status?.status === 'IN COURT'
							? 'bg-[#F2994A]'
							: row.original?.case_status?.status === 'RECALLED'
							? 'bg-red-200'
							: 'bg-green-200'
					}`}
				>
					{row.original?.case_status?.status}
				</button>
			);
		},
	},

	{
		Header: 'Action',
		Cell: ({ cell: { row } }: any) => {
			const dispatch = useAppDispatch();
			const user = useAppSelector(selectUser);

			return (
				<>
					{row.original?.case_status?.status === 'RECALLED' ? (
						<Link to={`/judge/recall?id=${row.original?.id}`}>
							<button
								onClick={() => dispatch(updateCase(row.original))}
								className='px-1  cursor-pointer py-1 bg-yellow-500 text-[#fff] text-xs  w-20  rounded-md'
							>
								In Progress
							</button>
						</Link>
					) : (
						<Link
							to={
								user.role === 'REGISTRAR 2'
									? `/registrar-2/process?id=${row.original?.id}`
									: user.role === 'PRESIDING JUDGE'
									? `/presiding-judge/process?id=${row.original?.id}`
									: user.role === 'PRESIDING MAGISTRATE'
									? `/presiding-magistrate/process?id=${row.original?.id}`
									: user.role === 'JUDGE'
									? `/judge/process?id=${row.original?.id}`
									: user.role === 'MAGISTRATE'
									? `/magistrate/process?id=${row.original?.id}`
									: user.role === 'BAILIFF'
									? `/bailiff/process?id=${row.original?.id}`
									: `/registrar-1/process?id=${row.original?.id}`
							}
						>
							<button
								onClick={() => {
									// e.preventDefault();
									dispatch(updateCase(row.original));
								}}
								className='px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md'
							>
								Process
							</button>
						</Link>
					)}
				</>
			);
		},
	},
];

export const CASE_CAUSELIST_COLUMNS = [
	{
		Header: 'S/No.',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{Number(row.id) + 1}</span>;
		},
	},

	{
		Header: 'Case Title',
		// accessor: 'title',
		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row.original?.case_folder?.title}</span>;
		},
	},
	{
		Header: 'Suit Number',

		Cell: ({ cell: { row } }: any) => {
			return <span className='text-xs'>{row.original?.case_folder?.suit_number}</span>;
		},
	},
	{
		Header: 'Hearing Description',
		accessor: 'description',
	},
	{
		Header: 'Scheduled Date',
		accessor: 'created_at',
		Cell: ({ cell: { row } }: any) => {
			return moment(row.original.scheduled_at).format('MMM Do YYYY');
		},
	},
	{
		Header: 'Hearing Type',
		accessor: 'type',
	},
	{
		Header: 'Meeting Link',
		accessor: 'join_url',
	},
	{
		Header: 'Court',
		accessor: 'court',
	},

	{
		Header: 'Action',
		Cell: ({ cell: { row } }: any) => {
			return (
				<>
					{row.original?.type === 'physical' ? (
						<></>
					) : (
						<a
							target='_blank'
							href={row.original.join_url}
							className='px-4 py-1 bg-brand_indigo text-[#fff] text-xs  w-20  rounded-md'
							rel='noreferrer'
						>
							Join
						</a>
					)}
				</>
			);
		},
	},

	{
		Header: 'Status',
		Cell: ({ cell: { row } }: any) => {
			return (
				<button
					className={`px-2 py-1 text-gray-500 text-xs cursor-default  rounded-md capitalize ${
						row.original?.status === 'PENDING'
							? 'bg-yellow-200'
							: row.original?.status === 'IN COURT'
							? 'bg-[#F2994A]'
							: row.original?.status === 'RECALLED'
							? 'bg-red-200'
							: 'bg-green-200'
					}`}
				>
					{row.original?.status}
				</button>
			);
		},
	},
];

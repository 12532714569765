import React, { useRef } from 'react';
// import emailjs from 'emailjs-com';
import { MdMarkEmailRead } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';
import { useForm, ValidationError } from '@formspree/react';
import Typography from '../../../components/Typography';

function ContactForm() {
	const [state, handleSubmit] = useForm('xzbqypnw');
	if (state.succeeded) {
		return <p>Thanks for Contacting Us!</p>;
	}
	return (
		<section id='contact'>
			<div className='flex flex-col'>
				<Typography variant='h3' textStyle='mx-auto pb-10 mt-10 text-brand_indigo'>
					Contact Us
				</Typography>
				<div className='grid grid-cols-2 max-m_large:flex max-m_large:flex-col gap-4 md:gap-8 mx-[5%] my-[2%]'>
					<div className='flex flex-col gap-4'>
						<div className='bg-[#272556] rounded-lg p-4 flex flex-col'>
							<MdMarkEmailRead className='text-white text-3xl mb-4 mx-auto' />
							<div className='mx-auto text-center'>
								<h4 className='text-xl font-bold mb-1 text-white'>Email</h4>
								<h5 className='text-gray-400 text-xs md:text-lg'>info@familycourt.rv.gov.ng</h5>
								<a
									href='mailto:info@familycourt.rv.gov.ng'
									className='text-[#55B7E8]  hover:text-blue-600 text-sm font-bold mt-2'
								>
									Send a Message
								</a>
							</div>
						</div>
						<div className='bg-[#272556] rounded-lg p-4 flex flex-col'>
							<BsWhatsapp className='text-white text-3xl mb-4 mx-auto' />
							<div className='mx-auto text-center'>
								<h4 className='text-xl font-bold mb-1 text-white'>WhatsApp</h4>
								<h5 className='text-gray-400'>07041473082</h5>
								<a
									href='https://wa.me/+2347041473082'
									className='text-[#55B7E8]  hover:text-blue-600 text-sm font-bold mt-2'
								>
									Send a Message
								</a>
							</div>
						</div>
					</div>
					<form onSubmit={handleSubmit} className='flex flex-col gap-4'>
						<input
							type='text'
							name='name'
							placeholder='Your full name'
							required
							className='px-4 py-3 border-2 border-blue-500 rounded-lg bg-[#272556] text-white'
						/>
						<input
							id='email'
							type='email'
							name='email'
							placeholder='Your Email'
							required
							className='px-4 py-3 border-2 border-blue-500 rounded-lg bg-[#272556] text-white'
						/>
						<ValidationError field='email' prefix='Email' errors={state.errors} />
						<textarea
							name='message'
							rows={7}
							placeholder='Your Message'
							required
							className='px-4 py-3 border-2 border-blue-500 rounded-lg bg-[#272556] text-white resize-none'
						></textarea>
						<button
							type='submit'
							disabled={state.submitting}
							className='bg-[#55B7E8]  hover:bg-blue-600 text-white py-3 rounded-lg font-bold'
						>
							Send Message
						</button>
					</form>
				</div>
			</div>
		</section>
	);
}

export default ContactForm;

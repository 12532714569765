import React, { useState } from 'react';
import { FILE_UPLOAD, UPDATE, USER_ICON } from '../../../assets';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectRole, selectUser, setUser } from '../../../states/slices/authReducer';
import { useDropzone } from 'react-dropzone';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Api from '../../../config';
import { getFormDataHeader, getJSONheader } from '../../../utils';
import { toast } from 'react-hot-toast';
import PaginatedTable from '../../../components/table/Paginated-table';
import { ADMIN_CASE_COLUMNS } from '../../../components/table/columns/admin';
import Notification from '../notification';
import { getSelectedCase, updateCase } from '../../../states/slices/partyInfo.slice';
import ConfirmationModal from '../../../components/modals/Confirmation';

function ViewUsers() {
	// const user = useAppSelector(selectUser);
	const user = useAppSelector(getSelectedCase);
	const dispatch = useAppDispatch();
	const [tab, setTab] = useState('profile');
	const [isOpen, setIsOpen] = useState(false);
	const [files, setFiles] = useState<any>([]);
	const [certFiles, setCertFiles] = useState<any>([]);
	const [value, setValue] = useState<any>({
		first_name: user?.first_name ?? '',
		last_name: user?.last_name ?? '',
		phone_number: user?.phone_number ?? '',
		whatsapp_number: user?.whatsapp_number ?? '',
		email: user?.email ?? '',
		password: '',
	});
	const [show, setShow] = useState<boolean>(false);
	const [required, setRequired] = useState<string>('');
	const [data, setData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setLoading] = useState(false);

	const fetchData = async (page: any, pageSize: any) => {
		setLoading(true);
		try {
			const response = await Api.get(`/admin/case-folders?page=${page}`, getJSONheader());
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
			setTotalPages(meta.last_page);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		maxSize: 2000000,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		},
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});
	const { getRootProps: rootprops, getInputProps: inputProps } = useDropzone({
		maxFiles: 1,
		maxSize: 2000000,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		},
		onDrop: (acceptedFiles) => {
			setCertFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const profilePic =
		files?.length > 0 ? (
			<>
				{files.map((file: any, index: any) => (
					<img src={files[0].preview} key={index} className={'w-[200px] h-[200px] rounded-full'} />
				))}
			</>
		) : (
			<img src={user?.profile_photo} className={'w-[200px] h-[200px] rounded-full'} />
		);
	const certPic =
		certFiles?.length > 0 ? (
			<>
				{certFiles.map((file: any, index: any) => (
					<img
						src={certFiles[0].preview}
						key={index}
						className={'w-[150px] h-[150px] rounded-full'}
					/>
				))}
			</>
		) : (
			<img src={user?.call_to_bar_certificate} className={'w-[200px] h-[200px] rounded-full'} />
		);

	// console.log(user?.profile_photo);

	const handleUpdateProfile = (e: any) => {
		e.preventDefault();

		const data = {
			first_name: value?.first_name ?? user?.first_name,
			last_name: value?.last_night ?? user?.last_name,
			phone_number: value?.phone_number ?? user?.phone_number,
			whatsapp_number: value?.whatsapp_number ?? user?.whatsapp_number,
			email: value.email ?? user?.email,
			password: value.password,
			profile_photo: files[0],
			call_to_bar_certificate: certFiles[0],
		};

		if (!value.password) {
			setRequired('required');
		} else {
			Api.post('/profile/update', data, getFormDataHeader())
				.then((res) => {
					toast.success(res?.data?.message, {
						duration: 3000,
					});
					// console.log(res?.data?.data);
					dispatch(setUser(res?.data?.data));
				})
				.catch((error) => {
					// console.log(error?.response?.data);
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
				});
		}
	};

	// console.log(user, 'user');

	const handleApproveUser = (e: any) => {
		e.preventDefault();
		setLoading(true);

		Api.post(`admin/users/${user?.id}/approve`, data, getFormDataHeader())
			.then((res) => {
				toast.success(res?.data?.message, {
					duration: 3000,
				});

				dispatch(setUser(res?.data?.data));
				setIsOpen(false);
				setLoading(false);
			})
			.catch((error) => {
				// console.log(error?.response?.data);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
				setIsOpen(false);
				setLoading(false);
			});
	};

	return (
		<div className='pb-16 lg:pb-0 '>
			<div className='bg-[#F8F8F9] text-left  py-3 flex items-center pl-[3%]'>
				<h3 className=''>{`Manage Users / ${user?.first_name} ${user?.last_name}`}</h3>
			</div>
			<div className='mt-4 flex justify-end lg:px-8'>
				<button
					onClick={() => setIsOpen(true)}
					className='bg-[#272556] rounded text-[#fff] py-[2%] md:py-2 w-44 flex justify-center items-center text-xs'
				>
					Approve
				</button>
			</div>
			<div className='flex flex-col md:flex-row justify-center items-center mt-8 lg:px-[5%] '>
				<div className=' lg:mr-8 mb-4 lg:mb-0 bg-[#13BAED0D] w-full lg:w-[50%] flex flex-col items-center lg:h-[250px] py-4 rounded-lg'>
					<div className=' w-full px-6 py-3'>
						<div
							{...getRootProps()}
							className={`flex justify-center items-center flex-col rounded-full mb-8`}
						>
							{files.length > 0 || user?.profile_photo ? (
								profilePic
							) : (
								<>
									<img src={USER_ICON} alt='' className='h-[150px] w-[150px]' />
									<p className='flex justify-center items-center'>
										Upload <img src={FILE_UPLOAD} alt='' className='h-5 ml-2' />
									</p>
								</>
							)}

							<input {...getInputProps()} />
						</div>
					</div>
				</div>
				<div className='bg-[#fff] rounded-lg shadow shadow-indigo-500/40 w-full md:w-[55%] px-8 py-4  lg:pt-4  lg:h-[250px]'>
					<div className='flex flex-col text-left'>
						<label className='mb-[2%]'>First Name</label>
						<input
							type='text'
							className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'
							defaultValue={value.first_name}
							onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
								setValue({
									...value,
									first_name: text.target.value,
								});
							}}
							placeholder='your first name'
						/>
					</div>
					<div className='flex flex-col text-left mt-[2%]'>
						<label className='mb-[2%]'>Last Name</label>
						<input
							type='text'
							placeholder='your last name'
							className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'
							defaultValue={value?.last_name}
							onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
								setValue({
									...value,
									last_name: text.target.value,
								});
							}}
						/>
					</div>
				</div>
			</div>

			<div className='px-12 pt-6'>
				<a
					type='button'
					className={`cursor-pointer ${tab === 'profile' ? 'text-brand_blue' : 'text-gray-400'}`}
					onClick={() => setTab('profile')}
				>
					Profile
				</a>
				<a
					type='button'
					className={`px-8 cursor-pointer ${tab === 'cases' ? 'text-brand_blue' : 'text-gray-400'}`}
					onClick={() => setTab('cases')}
				>
					Cases
				</a>
				<a
					type='button'
					className={`cursor-pointer ${
						tab === 'notifications' ? 'text-brand_blue' : 'text-gray-400'
					}`}
					onClick={() => setTab('notifications')}
				>
					Notifications
				</a>
			</div>

			{tab === 'profile' && (
				<>
					<div className='bg-[#fff] mt-3 rounded-lg shadow shadow-indigo-500/40 w-full md:w-[90%] p-[5%] pb-28 lg:pb-12 lg:mx-auto grid grid-cols-1 md:grid-cols-2 items-center gap-[10%]'>
						<div className='flex flex-col text-left'>
							<label className='mb-[2%]'>Phone Number</label>
							<input
								type='text'
								className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'
								defaultValue={value?.phone_number}
								onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
									setValue({
										...value,
										phone_number: text.target.value,
									});
								}}
							/>
						</div>
						<div className='flex flex-col text-left '>
							<label className='mb-[2%]'>Whatsapp Number</label>
							<input
								type='text'
								className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'
								defaultValue={value?.whatsapp_number}
								onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
									setValue({
										...value,
										whatsapp_number: text.target.value,
									});
								}}
							/>
						</div>
						<div className='flex flex-col text-left'>
							<label className='mb-[2%]'>Email Address</label>
							<input
								type='text'
								className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1'
								defaultValue={value?.email}
								autoFocus={true}
								onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
									setValue({
										...value,
										email: text.target.value,
									});
								}}
							/>
						</div>
						<div className='flex flex-col text-left '>
							<label className='mb-[2%]'>Enrollment Number</label>
							<p className='text-gray-500 text-sm'>{user?.enrollment_number}</p>
						</div>
					</div>
					{/* call_to_bar_certificate */}
					<div className='bg-[#fff] rounded-lg shadow shadow-indigo-500/40 w-full md:w-[90%] p-[5%] lg:ml-[5%] my-8 grid  items-center '>
						<label className='mb-2'>Call to Bar Certificate</label>
						<div className=' w-full px-6 '>
							<div
								{...rootprops()}
								className={`flex justify-center items-center flex-col rounded-full `}
							>
								{certFiles.length > 0 || user?.call_to_bar_certificate ? (
									certPic
								) : (
									<>
										<img src={USER_ICON} alt='' className='h-[150px] w-[150px]' />
										<p className='flex justify-center items-center'>
											Upload <img src={FILE_UPLOAD} alt='' className='h-5 ml-2' />
										</p>
									</>
								)}

								<input {...inputProps()} />
							</div>
						</div>
					</div>

					<div className=''>
						<h4 className='text-[1.0rem] text-left lg:ml-[5%] my-4 '>
							<span className='font-bold'>Password </span>
							<small className='italic text-gray-400'>
								{' '}
								(New password or continue with your existing password)
							</small>
						</h4>
						<div className='bg-[#fff] rounded-lg shadow shadow-indigo-500/40 w-full md:w-[90%] p-[5%] lg:ml-[5%] grid grid-cols-1 md:grid-cols-2 items-center gap-[10%]'>
							<div className='flex flex-col text-left'>
								<label className='mb-[2%] '>Password</label>
								<div className='rounded-md flex justify-between items-center relative'>
									<input
										className='rounded-md outline-none bg-[#13BAED0D] text-sm py-2 px-1 w-full'
										type={show === true ? 'text' : 'password'}
										value={value.password}
										onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
											setValue({
												...value,
												password: text.target.value,
											});
										}}
									/>
									{show === true ? (
										<FaEye
											className='absolute top-2 right-2 cursor-pointer text-gray-700 text-2xl'
											onClick={() => setShow(!show)}
										/>
									) : (
										<FaEyeSlash
											className='absolute top-2 right-2 cursor-pointer text-gray-700 text-2xl'
											onClick={() => setShow(!show)}
										/>
									)}
								</div>
								{required && <small className='text-red-500'>{`password is ${required}`}</small>}
							</div>
						</div>
					</div>
					<div className='mt-2'>
						<div className='bg-[#fff] rounded-lg shadow shadow-indigo-500/40 w-full md:w-[90%] p-[5%] lg:ml-[5%] grid grid-cols-1 md:grid-cols-2 items-center gap-[10%]'>
							<div className='flex flex-col text-left'>
								<div className='flex flex-col text-left '>
									<label className='mb-[4%]'>User Role</label>
									<p className='text-gray-500 text-sm capitalize'>{user?.role}</p>
								</div>
							</div>
						</div>
					</div>
					<div className='flex justify-center px-[5%] my-[3%] md:flex-row flex-col items-center'>
						{/* <button
							onClick={(e: any) => {}}
							className='bg-red-700 rounded-lg text-[#fff] py-[2%] md:py-2 w-44 flex justify-center items-center text-xs'
						>
							Delete user
						</button> */}
						<button
							onClick={(e: any) => handleUpdateProfile(e)}
							className='bg-[#272556] rounded-lg text-[#fff] py-[2%] md:py-2 w-44 flex justify-center items-center text-xs'
						>
							Update Profile <img src={UPDATE} alt='' className='ml-2 h-4 w-4' />
						</button>
					</div>
				</>
			)}

			{tab === 'cases' && (
				<div className='mt-8 lg:flex justify-between items-end'>
					<div className=' w-full lg:mb-0'>
						<div className=' border-b  border-[#F0F0F0] rounded-b-xl'>
							<PaginatedTable
								columns={ADMIN_CASE_COLUMNS}
								data={data}
								totalPages={totalPages}
								fetchData={fetchData}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			)}
			{tab === 'notifications' && <Notification userID={user?.id} />}
			<ConfirmationModal
				handleClick={(e: any) => handleApproveUser(e)}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				isLoading={isLoading}
				title={
					'You are about to approve this user. click cancel to cancel this action or proceed with continue'
				}
			/>
		</div>
	);
}

export default ViewUsers;

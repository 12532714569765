import {
	Registrar_1_ConcludedCases,
	Registrar_1_Dashboard,
	Registrar_1_MyFiles,
	Registrar_1_Notification,
	Registrar_1_Profile,
} from '../features/registrar-1';
import Payment from '../features/registrar-1/payment';
import ProcessPayment from '../features/registrar-1/proceed-payment';

export const registrarRoutes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Registrar_1_Dashboard,
		path: 'registrar-1',
	},
	{
		id: '2',
		title: 'Case Folders',
		component: Registrar_1_MyFiles,
		path: 'registrar-1/case-folders',
	},
	{
		id: '3',
		title: 'Notification',
		component: Registrar_1_Notification,
		path: 'registrar-1/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Registrar_1_Profile,
		path: 'registrar-1/profile',
	},

	{
		id: '6',
		title: 'Concluded Cases',
		component: Registrar_1_ConcludedCases,
		path: 'registrar-1/concluded-cases',
	},

	{
		id: '7',
		title: 'process',
		component: ProcessPayment,
		path: 'registrar-1/process',
	},
	{
		id: '8',
		title: 'Payments',
		component: Payment,
		path: 'registrar-1/payments',
	},
];

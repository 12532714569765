import React from 'react';
import axios from 'axios';
import formsToDownloadData from '../../data/forms';
import NavBar from '../home/NavigationBar';
import Footer from '../home/Footer';

type Props = {};

function downloadPDF(name: any) {
	const filepath = `${name}`;

	axios.get(filepath, { responseType: 'blob' }).then((response) => {
		// Create a link element and set its attribute
		const link = document.createElement('a');
		link.href = URL.createObjectURL(new Blob([response.data]));

		const regex = /[A-Z0-9]+/g;
		const match = name.match(regex);
		let r = match.shift();
		let s = match.shift();
		if (!isNaN(match[match.length - 1])) {
			let t = match.pop();
		}
		let filename = match.join(' ');
		link.download = `${filename}.pdf`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	});
}

function formatFileName(url: any) {
	const regex = /[A-Z0-9]+/g;
	const match = url.match(regex);
	let r = match.shift();
	let s = match.shift();
	if (!isNaN(match[match.length - 1])) {
		let t = match.pop();
	}
	let filename = match.join(' ');
	return filename;
}

const DownloadForm = (props: Props) => {
	return (
		<>
			<div className='sticky top-0  z-50 '>
				<NavBar />
			</div>
			<div className='overflow-x-auto'>
				<table className='md:w-[100%] table-auto border-collapse border border-gray-400'>
					<thead>
						<tr className='bg-gray-200 text-gray-700 uppercase text-sm leading-normal'>
							<th className='py-3 px-6 text-left'>Document Name</th>
							<th className='py-3 px-6 text-left'>Download</th>
						</tr>
					</thead>
					<tbody className='text-gray-600 text-sm font-light'>
						{formsToDownloadData.map((document: any) => (
							<tr key={document.id} className='border-b border-gray hover:bg-gray-100'>
								<td className='py-2 px-6 text-left'>{document.name}</td>
								<td className='py-2 px-12 text-left'>
									{/* <button onClick={() => downloadPDF(document.path)}> */}
									<a href={document.path} target='_black'>
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											viewBox='0 0 24 24'
										>
											<path d='M12 15.5l5.5-5.5h-4v-7h-3v7h-4l5.5 5.5z' />
											<path d='M0 0h24v24H0z' fill='none' />
										</svg>
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			<Footer />
		</>
	);
};

export default DownloadForm;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import background from "../../assets/icons/BackgroundImage.png";
import { Form, Formik } from "formik";
import Api from "../../config";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../hooks";
import {
  selectUser,
  setRefreshToken,
  setToken,
} from "../../states/slices/authReducer";
import Input from "../../components/form/Input";
import Button from "../../components/button";
import { LOGO } from "../../assets";
import { useDispatch } from "react-redux";

export const VerifyOtp = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const email = useLocation().state;

  const Resend = () => {
    setloading(true);
    const formData = {
      email: email ? email : user?.email,
      type: email ? "PASSWORD RESET" : "REGISTER",
    };
    Api.post("/auth/resend-token", formData)
      .then(({ data }) => {
        // Redirect or perform any other action upon successful login
        toast.success(data.message, {
          duration: 3000, // Duration for which the toast is displayed (in milliseconds)
        });
        setloading(false);
      })
      .catch((error: any) => {
        // console.log(error.response.data.message);
        toast.error(error?.response?.data?.message, {
          duration: 3000,
        });
        setloading(false);
      });
  };

  return (
    <div className='w-screen  h-screen relative'>
      <div className='flex justify-between pt-12 md:pl-12 md:-mb-24'>
        <Link to='/'>
          <img
            src={LOGO}
            className='h-full w-full rounded-full'
            alt='...logo'
          />
        </Link>
      </div>
      <div className='flex w-full h-full justify-center items-center'>
        <img
          src={background}
          alt='background'
          className='absolute z-[0] ml-[-8%] h-1/2 w-[100%] top-[25%] '
        />
        <div className='bg-[#fff]  shadow-lg shadow-indigo-500/40 relative z-[2] '></div>
        <div className='bg-[#fff] w-4/5 lg:w-2/3 shadow-lg shadow-indigo-500/40 relative z-[2] h-[60vh] p-2 lg:p-8 flex flex-col justify-center '>
          <h3 className='text-[#262553] font-bold text-[1.7rem]'>
            Please enter the One-Time Password to verify your account
          </h3>
          <p>
            One-Time password has been sent to{" "}
            <span className='text-brand_blue'>{user?.email}</span>{" "}
            <address></address>
          </p>
          <Formik
            initialValues={{
              token: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setIsLoading(true);
              const formdata = {
                token: values.token,
                type: email ? "PASSWORD RESET" : "REGISTER",
              };

              Api.post("/auth/verify-token", formdata)
                .then(({ data }) => {
                  // Redirect or perform any other action upon successful login
                  toast.success(data.message, {
                    duration: 3000, // Duration for which the toast is displayed (in milliseconds)
                  });
                  setIsLoading(false);
                  resetForm();
                  dispatch(setRefreshToken(data.data.token));
                  // console.log('data', data);

                  if (email) {
                    navigate("/password-reset");
                  } else navigate("/login");
                })
                .catch((error: any) => {
                  // console.log(error.response.data.message);
                  toast.error(error?.response?.data?.message, {
                    duration: 3000,
                  });
                  setIsLoading(false);
                });
            }}
          >
            {({
              handleSubmit,
              isSubmitting,

              /* and other goodies */
            }) => (
              <Form>
                <div className='w-full lg:w-1/2'>
                  <Input
                    type='text'
                    placeholder='enter your token'
                    name={"token"}
                  />
                </div>
                <Button
                  isLoading={isLoading}
                  onClick={handleSubmit}
                  title={"Verify Mail"}
                  bgColor={"bg-brand_indigo"}
                  textColor={"text-white"}
                  className={" my-2  lg:my-0  py-4 w-full lg:w-44 rounded-2xl"}
                />
              </Form>
            )}
          </Formik>
          <a onClick={Resend} className='mt-4 cursor-pointer'>
            {loading ? (
              <span>processing</span>
            ) : (
              <span className='text-brand_blue font-semibold'>
                Resend One-Time Password
              </span>
            )}
          </a>
        </div>
      </div>
    </div>
  );
};

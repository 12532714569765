import React from 'react';
import { useDropzone } from 'react-dropzone';
import { useAppSelector } from '../hooks';
import { selectUser } from '../states/slices/authReducer';
import { FILE_UPLOAD, USER_ICON } from '../assets';

function StampAndSeal({ stamp, setStamp }: any) {
	const user = useAppSelector(selectUser);
	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		maxSize: 2000000,
		accept: {
			'image/png': ['.png'],
			'image/jpeg': ['.jpg', '.jpeg'],
		},
		onDrop: (acceptedFiles) => {
			setStamp(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	const profilePic =
		stamp?.length > 0 ? (
			<>
				{stamp.map((file: any, index: any) => (
					<img src={stamp[0].preview} key={index} className={'w-[200px] h-[200px] rounded-full'} />
				))}
			</>
		) : (
			<img src={user?.stamp_photo} className={'w-[200px] h-[200px] rounded-full'} />
		);

	return (
		<div className=' w-full px-6 py-3'>
			<div
				{...getRootProps()}
				className={`flex justify-center items-center flex-col rounded-full mb-8`}
			>
				{stamp.length > 0 || user?.stamp_photo ? (
					profilePic
				) : (
					<>
						<img src={USER_ICON} alt='' className='h-[150px] w-[150px]' />
						<p className='flex justify-center items-center'>
							stamp <img src={FILE_UPLOAD} alt='' className='h-5 ml-2' />
						</p>
					</>
				)}

				<input {...getInputProps()} />
			</div>
		</div>
	);
}

export default StampAndSeal;

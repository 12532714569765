import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import background from '../../assets/icons/BackgroundImage.png';
import Button from '../../components/button';
import { LOGO } from '../../assets';

export const NonVerified = () => {
	const navigate = useNavigate();

	return (
		<div className='w-screen  h-screen relative'>
			<div className='flex justify-between pt-12 md:pl-12 md:-mb-24'>
				<Link to='/'>
					<img src={LOGO} className='h-full w-full rounded-full' alt='...logo' />
				</Link>
			</div>
			<div className='flex w-full h-full justify-center items-center'>
				<img
					src={background}
					alt='background'
					className='absolute z-[0] ml-[-8%] h-1/2 w-[100%] top-[25%] '
				/>
				<div className='bg-[#fff]  shadow-lg shadow-indigo-500/40 relative z-[2] '></div>
				<div className='bg-[#fff] w-2/3 shadow-lg shadow-indigo-500/40 relative z-[2] h-[60vh] p-8 flex flex-col justify-center '>
					<h3 className='text-[#262553] font-bold text-[1.7rem] text-center'>
						Your account is awaiting approval and you will be notified via email once this process
						is completed.
					</h3>

					<div className='w-full flex justify-center items-center mt-6'>
						<Button
							onClick={navigate('/')}
							title={'Go to Home page'}
							bgColor={'bg-brand_indigo'}
							textColor={'text-white'}
							className={' mb-2 lg:mb-0 w-full lg:w-40 py-4 rounded-2xl'}
						/>
						<Button
							onClick={navigate('/login')}
							title={'Go to Login'}
							bgColor={'bg-brand_blue'}
							textColor={'text-white'}
							className={' mb-2 lg:mb-0 w-full lg:w-40 py-4 rounded-2xl ml-6'}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

import React, { useRef } from 'react';
// import emailjs from 'emailjs-com';
import { MdMarkEmailRead } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';
import { useForm, ValidationError } from '@formspree/react';
import IMAGE_HELP from '../../../assets/icons/N.png';

function Help() {
	const [state, handleSubmit] = useForm('xzbqypnw');
	if (state.succeeded) {
		return <p>Thanks for Contacting Us!</p>;
	}
	return (
		<section className='w-full lg:flex justify-start items-center px-3 lg:px-16 py-8 lg:py-0'>
			<div className='w-[70%]'>
				<img src={IMAGE_HELP} className='w-full' />
			</div>
			<div className='w-full lg:w-[30%]'>
				<h1 className='text-5xl lg:text-9xl text-brand_indigo text-center font-bold'>Call</h1>
				<h1 className='text-3xl lg:text-6xl text-brand_indigo text-center'>07041473082</h1>
			</div>
		</section>
	);
}

export default Help;

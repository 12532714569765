import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	getSelectedCase,
	getSingleParty,
	setToggle,
	setToggle_2,
	toggleState,
	toggleState_2,
} from '../../../states/slices/partyInfo.slice';
import { TableComponent } from '../../../components/table/Table';
import {
	CASE_COLUMNS,
	PARTY_COLUMNS,
	PARTY_COLUMNS_view,
	SINGLE_CASE_COLUMNS,
} from '../../../components/table/columns';
import Typography from '../../../components/Typography';
import { PDF } from '../../../assets';
import { formatCurrency } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import { FormInput } from '../../../components/form/Input';
import Modal from '../../../components/UI/modal';
import { Zoom } from 'react-reveal';
import PartyInformation from '../../../components/modals/Partinformation';
import CaseInformation from '../../../components/modals/CaseInformation';

function ViewCases() {
	const getCase = useAppSelector(getSelectedCase);

	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const navigate = useNavigate();
	const toggleStatus = useAppSelector(toggleState);
	const toggleStatus_2 = useAppSelector(toggleState_2);
	const singlePartyInfo = useAppSelector(getSingleParty);
	const dispatch = useAppDispatch();

	// console.log(getCase);

	return (
		<>
			<div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
				<Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
			</div>
			<div className='pt-4 pb-8 px-8'>
				<Typography textStyle='text-[20px] font-medium'>Case Folder Information</Typography>
				<div className='my-2'>
					{getCase ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={[getCase]}
							COLUMNS={SINGLE_CASE_COLUMNS}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={() => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Case Folder Information
						</Typography>
					)}
				</div>
				<Typography textStyle='text-[20px] font-medium'>Party Information</Typography>
				<div className='my-2'>
					{getCase?.parties?.length > 0 ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getCase?.parties}
							COLUMNS={PARTY_COLUMNS_view}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={(value: number) => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Party Information
						</Typography>
					)}
				</div>

				<Typography textStyle='text-[20px] font-medium'>Uploaded Documents</Typography>
				<div className='my-2 lg:flex justify-between items-center flex-wrap '>
					{getCase?.case_documents?.length > 0 ? (
						getCase?.case_documents.map((item: any, index: number) => (
							<>
								<div
									key={index}
									className='flex justify-between items-center mb-3 border border-gray-200 w-full lg:w-[48%] p-3  rounded-lg'
								>
									<div className='flex justify-start items-center '>
										<img src={PDF} className='h-6 w-6' />
										<div className='ml-4'>
											<p className='text-center text-black font-bold text-sm'>Document Title</p>
											<p className='text-left text-gray-400 text-xs mt-1'>{item?.title}</p>
										</div>
									</div>

									<p className='text-center text-green-600 text-xl ml-4 p-2 border border-gray-300 w-24 rounded-lg'>
										{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(item?.price)}
									</p>
								</div>
							</>
						))
					) : (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Uploaded Documents
						</Typography>
					)}
				</div>
				<div
					className={`${
						getCase?.case_status?.remark ? 'justify-between' : 'justify-end'
					} flex  items-end`}
				>
					{getCase?.case_status?.remark && (
						<div className='w-full lg:w-[40%]'>
							<Typography textStyle='text-[20px] font-medium'>Remarks</Typography>
							<div className='my-2 flex justify-start items-center '>
								<p className='bg-[#D9D9D91A] overflow-auto min-h-[100px] w-full p-4 text-sm text-gray-600 border border-gray-200'>
									{getCase?.case_status?.remark}
								</p>
							</div>
						</div>
					)}
				</div>
				<div className=' flex justify-between items-end mt-10'>
					<Button
						iconClassName='h-[0.9rem] w-3'
						title='Back'
						bgColor={'bg-brand_indigo'}
						onClick={() => navigate('/lawyer/my-files')}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-3 w-44'}
					/>
				</div>
			</div>

			<CaseInformation
				open={toggleStatus_2}
				close={() => dispatch(setToggle_2(false))}
				data={getCase}
			/>
			<PartyInformation
				open={toggleStatus}
				close={() => dispatch(setToggle(false))}
				data={singlePartyInfo}
			/>
		</>
	);
}

export default ViewCases;

import { Store } from '../states/store';

export const getJSONheader = () => {
	// const token = localStorage.getItem('token');
	const token = Store.getState().auths.token;
	return {
		headers: {
			// 'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
	};
};

export const getFormDataHeader = () => {
	const token = Store.getState().auths.token;
	return {
		headers: {
			'Content-Type': 'multipart/form-data',
			Authorization: `Bearer ${token}`,
		},
	};
};

interface currencyTypes {
	iso: string;
	slug: 'NGN' | 'USD';
}

export const formatCurrency = (currencyType: currencyTypes) => {
	return new Intl.NumberFormat(currencyType.iso, {
		style: 'currency',
		currency: currencyType.slug,
	});
};

export function sumPrices(data: any) {
	let totalPrice = 0;

	for (const item of data) {
		totalPrice += item.price;
	}

	return totalPrice;
}

import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Api from '../../config';
import { toast } from 'react-hot-toast';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectUser } from '../../states/slices/authReducer';
import Input from '../../components/form/Input';
import Button from '../../components/button';
import { Formik } from 'formik';
import { LOGO, login_bg } from '../../assets';
import { forgotPassword } from '../../services/auth.service';
import { Fade } from 'react-reveal';
export const ForgotPassword = () => {
	const [loading, setloading] = useState<boolean>(false);

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	return (
		<div className='bg-white  font-family-karla h-screen'>
			<div className='w-full flex flex-wrap'>
				<div className='w-full md:w-1/2 flex flex-col'>
					<div className='flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24'>
						<Link to='/'>
							<img src={LOGO} className='h-full w-full rounded-full cursor-pointer' alt='...logo' />
						</Link>
					</div>
					<div className='flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32'>
						<p className='text-center text-xl font-bold'>Forgot password</p>

						<p className='flex text-[#00000080] text-[1.0rem] text-left  w-full mt-6'>
							Input the email you used in registering your account, an otp would be sent to your
							email address
						</p>
						<Fade left>
							<Formik
								initialValues={{ email: '' }}
								// validationSchema={loginSchema}
								onSubmit={async (values, { setSubmitting, resetForm }) => {
									try {
										const response = await forgotPassword({ email: values.email });

										if (response) {
											console.log(response);

											// resetForm();

											// console.log('data', data);

											navigate('/email-Verification', { state: values.email });
										}
									} catch (error) {}
								}}
							>
								{({
									handleSubmit,
									isSubmitting,
									/* and other goodies */
								}) => (
									<form className='flex flex-col pt-3 md:pt-8' onSubmit={handleSubmit}>
										<div className='flex flex-col pt-4'>
											<label htmlFor='email' className='text-sm'>
												Email
											</label>
											<Input type='email' placeholder='your@email.com' name={'email'} />
										</div>

										<Button
											isLoading={isSubmitting}
											title={'Submit'}
											bgColor={'bg-brand_indigo'}
											textColor={'text-white'}
											className={' mb-2 lg:mb-0 w-full py-4 rounded-2xl'}
										/>
									</form>
								)}
							</Formik>
						</Fade>
						<div className='text-center pt-12 pb-12'>
							<p className='text-sm'>
								Don't have an account?{' '}
								<Link to='/signup' className='text-brand_blue font-semibold'>
									Create an account
								</Link>
							</p>
						</div>
					</div>
				</div>

				<div className='w-1/2 shadow-2xl'>
					<img className='object-cover w-full h-screen hidden md:block' src={login_bg} />
				</div>
			</div>
		</div>
	);
};

{
	/* <>
<div className="w-[100%] flex flex-col justify-start">
  <label htmlFor="name" className="text-left w-[100%]">
	New Password {' '}<span className="text-red-500">*</span>
  </label>{" "}
  <br />
  <input
	type="password"
	name="password"
	id="name"
	onChange={handleChange}
	required
  />
</div>
<div className="flex justify-end w-[100%]">
  <button
	className="bg-[#272556] text-[#fff] w-[50%] rounded-lg h-[5vh] mt-[5%]"
	onClick={handleSubmit}
  >
	Submit
  </button>
</div>
</> */
}

import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import {
  getSelectedCase,
  getSingleParty,
  setToggle,
  toggleState,
} from "../../../states/slices/partyInfo.slice";
import { TableComponent } from "../../../components/table/Table";
import { PARTY_COLUMNS_view } from "../../../components/table/columns";
import Typography from "../../../components/Typography";
import { FLAG, PDF, UPLOAD } from "../../../assets";
import { formatCurrency, getFormDataHeader } from "../../../utils";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button";
import Api from "../../../config";
import { useDropzone } from "react-dropzone";
import Modal from "../../../components/UI/modal";
import { Fade, Zoom } from "react-reveal";
import { FormInput } from "../../../components/form/Input";
import { FaRegTrashAlt } from "react-icons/fa";
import { useGetPaymentsQuery } from "../../../services/apis/cases";
import { toast } from "react-hot-toast";
import PartyInformation from "../../../components/modals/Partinformation";

function ProceedPayment() {
  const getCase = useAppSelector(getSelectedCase);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const navigate = useNavigate();
  const [file, setFile] = useState<any>([]);
  const [title, setTitle] = useState<any>("");
  const [paymentID, setPaymentID] = useState<any>();
  const [required, setRequired] = useState("");
  const [requiredPhoto, setRequiredPhoto] = useState("");
  const toggleStatus = useAppSelector(toggleState);
  const singlePartyInfo = useAppSelector(getSingleParty);
  const dispatch = useAppDispatch();
  const [loading, setIsLoading] = useState<boolean>(false);

  const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
    selectFromResult: ({ data, isLoading, isError }) => ({
      paymentData: data?.data,
      isLoadingCases: isLoading,
    }),
  });

  useEffect(() => {
    handlePaymentData(paymentData);
  }, [paymentData]);

  useEffect(() => {
    refetch();
  }, [page]);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 50000000,
    accept: {
      "image/*": [".png, .jpeg, .jpg, .svg"],
    },
    onDrop: (acceptedFiles) => {
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setRequiredPhoto("");
    },
  });

  const productFiles = file.map((item: any, index: number) => (
    <div className='lg:w-[30vw] flex justify-between items-center'>
      <div className='flex justify-start items-center '>
        <img src={PDF} className='h-6 w-6 ' />
        <p className='text-center text-green-600 text-sm ml-4' key={index}>
          {item.name}
        </p>
      </div>
      <div className='flex justify-end'>
        <FaRegTrashAlt
          size={"0.7em"}
          onClick={() => removeAll()}
          className=' text-red-500'
        />
      </div>
    </div>
  ));

  const removeAll = () => {
    setFile([]);
  };

  const handlePaymentData = (paymentData: any[]) => {
    paymentData?.map((item: any) => {
      if (item?.service?.id === getCase?.id) {
        console.log(item, "ddd");

        setPaymentID(item);
        return item;
      }
    });
  };

  const handlePayment = async () => {
    const data = { title: title, file_path: file[0] };
    if (title && file.length > 0) {
      setIsLoading(true);
      try {
        const res: any = Api.post(
          `/lawyer/payments/${paymentID?.id}/upload-payment-proof`,
          data,
          getFormDataHeader()
        );

        // console.log(res.data);

        toast.success(`proof of payment upload is succefful`, {
          duration: 3000,
        });

        setTitle("");
        setFile([]);
        setOpen(false);
        setIsLoading(false);
        navigate("/non-lawyer/my-files");
      } catch (error: any) {
        setIsLoading(false);
        // console.log(error?.response);
        toast.error(error?.response?.data?.message, {
          duration: 3000,
        });
      }
    } else {
      setIsLoading(false);
      setRequired("Kindly fill the title");
      setRequiredPhoto("Kindly upload an image");
    }
  };

  // console.log(paymentID, 'payid');
  console.log(getCase, "pcase");
  // console.log(paymentData, 'pcase');

  return (
    <>
      <div className='bg-[#F8F8F9E5]/90  w-full py-2 px-2 lg:px-8'>
        <Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
      </div>
      <div className='pt-4 pb-8 px-2 lg:px-8'>
        <Typography textStyle='text-[20px] font-medium'>
          Party Information
        </Typography>
        <div className='my-2'>
          {getCase?.parties?.length > 0 ? (
            <TableComponent
              className='rounded-none rounded-t-0'
              DATA={getCase?.parties}
              COLUMNS={PARTY_COLUMNS_view}
              TITLE=''
              isExport={null}
              isPaginated={null}
              isSearch={null}
              size={size}
              setSize={setSize}
              setPageIndex={(value: number) => page + 1}
            />
          ) : (
            <Typography variant='label' textStyle='font-medium text-center'>
              No Party Information
            </Typography>
          )}
        </div>

        <Typography textStyle='text-[20px] font-medium'>
          Uploaded Documents
        </Typography>
        <div className='my-2 lg:flex justify-between items-center flex-wrap '>
          {getCase?.case_documents?.length > 0 ? (
            getCase?.case_documents.map((item: any, index: number) => (
              <>
                <div
                  key={index}
                  className='flex justify-between items-center mb-3 border border-gray-200 w-full lg:w-[48%] p-3  rounded-lg'
                >
                  <div className='flex justify-start items-center '>
                    <a href={item?.file_path} target='_blank'>
                      <img src={PDF} className='h-6 w-6' />
                    </a>
                    <div className='ml-4'>
                      <p className='text-center text-black font-bold text-sm'>
                        Document Title
                      </p>
                      <p className='text-left text-gray-400 text-xs mt-1'>
                        {item?.title}
                      </p>
                    </div>
                  </div>

                  <div className=' ml-4 p-2 border'>
                    <p className='text-center text-green-600 text-xl  border-gray-300 rounded-lg'>
                      {formatCurrency({ iso: "en-ng", slug: "NGN" }).format(
                        item?.price
                      )}
                    </p>
                  </div>
                </div>
              </>
            ))
          ) : (
            <Typography variant='label' textStyle='font-medium text-center'>
              No Uploaded Documents
            </Typography>
          )}
        </div>
        <div
          className={`${
            getCase?.case_status?.remark ? "justify-between" : "justify-end"
          } flex  items-end`}
        >
          {getCase?.case_status?.remark && (
            <div className='w-full lg:w-[40%]'>
              <Typography textStyle='text-[20px] font-medium'>
                Remarks
              </Typography>
              <div className='my-2 flex justify-start items-center '>
                <p className='bg-[#D9D9D91A] overflow-auto min-h-[100px] w-full p-4 text-sm text-gray-600 border border-gray-200'>
                  {getCase?.case_status?.remark}
                </p>
              </div>
            </div>
          )}
          <div className='ml-4 w-[30%]'>
            <Typography textStyle='text-[20px] font-medium text-center'>
              Total
            </Typography>
            <p className='text-center text-green-600 bg-[#D9D9D91A] text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
              {formatCurrency({ iso: "en-ng", slug: "NGN" }).format(
                paymentID?.amount ? paymentID?.amount : 0
              )}
            </p>
          </div>
        </div>
        <div className=' flex justify-between items-end mt-10'>
          <div className='w-[45%] md:w-44'>
            <Button
              iconClassName='h-[0.9rem] w-3'
              title='Back'
              bgColor={"bg-brand_indigo"}
              onClick={() => navigate("/lawyer/my-files")}
              hoverColor={""}
              textColor={"text-white"}
              className={"py-3 w-full"}
            />
          </div>

          {getCase?.require_payment && getCase?.payment_status !== "success" ? (
            <div className='w-[53%] md:w-44'>
              <Button
                iconClassName='h-[0.9rem] w-3'
                title='Upload proof of payment'
                bgColor={"bg-[#27AE60]"}
                onClick={() => setOpen(true)}
                hoverColor={""}
                textColor={"text-white"}
                className={"py-3 w-full"}
              />
            </div>
          ) : getCase?.require_payment &&
            getCase?.payment_status === "pending" ? (
            <p>Your payment have been made</p>
          ) : null}
        </div>
        <Modal
          open={open}
          close={() => {
            setOpen(false);

            setFile([]);
          }}
          maxWidth='md'
        >
          <>
            <div className='px-8 pb-6'>
              <Fade bottom>
                <>
                  <div className=''>
                    <div className='flex flex-col   w-full lg:w-[48%]'>
                      <label htmlFor='email' className='text-sm'>
                        Title <small className='text-red-500'>*</small>
                      </label>
                      <FormInput
                        type='text'
                        placeholder='Enter your title'
                        value={title}
                        onChange={(
                          text: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setTitle(text.target.value);
                          setRequired("");
                        }}
                        className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
                      />
                      <small className='text-red-500'>{required}</small>
                    </div>
                  </div>
                  <div className=' py-2 md:py-4' {...getRootProps()}>
                    <div className='w-full'>
                      {file.length > 0 ? (
                        productFiles
                      ) : (
                        <label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                          <img src={UPLOAD} alt='' className='h-12' />
                          <p className='flex flex-col items-center space-x-2'>
                            <span className='font-medium text-gray-600 mt-4 text-center text-sm'>
                              Click to upload or drag and drop <br />
                              Maxiumum file size 10 MB
                            </span>
                            <a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
                              Browse file
                            </a>
                          </p>
                          <span className='text-[#9CA3AF] text-xs mt-4 '>
                            Supported Image files only
                          </span>
                          <input
                            type='file'
                            name='file_upload'
                            className='hidden'
                            {...getInputProps()}
                          />
                        </label>
                      )}
                    </div>
                    <small className='text-red-500'>{requiredPhoto}</small>
                  </div>

                  <div className='flex justify-start items-center mt-4'>
                    <Button
                      icons={FLAG}
                      iconClassName='h-[0.9rem] w-3'
                      isLoading={loading}
                      title='Upload Document Info'
                      bgColor={"bg-brand_blue"}
                      onClick={() => handlePayment()}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg"}
                    />
                  </div>
                </>
              </Fade>
            </div>
          </>
        </Modal>
        <PartyInformation
          open={toggleStatus}
          close={() => dispatch(setToggle(false))}
          data={singlePartyInfo}
        />
      </div>
    </>
  );
}

export default ProceedPayment;

import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../baseQuery';

export const casesApi = createApi({
	reducerPath: 'cases',
	baseQuery: baseQuery(),
	endpoints: (builder) => ({
		// rtk

		getCaseFolders: builder.query({
			query: (role: string) => `/${role}/case-folders`,
		}),
		getCaseFoldersParams: builder.query({
			query: (params: string) => `/lawyer/case-folders?search=${params}`,
		}),
		getNotifications: builder.query({
			query: (userId: string) => `/notifications?${userId}`,
		}),
		getPayments: builder.query({
			query: () => `/payments`,
		}),
		getAnalytics: builder.query({
			query: (role: any) => `/${role}/analytics`,
		}),
		getCauseList: builder.query({
			query: (data: any) =>
				`/cause-list?name=${data?.name}&suit_number=${data?.suit_number}&title=${data?.title}`,
		}),
	}),
});

export const {
	useGetCaseFoldersQuery,
	useGetPaymentsQuery,
	useGetNotificationsQuery,
	useGetAnalyticsQuery,
	useGetCaseFoldersParamsQuery,
	useGetCauseListQuery,
} = casesApi;

import {
	Presiding_Judge_ConcludedCases,
	Presiding_Judge_Dashboard,
	Presiding_Judge_MyFiles,
	Presiding_Judge_Notification,
	Presiding_Judge_Profile,
} from '../features/presiding-judge';

import Judges from '../features/presiding-judge/judges';
import Open from '../features/presiding-judge/open-judges';
import ProcessPayment from '../features/presiding-judge/proceed-payment';

export const presiding_judge_Routes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Presiding_Judge_Dashboard,
		path: 'presiding-judge',
	},
	{
		id: '2',
		title: 'Case Folders',
		component: Presiding_Judge_MyFiles,
		path: 'presiding-judge/case-folders',
	},
	{
		id: '3',
		title: 'Notification',
		component: Presiding_Judge_Notification,
		path: 'presiding-judge/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Presiding_Judge_Profile,
		path: 'presiding-judge/profile',
	},

	{
		id: '6',
		title: 'Concluded Cases',
		component: Presiding_Judge_ConcludedCases,
		path: 'presiding-judge/concluded-cases',
	},
	{
		id: '7',
		title: 'process',
		component: ProcessPayment,
		path: 'presiding-judge/process',
	},
	{
		id: '8',
		title: 'Judges',
		component: Judges,
		path: 'presiding-judge/judges',
	},
	{
		id: '9',
		title: 'Open',
		component: Open,
		path: 'presiding-judge/judges/open',
	},
];

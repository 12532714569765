import React, { useEffect, useState } from 'react';
import { Zoom } from 'react-reveal';
import { Bounce } from 'react-reveal';
// import DOMPurify from 'dompurify';

type headType = {
	head: string;
	para: string;
};

interface CarouselProps {
	images: string[];
	header: headType[];
}

const Carousel: React.FC<CarouselProps> = ({ header, images }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const handlePrevClick = () => {
		setCurrentIndex(currentIndex === 0 ? images.length - 1 : currentIndex - 1);
	};

	const handleNextClick = () => {
		currentIndex === images.length - 1 ? setCurrentIndex(0) : setCurrentIndex(currentIndex + 1);
		// setCurrentIndex(currentIndex === images.length - 1 ? 0 : currentIndex + 1);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			handleNextClick();
		}, 6000);

		return () => {
			if (interval) {
				clearInterval(interval);
			}
		};
	});

	if (!Array.isArray(images) || images.length <= 0) {
		return null;
	}

	// const sanitizedData = () => ({
	// 	__html: DOMPurify.sanitize(),
	// });

	return (
		<div>
			<div className='flex flex-col-reverse min-h-[600px] justify-evenly lg:justify-between px-0 lg:px-8 items-center lg:flex-row ease-linear bg-[#272656]'>
				{/* <Zoom> */}
				<Bounce>
					<div className='text-center lg:text-left w-full lg:w-[48%]'>
						<h3 className='text-[1.5rem] font-bold lg:text-[4.0rem] text-[#F4FCFF] font-luxia'>
							{header[currentIndex].head}
						</h3>
						{/* <p className='w-[80%] mx-[auto] md:mb-[5%] lg:mx-[0%]  text-[#F4FCFF] font-luxia'>
							{header[currentIndex].para}
						</p> */}
						<div
							className='w-full lg:w-[80%] mx-[auto] md:mb-[5%] lg:mx-[0%]  text-[#F4FCFF] font-luxia'
							dangerouslySetInnerHTML={{ __html: header[currentIndex].para }}
						/>
					</div>
				</Bounce>
				{/* </Zoom> */}
				<img
					src={images[currentIndex]}
					className=' object-cover w-full lg:w-[50%] h-[300px] md:h-[500px]   '
					alt=''
				/>
			</div>
		</div>
	);
};

export default Carousel;

import React, { useState } from "react";
import { LOGO, login_bg } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/form/Input";
import Button from "../../components/button";
import { Fade } from "react-reveal";
import AuthService from "../../services/auth";
import { Form, Formik } from "formik";
import { toast } from "react-hot-toast";
import { loginSchema } from "../../schemas/login";
import { useAppDispatch } from "../../hooks";
import { setRole, setToken, setUser } from "../../states/slices/authReducer";

function Login() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  return (
    <div className='bg-white  font-family-karla h-screen'>
      <div className='w-full flex flex-wrap'>
        <div className='w-full md:w-1/2 flex flex-col'>
          <div className='flex justify-center md:justify-start pt-12 md:pl-12 md:-mb-24'>
            <Link to='/'>
              <img
                src={LOGO}
                className='h-full w-full rounded-full cursor-pointer'
                alt='...logo'
              />
            </Link>
          </div>
          <div className='flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32'>
            <p className='text-center text-xl font-bold'>Login</p>
            <Fade left>
              <Formik
                initialValues={{ email: "", password: "" }}
                validationSchema={loginSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  // console.log(values);
                  // setSubmitting(true);
                  if (submitDisabled) {
                    return;
                  }
                  setIsLoading(true);
                  try {
                    const res = await AuthService.login(
                      values.email,
                      values.password
                    );

                    // Redirect or perform any other action upon successful login
                    console.log(res);

                    toast.success("Login successful", {
                      duration: 5000, // Duration for which the toast is displayed (in milliseconds)
                    });
                    setIsLoading(false);
                    resetForm();
                    dispatch(setUser(res?.data));
                    dispatch(setRole(res?.data?.role));
                    dispatch(setToken(res?.data.token));
                    // localStorage.setItem('token', );

                    if (res?.data?.role === "LAWYER") {
                      navigate("/lawyer");
                    }

                    if (res?.data?.role === "NON LAWYER") {
                      navigate("/non-lawyer");
                    }
                    if (res?.data?.role === "REGISTRAR 1") {
                      navigate("/registrar-1");
                    }
                    if (res?.data?.role === "REGISTRAR 2") {
                      navigate("/registrar-2");
                    }
                    if (res?.data?.role === "PRESIDING JUDGE") {
                      navigate("/presiding-judge");
                    }
                    if (res?.data?.role === "PRESIDING MAGISTRATE") {
                      navigate("/presiding-magistrate");
                    }
                    if (res?.data?.role === "JUDGE") {
                      navigate("/judge");
                    }
                    if (res?.data?.role === "MAGISTRATE") {
                      navigate("/magistrate");
                    }
                    if (res?.data?.role === "BAILIFF") {
                      navigate("/bailiff");
                    }
                  } catch (error: any) {
                    setSubmitDisabled(true);
                    if (
                      error?.response?.data?.message ===
                      "Your account is awaiting approval, we will notify you once this process is completed"
                    ) {
                      navigate("/non-verify");
                      // console.log(error.response.data.message);
                      toast.error(error?.response?.data?.message, {
                        duration: 10000,
                      });
                      setIsLoading(false);
                    } else {
                      // console.log(error.response.data.message);
                      toast.error(error?.response?.data?.message, {
                        duration: 3000,
                      });
                      setIsLoading(false);
                    }
                  }
                }}
              >
                {({
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <Form className='flex flex-col pt-3 md:pt-8'>
                    <div className='flex flex-col pt-4'>
                      <label htmlFor='email' className='text-sm'>
                        Email
                      </label>
                      <Input
                        type='email'
                        placeholder='your@email.com'
                        name={"email"}
                      />
                    </div>
                    <div className='flex flex-col pt-4'>
                      <label htmlFor='password' className='text-sm'>
                        Password
                      </label>
                      <Input
                        type='password'
                        placeholder='password'
                        isPassword={true}
                        name={"password"}
                      />
                    </div>
                    <Link to='/forgot-password'>
                      <p className='text-right text-brand_blue text-sm mb-4'>
                        Forget password?
                      </p>
                    </Link>
                    {/* {isLoading ? (
											<Button
												isLoading={isLoading}
												bgColor={'bg-brand_indigo'}
												textColor={'text-white'}
												className={' mb-2 lg:mb-0 w-full py-4 rounded-2xl'}
											/>
										) : ( */}
                    <Button
                      isLoading={isSubmitting}
                      type={"submit"}
                      title={"Login"}
                      bgColor={"bg-brand_indigo"}
                      textColor={"text-white"}
                      className={" mb-2 lg:mb-0 w-full py-4 rounded-2xl"}
                    />
                    {/* )} */}
                  </Form>
                )}
              </Formik>
            </Fade>
            <div className='text-center pt-12 pb-12'>
              <p className='text-sm'>
                Don't have an account?{" "}
                <Link to='/signup' className='text-brand_blue font-semibold'>
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </div>

        <div className='w-1/2 shadow-2xl'>
          <img
            className='object-cover w-full h-screen hidden md:block'
            src={login_bg}
          />
        </div>
      </div>
    </div>
  );
}

export default Login;

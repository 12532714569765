import React, { useCallback, useEffect, useState } from "react";
import Button from "../../../components/button";
import { FLAG, PDF, PLUS, PLUS_DARK, SEARCH, UPLOAD } from "../../../assets";
import { FormInput } from "../../../components/form/Input";
import { useAppDispatch, useAppSelector, useMediaQuery } from "../../../hooks";
import {
  addDoc,
  addValue,
  getDocument,
  getPartyInfo,
  getSelectedCase,
  reload,
  removeDoc,
  removeValue,
  resetDoc,
  resetValue,
  setReload,
  updateCase,
} from "../../../states/slices/partyInfo.slice";
import { partyInfoTypes } from "../../../types";
import { useDropzone } from "react-dropzone";
import { getFormDataHeader, getJSONheader } from "../../../utils";
import { toast } from "react-hot-toast";
import Api from "../../../config";
import { BsFillTrash2Fill } from "react-icons/bs";
import Modal from "../../../components/UI/modal";
import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import Typography from "../../../components/Typography";
import { TableComponent } from "../../../components/table/Table";
import {
  PARTY_COLUMNS,
  PARTY_COLUMNS_EXISTING,
} from "../../../components/table/columns";
import { Zoom, Fade } from "react-reveal";

function CreateCases(props: any) {
  const [required, setRequired] = useState<string>("");
  const [partyErr, setPartyErr] = useState<string>("");
  const [uploadErr, setUploadErr] = useState<string>("");
  const [showInput, setShowInput] = useState(false);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(3);
  const [value, setValue] = useState("new");
  const [tab, setTab] = useState(0);
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const dispatch = useAppDispatch();
  const [suitNumber, setSuitNumber] = useState<any>(undefined);
  const partyInfo = useAppSelector(getPartyInfo);
  const docUpload = useAppSelector(getDocument);
  const [file, setFile] = useState<any>([]);
  const [title, setTitle] = useState<any>("");
  // const [data, setData] = useState<any>(null);
  const [base64Data, setBase64Data] = useState<any>(null);
  const reloadNow = useAppSelector(reload);
  const [addLoading, setAddLoading] = useState<boolean>(false);
  const data = useAppSelector(getSelectedCase);
  const [response, setResponse] = useState<any>();

  const [partyValue, setPartyValue] = useState<partyInfoTypes>({
    first_name: "",
    last_name: "",
    other_name: "",
    phone_number: "",
    email: "",
    title: "",
    address: "",
  });

  const initialPartyValue = {
    title: "",
    first_name: "",
    last_name: "",
    other_name: "",
    email: "",
    phone_number: "",
    address: "",
  };

  const initialErrors = {
    email: "",
  };
  const [errors, setErrors] = useState(initialErrors);
  const validateEmail = (email: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const { getCaseFolders, isLoadingCases, refetch } = useGetCaseFoldersParamsQuery(suitNumber, {
  // 	selectFromResult: ({ data, isLoading, isError }) => ({
  // 		getCaseFolders: data?.data,
  // 		isLoadingCases: isLoading,
  // 	}),
  // });

  // useEffect(() => {
  // 	handleSuitSearch();
  // }, [suitNumber]);

  useEffect(() => {
    if (props.open === false) {
      dispatch(resetDoc());
      dispatch(resetValue());
    }
  }, [props.open]);

  const handleSuitSearch = useCallback(() => {
    setLoading(true);
    Api.get(
      `/non-lawyer/case-folders?search=${suitNumber}`,
      getJSONheader()
    ).then((res) => {
      setLoading(false);
      // setData(res.data.data);
      dispatch(updateCase(res.data.data));
    });
  }, [suitNumber, data?.length]);

  const handlePartyHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (
      !partyValue.first_name ||
      !partyValue.title ||
      !partyValue.last_name ||
      !partyValue?.address
    ) {
      setRequired("required");
    } else {
      dispatch(addValue(partyValue));
      setPartyValue({
        ...partyValue,
        first_name: "",
        last_name: "",
        other_name: "",
        phone_number: "",
        email: "",
        address: "",
      });
      setRequired("");
    }
  };

  const handleDocumentUpload = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(addDoc({ title, file_path: file[0] }));
    setFile([]);
    setTitle("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 10000000,
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );

      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const base64 = reader.result;
          setBase64Data(base64);
        };

        reader.readAsDataURL(file);
      }
    },
  });

  const productFiles = file.map((item: any, index: number) => (
    <div className='p-4 flex justify-between items-center w-full lg:w-[45%] border border-gray-300 rounded-lg'>
      <div className='flex justify-start items-center'>
        <img src={PDF} className='h-6 w-6 ' />
        <p className='text-center text-green-600 text-sm ml-4' key={index}>
          {item.name}
        </p>
      </div>

      <button
        onClick={() => removeAll()}
        type='button'
        className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
      >
        Remove
      </button>
    </div>
  ));

  const removeAll = () => {
    file.length = 0;
  };

  const handleCreateCase = async () => {
    setLoading(true);
    const formData = {
      // suit_number: suitNumber,
      parties: partyInfo,
      // documents: docUpload,
    };
    if (partyInfo?.length < 1) {
      setPartyErr("kindly fill the party information");
      // setUploadErr('kindly fill upload all required documents');
      setLoading(false);
    } else {
      Api.post(`/lawyer/case-folders`, formData, getFormDataHeader())
        .then((res: any) => {
          if (res?.data) {
            toast.success("Case Created Successfully", {
              duration: 3000,
            });
            dispatch(resetDoc());
            dispatch(resetValue());

            setPartyErr("");
            setLoading(false);
            setResponse(res?.data?.data);
            setTab(2);
            setUploadErr("");
          } else if (res?.status === 413) {
            // Handle the 413 error
            toast.error("Uploaded documents too large", {
              duration: 3000,
            });
          }
        })
        .catch((error: any) => {
          // console.log(error, 'na the yeye error be this');
          setPartyErr("");
          setLoading(false);
          setUploadErr("");
          if (error?.response?.status === 413) {
            // Handle the 413 error
            toast.error("Uploaded documents too large", {
              duration: 3000,
            });
          } else {
            // Handle other errors here if needed
            console.error("Request failed:", error?.response?.data?.message);
          }
        });
    }
  };

  const handleAddDoc = (e: any) => {
    e.preventDefault();
    setAddLoading(true);
    let x = 5;
    if (file?.length < 1) {
      setRequired("required");
    } else {
      Api.post(
        `lawyer/case-folders/${response.id}/add-document`,
        { title, file_path: file[0] },
        getFormDataHeader()
      )
        .then((res) => {
          if (res.data) {
            // console.log(res);
            setFile([]);
            setAddLoading(false);

            toast.success(res.data.message, {
              duration: 3000,
            });
            setRequired("");
            setTitle("");

            // console.log(res.data.message, 'sususususususus');
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            duration: 3000,
          });
          setAddLoading(false);
        });
    }
  };
  const handleCreateExistingCase = async () => {
    const formData = {
      suit_number: data?.suit_number,
      parties: data?.parties,
      documents: data?.case_documents?.map((item: any) => {
        const parties = { title: item?.title, file_path: item?.file_path };

        return parties;
      }),
    };

    try {
      const res: any = Api.post(
        `/lawyer/case-folders/case-status/${data?.id}/resubmit`,
        formData,
        getJSONheader()
      );

      toast.success("Case Created Successfully", {
        duration: 3000,
      });
      dispatch(resetDoc());
      dispatch(resetValue());
      props.setOpen(false);
      setPartyErr("");
      setUploadErr("");
      setSuitNumber(null);
      dispatch(updateCase(null));
    } catch (error) {
      // console.log(error);
      setPartyErr("");
      setUploadErr("");
    }
  };

  const handleAddExistingParty = (e: any) => {
    e.preventDefault();
    setAddLoading(true);
    let x = 5;
    if (
      !partyValue.first_name &&
      !partyValue.title &&
      !partyValue.last_name &&
      !partyValue?.address
    ) {
      setRequired("required");
    } else {
      Api.post(
        `lawyer/case-folders/${data[0].id}/add-party`,
        partyValue,
        getJSONheader()
      )
        .then((res) => {
          // console.log(res);
          setPartyValue({
            ...partyValue,
            first_name: "",
            last_name: "",
            other_name: "",
            phone_number: "",
            email: "",

            address: "",
          });
          setLoading(false);
          setRequired("");
          dispatch(setReload(x++));
          toast.success(res.data.message, {
            duration: 3000,
          });
          setAddLoading(false);
          handleSuitSearch();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.response.data.message, {
            duration: 3000,
          });
          if (err.response.status == 413) {
            toast.error("Uploaded docs too large", {
              duration: 3000,
            });
          }
          // toast.error(err.response.data.message, {
          // 	duration: 3000,
          // });
          setAddLoading(false);
        });
    }
  };

  const handleAddExistingDoc = (e: any) => {
    e.preventDefault();
    setAddLoading(true);
    let x = 5;
    if (file?.length < 1) {
      setRequired("required");
    } else {
      Api.post(
        `lawyer/case-folders/${data[0].id}/add-document`,
        { title, file_path: file[0] },
        getFormDataHeader()
      )
        .then((res) => {
          // console.log(res);
          setFile([]);
          dispatch(setReload(x++));
          toast.success(res.data.message, {
            duration: 3000,
          });
          setRequired("");
          setAddLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            duration: 3000,
          });
          setAddLoading(false);
        });
    }
  };

  const handleDeleteExistingDoc = (docId: string) => {
    let x = 9;
    Api.delete(`/lawyer/case-documents/${docId}`, getJSONheader()).then(
      (res) => {
        // console.log(res);
        dispatch(setReload(x++));
        toast.success(res.data.message, {
          duration: 3000,
        });
      }
    );
  };

  // console.log('data', response);

  return (
    <>
      <Modal
        open={props.open}
        close={() => {
          props.setOpen(false);
          setTab(0);
          setPartyErr("");
          setUploadErr("");
          setRequired("");
          dispatch(resetDoc());
          dispatch(resetValue());
          dispatch(updateCase(null));
        }}
        tab={tab}
        back={() => {
          // setTab(tab > 0 ? tab - 1 : tab);
          setTab(0);
          setPartyErr("");
          setUploadErr("");
          setRequired("");
        }}
        maxWidth='md'
      >
        <>
          {tab === 0 && (
            <div className='pb-12'>
              <Box
                sx={{ width: "100%" }}
                className='mb-12 flex justify-center items-center px-2 lg:px-0'
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor='primary'
                  indicatorColor='primary'
                  aria-label='secondary tabs example'
                >
                  <Tab
                    value='new'
                    label='New case'
                    sx={{
                      fontSize: isMobileView ? 11 : isTabletView ? 12 : 12,
                    }}
                  />
                  <Tab
                    value='existing'
                    label='Existing case'
                    sx={{
                      fontSize: isMobileView ? 11 : isTabletView ? 12 : 12,
                    }}
                  />
                </Tabs>
              </Box>

              {value === "new" && (
                <Zoom>
                  <div className='flex flex-col justify-center items-center w-full'>
                    <div
                      onClick={() => setTab(1)}
                      className='bg-[#F3F8FD] hover:bg-brand_blue/20 hover:cursor-pointer mb-4  rounded-lg flex justify-between items-center p-4 w-[60%]'
                    >
                      <p>Party information</p>
                      <img src={PLUS_DARK} className='h-6 w-6' />
                    </div>

                    {/* <div
											onClick={() => setTab(2)}
											className='bg-[#F3F8FD] hover:bg-brand_blue/20 hover:cursor-pointer mb-4  rounded-lg flex justify-between items-center p-4 w-[60%]'
										>
											<p>Upload Documents</p>
											<img src={PLUS_DARK} className='h-6 w-6' />
										</div> */}

                    {/* <small className='text-red-500'>{partyErr}</small>
										<small className='text-red-500'>{uploadErr}</small> */}
                  </div>
                </Zoom>
              )}
              {value === "existing" && (
                <Zoom>
                  <div className='flex flex-col justify-center items-center w-full'>
                    <div
                      onClick={() => setShowInput(true)}
                      className='bg-[#F3F8FD] hover:bg-brand_blue/20 hover:cursor-pointer mb-4  rounded-lg flex justify-between items-center p-4 w-[60%]'
                    >
                      <p>Suit number</p>
                      <img src={PLUS_DARK} className='h-6 w-6' />
                    </div>

                    {showInput && (
                      <>
                        <FormInput
                          placeholder={"Enter your Suit number"}
                          type={"text"}
                          autoFocus={true}
                          value={suitNumber}
                          onChange={(e: any) => setSuitNumber(e.target.value)}
                          // onEnded={()=>handleSingleSuit()}
                        />

                        <div className='flex justify-end items-center my-4'>
                          <Button
                            iconClassName='h-[0.9rem] w-3'
                            title='Search Case Folder'
                            bgColor={"bg-brand_blue"}
                            onClick={() => handleSuitSearch()}
                            hoverColor={""}
                            textColor={"text-white"}
                            className={"py-4 rounded-lg"}
                          />
                        </div>
                      </>
                    )}
                    {loading && (
                      <div className='w-full justify-content items-center'>
                        <Stack
                          sx={{
                            color: "grey.500",
                          }}
                          spacing={2}
                          direction='row'
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <CircularProgress color='primary' />
                        </Stack>
                      </div>
                    )}

                    {data && loading === false && (
                      <>
                        <div
                          onClick={() => setTab(3)}
                          className='bg-[#F3F8FD] hover:bg-brand_blue/20 hover:cursor-pointer mb-4  rounded-lg flex justify-between items-center p-4 w-[60%]'
                        >
                          <p>Party information</p>
                          <img src={PLUS_DARK} className='h-6 w-6' />
                        </div>

                        <div
                          onClick={() => setTab(4)}
                          className='bg-[#F3F8FD] hover:bg-brand_blue/20 hover:cursor-pointer mb-4  rounded-lg flex justify-between items-center p-4 w-[60%]'
                        >
                          <p>Upload Documents</p>
                          <img src={PLUS_DARK} className='h-6 w-6' />
                        </div>

                        <small className='text-red-500'>{partyErr}</small>
                        <small className='text-red-500'>{uploadErr}</small>

                        <div className='flex justify-end items-center mt-4'>
                          <Button
                            icons={FLAG}
                            iconClassName='h-[0.9rem] w-3'
                            title='Submit'
                            bgColor={"bg-brand_indigo"}
                            onClick={() => handleCreateExistingCase()}
                            hoverColor={""}
                            textColor={"text-white"}
                            className={"py-4 rounded-lg w-44"}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </Zoom>
              )}
            </div>
          )}

          {tab === 1 && (
            <div className='px-8 pt-4 pb-12'>
              <Typography variant='h3'>Party Information</Typography>
              <form className=''>
                {required && (
                  <small className='text-red-500'>
                    All * information is compulsory
                  </small>
                )}
                <div className='flex justify-between items-center flex-wrap'>
                  <div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
                    <label htmlFor='email' className='text-sm'>
                      Title <sup className='text-red-500'>*</sup>
                    </label>
                    <select
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        const selectedOption = event.target.value;
                        setPartyValue({
                          ...partyValue,
                          title: selectedOption,
                        });
                      }}
                      className={`mb-2 appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                    >
                      <option value='' className='text-gray-200'>
                        Select an option
                      </option>
                      <option value='CLAIMANT'>Claimant</option>
                      <option value='DEFENDANT'>Defendant</option>
                      <option value='APPLICANT'>Applicant</option>
                      <option value='RESPONDENT'>Respondent</option>
                      <option value='APPELLANT'>Appellant</option>
                    </select>
                  </div>
                  <div className='flex flex-col   w-full lg:w-[48%] mb-2'>
                    <label htmlFor='email' className='text-sm'>
                      First name <sup className='text-red-500'>*</sup>
                    </label>
                    <FormInput
                      isDefault={true}
                      required={true}
                      type='text'
                      placeholder='Enter your first name'
                      value={partyValue.first_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          first_name: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='lastname' className='text-sm'>
                      Last name <sup className='text-red-500'>*</sup>
                    </label>
                    <FormInput
                      isDefault={true}
                      required={true}
                      type='text'
                      placeholder='Enter your last name'
                      value={partyValue.last_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          last_name: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Other name
                    </label>
                    <FormInput
                      isDefault={true}
                      type='text'
                      placeholder='Enter your other name'
                      value={partyValue.other_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          other_name: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Email Address <sup className='text-red-500'>*</sup>
                    </label>
                    <FormInput
                      isDefault={true}
                      type='email'
                      placeholder='Enter your email'
                      value={partyValue.email}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        const email = text.target.value;
                        setPartyValue({
                          ...partyValue,
                          email,
                        });
                        // Validate email and set error if invalid
                        setErrors({
                          ...errors,
                          email: validateEmail(email)
                            ? ""
                            : "Please enter a valid email address",
                        });
                      }}
                    />

                    {errors.email && (
                      <span className='text-red-500 text-sm'>
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Phone / Whatsapp Number
                    </label>
                    <FormInput
                      isDefault={true}
                      type='number'
                      placeholder='Enter your phone number or whatsapp number'
                      value={partyValue.phone_number}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          phone_number: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Contact Address <sup className='text-red-500'>*</sup>
                    </label>
                    <FormInput
                      isDefault={true}
                      required={true}
                      type='text'
                      placeholder='Enter your contact address'
                      value={partyValue.address}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          address: text.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className='flex justify-end items-center'>
                  <Button
                    icons={PLUS}
                    iconClassName='h-[0.9rem] w-3'
                    title='Add Party Info'
                    bgColor={"bg-brand_indigo"}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handlePartyHandler(e)
                    }
                    hoverColor={""}
                    textColor={"text-white"}
                    className={"py-4 rounded-lg"}
                  />
                </div>

                <div className='my-2'>
                  {partyInfo?.length > 0 && (
                    <TableComponent
                      className='rounded-none rounded-t-0'
                      DATA={partyInfo}
                      COLUMNS={PARTY_COLUMNS}
                      TITLE=''
                      isExport={null}
                      isPaginated={null}
                      isSearch={null}
                      size={size}
                      setSize={setSize}
                      setPageIndex={(value: number) => page + 1}
                    />
                  )}
                </div>

                {partyInfo?.length > 0 && (
                  // <div className='flex justify-end items-center mt-4'>
                  // 		<Button
                  // 			icons={FLAG}
                  // 			iconClassName='h-[0.9rem] w-3'
                  // 			isLoading={loading}
                  // 			title='Create Case Folder'
                  // 			bgColor={'bg-brand_blue'}
                  // 			onClick={() => handleCreateCase()}
                  // 			hoverColor={''}
                  // 			textColor={'text-white'}
                  // 			className={'py-4 rounded-lg'}
                  // 		/>
                  // 	</div>
                  <div className='flex justify-end items-center mt-4'>
                    <Button
                      icons={FLAG}
                      iconClassName='h-[0.9rem] w-3'
                      isLoading={loading}
                      title='Create Case Folder'
                      bgColor={"bg-brand_blue"}
                      onClick={() => handleCreateCase()}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg"}
                    />
                  </div>
                )}
              </form>
            </div>
          )}

          {tab === 2 && (
            <div className='p-8'>
              <Fade bottom>
                <>
                  <div className='mt-4'>
                    <div className='flex flex-col   w-full lg:w-[48%]'>
                      <label htmlFor='email' className='text-sm'>
                        Title <small className='text-red-500'>*</small>
                      </label>
                      <FormInput
                        type='text'
                        placeholder='Enter your title'
                        value={title}
                        onChange={(
                          text: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setTitle(text.target.value);
                        }}
                        className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
                      />
                    </div>
                  </div>
                  <div className=' py-2 md:py-4' {...getRootProps()}>
                    <div className='w-full'>
                      {file.length > 0 ? (
                        productFiles
                      ) : (
                        <label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                          <img src={UPLOAD} alt='' className='h-12' />
                          <p className='flex flex-col items-center space-x-2'>
                            <span className='font-medium text-gray-600 mt-4 text-center text-sm'>
                              Click to upload or drag and drop <br />
                              Maxiumum file size 10 MB
                            </span>
                            <a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
                              Browse file
                            </a>
                          </p>
                          <span className='text-[#9CA3AF] text-xs mt-4 '>
                            Supported PDF files only{" "}
                            <span className='text-blue-800 font-bold'>
                              not more than 10 mb
                            </span>
                          </span>
                          <input
                            type='file'
                            name='file_upload'
                            className='hidden'
                            {...getInputProps()}
                          />
                        </label>
                      )}
                    </div>
                  </div>

                  <div className='flex justify-end items-center mt-4 mb-2'>
                    <Button
                      icons={PLUS}
                      iconClassName='h-[0.9rem] w-3'
                      title='Add Document'
                      isLoading={addLoading}
                      bgColor={"bg-brand_indigo"}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleAddDoc(e)
                      }
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg"}
                    />
                  </div>
                  {data && data[0]?.case_documents?.length > 0 && (
                    <p className='my-4 font-bold text-lg'>
                      Uploaded Documents{" "}
                    </p>
                  )}
                  <div className='flex justify-between items-center w-full'>
                    {data &&
                      data[0]?.case_documents?.map(
                        (item: any, index: number) => (
                          <div className='w-[30%] border border-gray-300 rounded-lg p-2 flex justify-between items-center mb-1'>
                            <div className='flex justify-start items-center'>
                              <img src={PDF} className='h-6 w-6 ' />
                              <p
                                className='text-center text-black text-sm ml-4'
                                key={index}
                              >
                                {item?.title}
                              </p>
                            </div>
                            <button
                              onClick={() => handleDeleteExistingDoc(item?.id)}
                              className={`px-2 py-1 text-white text-xs  rounded-md bg-red-600 cursor-pointer`}
                            >
                              Delete
                            </button>
                          </div>
                        )
                      )}
                  </div>

                  <div className='flex justify-center items-center mt-4 mb-2'>
                    <Button
                      iconClassName='h-[0.9rem] w-3'
                      title='I have finished adding my documents'
                      bgColor={"bg-black"}
                      onClick={() => props.setOpen(false)}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg  mx-auto"}
                    />
                  </div>

                  {/* <div className='flex justify-end items-center mt-4'>
										<Button
											icons={PLUS}
											iconClassName='h-[0.9rem] w-3'
											title='Add Document'
											bgColor={'bg-brand_indigo'}
											onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleDocumentUpload(e)}
											hoverColor={''}
											textColor={'text-white'}
											className={'py-4 rounded-lg'}
										/>
									</div>
									{docUpload.length > 0 && (
										<p className='my-4 font-bold text-lg'>Uploaded Documents </p>
									)}
									<div className='my-2 lg:flex justify-between items-center flex-wrap '>
										{docUpload?.map((item: any, index: number) => (
											<>
												<div
													key={index}
													className='flex justify-between items-center mb-3 border border-gray-200 w-full lg:w-[48%] p-3  rounded-lg'
												>
													<div className='flex justify-start items-center '>
														<img src={PDF} className='h-6 w-6' />
														<div className='ml-4'>
															<p className='text-left text-gray-400 text-xs '>Document Title</p>
															<p className='text-center text-black font-bold text-sm mt-1'>
																{item?.title}
															</p>
														</div>
													</div>

													<button
														onClick={() => dispatch(removeDoc(item))}
														type='button'
														className={`px-1 py-1 hover:opacity-70 text-white text-xs  rounded-md bg-red-600`}
													>
														Delete
													</button>
												</div>
											</>
										))}
									</div>
									{docUpload?.length > 0 && (
										<div className='flex justify-start items-center mt-4'>
											<Button
												icons={FLAG}
												iconClassName='h-[0.9rem] w-3'
												title='Submit'
												bgColor={'bg-brand_blue'}
												onClick={() => setTab(0)}
												hoverColor={''}
												textColor={'text-white'}
												className={'py-4 rounded-lg'}
											/>
										</div>
									)} */}
                </>
              </Fade>
            </div>
          )}

          {/* existing cases */}

          {tab === 3 && (
            <div className='px-8 pt-4 pb-12'>
              <Typography variant='h3'>Party Information</Typography>
              <form className=''>
                <div className='flex justify-between items-center flex-wrap'>
                  <div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
                    <label htmlFor='email' className='text-sm'>
                      Title <small className='text-red-500'>*</small>
                    </label>
                    <select
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        const selectedOption = event.target.value;
                        setPartyValue({
                          ...partyValue,
                          title: selectedOption,
                        });
                      }}
                      className={`mb-2 appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
                    >
                      <option value='' className='text-gray-200'>
                        Select an option
                      </option>
                      <option value='CLAIMANT'>Claimant</option>
                      <option value='DEFENDANT'>Defendant</option>
                      <option value='APPLICANT'>Applicant</option>
                      <option value='RESPONDENT'>Respondent</option>
                      <option value='APPELLANT'>Appellant</option>
                    </select>
                    <small className='text-red-500'>{required}</small>
                  </div>
                  <div className='flex flex-col   w-full lg:w-[48%] mb-2'>
                    <label htmlFor='email' className='text-sm'>
                      First name
                    </label>
                    <FormInput
                      isDefault={true}
                      type='text'
                      placeholder='Enter your first name'
                      value={partyValue.first_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          first_name: text.target.value,
                        });
                      }}
                    />
                    <small className='text-red-500'>{required}</small>
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='lastname' className='text-sm'>
                      Last name
                    </label>
                    <FormInput
                      isDefault={true}
                      type='text'
                      placeholder='Enter your last name'
                      value={partyValue.last_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          last_name: text.target.value,
                        });
                      }}
                    />
                    <small className='text-red-500'>{required}</small>
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Other name
                    </label>
                    <FormInput
                      isDefault={true}
                      type='text'
                      placeholder='Enter your other name'
                      value={partyValue.other_name}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          other_name: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Email Address <sup className='text-red-500'>*</sup>
                    </label>
                    <FormInput
                      isDefault={true}
                      type='email'
                      placeholder='Enter your email'
                      value={partyValue.email}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        const email = text.target.value;
                        setPartyValue({
                          ...partyValue,
                          email,
                        });
                        // Validate email and set error if invalid
                        setErrors({
                          ...errors,
                          email: validateEmail(email)
                            ? ""
                            : "Please enter a valid email address",
                        });
                      }}
                    />

                    {errors.email && (
                      <span className='text-red-500 text-sm'>
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Phone / Whatsapp Number
                    </label>
                    <FormInput
                      isDefault={true}
                      type='number'
                      placeholder='Enter your phone number or whatsapp number'
                      value={partyValue.phone_number}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          phone_number: text.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className='flex flex-col w-full lg:w-[48%] mb-2'>
                    <label htmlFor='othername' className='text-sm'>
                      Contact Address
                    </label>
                    <FormInput
                      isDefault={true}
                      type='text'
                      placeholder='Enter your contact address'
                      value={partyValue.address}
                      onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
                        setPartyValue({
                          ...partyValue,
                          address: text.target.value,
                        });
                      }}
                    />
                    <small className='text-red-500'>{required}</small>
                  </div>
                </div>
                <div className='flex justify-end items-center'>
                  <Button
                    icons={PLUS}
                    iconClassName='h-[0.9rem] w-3'
                    title='Add Party Info'
                    bgColor={"bg-brand_indigo"}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                      handleAddExistingParty(e)
                    }
                    hoverColor={""}
                    textColor={"text-white"}
                    className={"py-4 rounded-lg"}
                    isLoading={addLoading}
                  />
                </div>

                <div className='my-2'>
                  {data[0]?.parties?.length > 0 && (
                    <TableComponent
                      className='rounded-none rounded-t-0'
                      DATA={data[0]?.parties}
                      COLUMNS={PARTY_COLUMNS_EXISTING}
                      TITLE=''
                      isExport={null}
                      isPaginated={null}
                      isSearch={null}
                      size={size}
                      setSize={setSize}
                      setPageIndex={(value: number) => page + 1}
                    />
                  )}
                </div>

                {data[0]?.parties?.length > 0 && (
                  <div className='flex justify-end items-center'>
                    <Button
                      icons={FLAG}
                      iconClassName='h-[0.9rem] w-3'
                      title='Submit'
                      bgColor={"bg-brand_blue"}
                      onClick={() => setTab(4)}
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg"}
                    />
                  </div>
                )}
              </form>
            </div>
          )}
          {tab === 4 && (
            <div className='p-8'>
              <Fade bottom>
                <>
                  <div className='mt-4'>
                    <div className='flex flex-col   w-full lg:w-[48%]'>
                      <label htmlFor='email' className='text-sm'>
                        Title <small className='text-red-500'>*</small>
                      </label>
                      <FormInput
                        type='text'
                        placeholder='Enter your title'
                        value={title}
                        onChange={(
                          text: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setTitle(text.target.value);
                        }}
                        className='border py-2 border-gray-200  px-4 mt-4 rounded-lg outline-none w-full'
                      />
                    </div>
                  </div>
                  <div className=' py-2 md:py-4' {...getRootProps()}>
                    <div className='w-full'>
                      {file.length > 0 ? (
                        productFiles
                      ) : (
                        <label className='flex flex-col justify-center items-center min-h-[250px] w-full px-4 transition border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none'>
                          <img src={UPLOAD} alt='' className='h-12' />
                          <p className='flex flex-col items-center space-x-2'>
                            <span className='font-medium text-gray-600 mt-4 text-center text-sm'>
                              Click to upload or drag and drop <br />
                              Maxiumum file size 10 MB
                            </span>
                            <a className='h-10 px-6 py-2 mt-4 lg:mt-0  ml-4 font-normal items-center text-black bg-BRAND-200 border border-BRAND-200 rounded-xl '>
                              Browse file
                            </a>
                          </p>
                          <span className='text-[#9CA3AF] text-xs mt-4 '>
                            Supported PDF files only
                          </span>
                          <input
                            type='file'
                            name='file_upload'
                            className='hidden'
                            {...getInputProps()}
                          />
                        </label>
                      )}
                    </div>
                  </div>

                  <div className='flex justify-end items-center mt-4 mb-2'>
                    <Button
                      icons={PLUS}
                      iconClassName='h-[0.9rem] w-3'
                      title='Add Document'
                      bgColor={"bg-brand_indigo"}
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                        handleAddExistingDoc(e)
                      }
                      hoverColor={""}
                      textColor={"text-white"}
                      className={"py-4 rounded-lg"}
                    />
                  </div>
                  {data[0]?.case_documents?.length > 0 && (
                    <p className='my-4 font-bold text-lg'>
                      Uploaded Documents{" "}
                    </p>
                  )}
                  <div className='flex justify-between items-center w-full'>
                    {data[0]?.case_documents?.map(
                      (item: any, index: number) => (
                        <div className='w-[30%] border border-gray-300 rounded-lg p-2 flex justify-between items-center mb-1'>
                          <div className='flex justify-start items-center'>
                            <img src={PDF} className='h-6 w-6 ' />
                            <p
                              className='text-center text-black text-sm ml-4'
                              key={index}
                            >
                              {item?.title}
                            </p>
                          </div>
                          <button
                            onClick={() => handleDeleteExistingDoc(item?.id)}
                            className={`px-2 py-1 text-white text-xs  rounded-md bg-red-600 cursor-pointer`}
                          >
                            Delete
                          </button>
                        </div>
                      )
                    )}
                  </div>

                  {data?.case_documents?.length > 0 && (
                    <div className='flex justify-center items-center mt-4'>
                      <Button
                        icons={FLAG}
                        iconClassName='h-[0.9rem] w-3'
                        title='Submit'
                        bgColor={"bg-brand_blue"}
                        onClick={() => {
                          setTab(0);
                          setValue("existing");
                        }}
                        hoverColor={""}
                        textColor={"text-white"}
                        className={"py-4 rounded-lg w-52"}
                      />
                    </div>
                  )}
                </>
              </Fade>
            </div>
          )}
        </>
      </Modal>
    </>
  );
}

export default CreateCases;

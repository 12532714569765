import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography';
import { selectUser } from '../../../states/slices/authReducer';
import { useAppSelector } from '../../../hooks';
import CreateCases from '../components/create-cases';
import Api from '../../../config';
import PaginatedTable from '../../../components/table/Paginated-table';
import { getJSONheader } from '../../../utils';
import { ADMIN_CASE_COLUMNS, ADMIN_USERS_COLUMNS } from '../../../components/table/columns/admin';
import { useGetAnalyticsQuery } from '../../../services/apis/cases';
import { MdOutlinePendingActions, MdOutlinePhoneCallback, MdWifiCalling } from 'react-icons/md';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { CgMenuMotion } from 'react-icons/cg';
import { AiOutlinePartition } from 'react-icons/ai';
import { VscLaw, VscServer } from 'react-icons/vsc';
import { FcCallback } from 'react-icons/fc';
import { Zoom } from 'react-reveal';
import { FaUsers } from 'react-icons/fa';

function ManageUsers() {
	const [active, setActive] = useState(0);
	const user = useAppSelector(selectUser);
	const [open, setOpen] = useState(false);

	const [data, setData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setLoading] = useState(false);

	const fetchData = async (page: any, pageSize: any) => {
		setLoading(true);
		try {
			const response = await Api.get(`/admin/users?page=${page}`, getJSONheader());
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
			setTotalPages(meta.last_page);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	const { getanalytics, isLoadingAnalytics, refetch } = useGetAnalyticsQuery('admin', {
		selectFromResult: ({ data, isLoading, isError }) => ({
			getanalytics: data?.data,
			isLoadingAnalytics: isLoading,
		}),
	});

	useEffect(() => {
		refetch();
	}, []);

	return (
		<div className='pt-4 px-2 lg:px-8 pb-36 lg:pb-0'>
			<Typography textStyle='text-[20px] font-medium'>Manage Users</Typography>
			<div className='w-full  pb-20 lg:pb-8'>
				<Zoom>
					<section className='flex justify-around items-center flex-wrap mt-6'>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-purple-600 bg-purple-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.all_users}</span>
								<span className='block text-gray-500 text-sm'>All users</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.bailiff}</span>
								<span className='block text-gray-500 text-sm'>Bailiff</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-red-600 bg-red-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='inline-block text-2xl font-bold'>
									{getanalytics?.users?.judge}
								</span>
								<span className='block text-gray-500 text-sm'>Judges</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.lawyers}</span>
								<span className='block text-gray-500'>Lawyers</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-orange-600 bg-orange-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.magistrate}</span>
								<span className='block text-gray-500 text-sm'>Magistrates</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-lime-600 bg-lime-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.non_lawyers}</span>
								<span className='block text-gray-500 text-sm'>Non-Lawyers</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-cyan-600 bg-cyan-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.users?.presiding_judge}
								</span>
								<span className='block text-gray-500 text-sm'>Presiding Judge</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-yellow-600 bg-yellow-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.users?.presiding_magistrate}
								</span>
								<span className='block text-gray-500 text-sm'>Presiding Magistrates</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-slate-600 bg-slate-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.registra_1}</span>
								<span className='block text-gray-500 text-sm'>Registrar 1</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-rose-600 bg-rose-100 rounded-full mr-6'>
								<FaUsers size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>{getanalytics?.users?.registra_2}</span>
								<span className='block text-gray-500 text-sm'>Registrar 2</span>
							</div>
						</div>
					</section>
				</Zoom>

				<div className='mt-8 lg:flex justify-between items-end'>
					<div className=' w-full lg:mb-0'>
						<div className=' border-b  border-[#F0F0F0] rounded-b-xl'>
							<PaginatedTable
								columns={ADMIN_USERS_COLUMNS}
								data={data}
								totalPages={totalPages}
								fetchData={fetchData}
								isLoading={isLoading}
							/>
						</div>
					</div>
				</div>
			</div>
			<CreateCases {...{ open, setOpen }} />
		</div>
	);
}

export default ManageUsers;

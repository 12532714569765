import {
  Dashboard,
  MyFiles,
  Notification,
  Payment,
  Profile,
} from "../features/non-lawyer";
import EditCases from "../features/non-lawyer/edit-cases";
import ProceedPayment from "../features/non-lawyer/proceed-payment";
import ViewCases from "../features/non-lawyer/view-cases";

export const NonlawyerRoutes = [
  {
    id: "1",
    title: "Dashboard",
    component: Dashboard,
    path: "non-lawyer",
  },
  {
    id: "2",
    title: "My Files",
    component: MyFiles,
    path: "non-lawyer/my-files",
  },
  {
    id: "3",
    title: "Notification",
    component: Notification,
    path: "non-lawyer/notification",
  },
  {
    id: "4",
    title: "Profile",
    component: Profile,
    path: "non-lawyer/profile",
  },
  // {
  // 	id: '5',
  // 	title: 'Payment',
  // 	component: Payment,
  // 	path: 'lawyer/payment',
  // },
  // {
  // 	id: '6',
  // 	title: 'Payment',
  // 	component: Payment,
  // 	path: 'lawyer/proceed',
  // },
  {
    id: "7",
    title: "ProceedPayment",
    component: ProceedPayment,
    path: "non-lawyer/proceed",
  },
  {
    id: "8",
    title: "ViewCases",
    component: ViewCases,
    path: "non-lawyer/view-case",
  },
  {
    id: "9",
    title: "EditCases",
    component: EditCases,
    path: "non-lawyer/edit-case",
  },
];

import { createContext, useState, ReactNode } from "react";

type Context = {
  isOpenNav: boolean;
  setIsOpenNav: (value: boolean) => void;
};

interface Props {
  children?: ReactNode;
}

export const NavContext = createContext<Context>({
  isOpenNav: false,
  setIsOpenNav: () => {},
});

export const NavProvider = ({ children }: Props) => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const value = { isOpenNav, setIsOpenNav };

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

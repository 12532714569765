import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { selectUser } from '../../../states/slices/authReducer';
import { useGetNotificationsQuery } from '../../../services/apis/cases';
import { SEARCH, USERAVATAR } from '../../../assets';

function Notification(this: {
	id: string;
	title: string;
	component: () => import('react/jsx-runtime').JSX.Element;
	path: string;
}) {
	const user = useAppSelector(selectUser);
	const [search, setSearch] = useState('');

	const { notification, isLoadingNotification, refetch } = useGetNotificationsQuery(user?.id, {
		selectFromResult: ({ data, isLoading, isError }) => ({
			notification: data?.data,
			isLoadingNotification: isLoading,
		}),
	});

	useEffect(() => {
		refetch();
	}, []);

	const searchNotification = (event: any) => {
		setSearch(event.target.value);
	};

	let dataSearch =
		isLoadingNotification === true
			? []
			: notification?.filter((item: { [x: string]: { toString: () => string } }) => {
					return Object.keys(item).some((key) =>
						item[key].toString().toLowerCase().includes(search.toString().toLowerCase())
					);
			  });

	console.log(notification);

	return (
		<div className='pb-20 lg:pb-0 lg:px-8'>
			<div className='bg-[#F4FCFF]  py-2 px-2 w-[100%] md:w-[30%] my-[2%] flex items-center'>
				<img src={SEARCH} alt='' className='h-[3vh]' />
				<input
					type='text'
					placeholder='Search in Files'
					className='bg-[transparent] pl-4 border-0 outline-0 w-[100%]'
					value={search}
					onChange={searchNotification.bind(this)}
				/>
				{/* <img src={DATErANGE} alt='' className='h-[3vh]' /> */}
			</div>
			{dataSearch?.map((item: any, index: any) => {
				const body = item?.body;
				const joinUrlIndex = body?.indexOf('Join Url: ');
				if (joinUrlIndex !== -1) {
					const joinUrlSubstring = body.substring(joinUrlIndex + 'Join Url: '.length);
					const joinUrl = joinUrlSubstring.split(' ')[0];

					return (
						<div
							className={`${
								index % 2 === 0 ? 'bg-[#F4FCFF]' : 'bg-[#F2F2F2]'
							} flex justify-between  items-center pl-[5%]  py-3`}
						>
							<div className='flex gap-2 justify-start items-center w-[87%]'>
								<img src={USERAVATAR} alt='' className='w-[40px] h-[40px]' />
								<div className='text-left pl-[3%]'>
									<h4 className='font-bold'>{item.title}</h4>
									<p className='text-[12px]'>{item.body}</p>
									<p className='text-[10px]'>{`${new Date(item.created_at)}`.slice(0, 16)}</p>
								</div>
							</div>

							<a
								target='_blank'
								href={joinUrl}
								className='px-4  self-end py-1 mr-4 bg-brand_indigo text-center text-[#fff] text-xs  w-20  rounded-md'
								rel='noreferrer'
							>
								Join
							</a>
						</div>
					);
				} else {
					return (
						<div
							className={`${
								index % 2 === 0 ? 'bg-[#F4FCFF]' : 'bg-[#F2F2F2]'
							} flex items-center pl-[5%]  py-3`}
						>
							<img src={USERAVATAR} alt='' className='w-[40px] h-[40px]' />
							<div className='text-left pl-[3%]'>
								<h4 className='font-bold'>{item.title}</h4>
								<p className='text-[12px]'>{item.body}</p>
								<p className='text-[10px]'>{`${new Date(item.created_at)}`.slice(0, 16)}</p>
							</div>
						</div>
					);
				}
			})}
		</div>
	);
}

export default Notification;

import {
	Bailiff_ConcludedCases,
	Bailiff_Dashboard,
	Bailiff_MyFiles,
	Bailiff_Notification,
	Bailiff_Profile,
} from '../features/bailiff';

import Judges from '../features/bailiff/judges';
import Open from '../features/bailiff/open-judges';
import ProcessPayment from '../features/bailiff/proceed-payment';

export const bailff_Routes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Bailiff_Dashboard,
		path: 'bailiff',
	},
	{
		id: '2',
		title: 'Service',
		component: Bailiff_MyFiles,
		path: 'bailiff/service',
	},
	{
		id: '3',
		title: 'Notification',
		component: Bailiff_Notification,
		path: 'bailiff/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Bailiff_Profile,
		path: 'bailiff/profile',
	},

	{
		id: '6',
		title: 'Concluded Cases',
		component: Bailiff_ConcludedCases,
		path: 'bailiff/concluded-cases',
	},
	{
		id: '7',
		title: 'process',
		component: ProcessPayment,
		path: 'bailiff/process',
	},
	{
		id: '8',
		title: 'Cause list',
		component: Judges,
		path: 'bailiff/cause-list',
	},
	{
		id: '9',
		title: 'Open',
		component: Open,
		path: 'bailiff/judges/open',
	},
];

import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	getSelectedCase,
	getSingleParty,
	setToggle,
	setToggle_2,
	toggleState,
	toggleState_2,
} from '../../../states/slices/partyInfo.slice';
import { TableComponent } from '../../../components/table/Table';
import { PARTY_COLUMNS_view } from '../../../components/table/columns';
import Typography from '../../../components/Typography';
import {
	CHEVRON_LEFT,
	FLAG,
	FOLDER,
	LOGO,
	MINUS,
	PDF,
	PLUS,
	QUESTION,
	STAMP,
	UPLOAD,
} from '../../../assets';
import { formatCurrency, getJSONheader } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Api from '../../../config';
import { useGetPaymentsQuery } from '../../../services/apis/cases';
import { toast } from 'react-hot-toast';
import PartyInformation from '../../../components/modals/Partinformation';
import FillingFee from '../../../components/modals/FillingFee';
import { PDFDocument, rgb, degrees } from 'pdf-lib';
import { selectUser } from '../../../states/slices/authReducer';
import Modal from '../../../components/UI/modal';

function ProcessPayment() {
	const getCase = useAppSelector(getSelectedCase);
	const [pdfBytes, setPdfBytes] = useState<any>(null);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const navigate = useNavigate();
	const [remark, setRemark] = useState<any>('');
	const [waiver, setWaiver] = useState<any>(1);
	const [bgColor, setBgColor] = useState<any>('bg-red-700');
	const user = useAppSelector(selectUser);
	const [paymentID, setPaymentID] = useState<any>();
	const [remarkRequire, setRemarkRequire] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const [waiverloading, setWaiverLoading] = useState<boolean>(false);
	const [rjloading, setRjLoading] = useState<boolean>(false);
	const toggleStatus = useAppSelector(toggleState);
	const toggleStatus_2 = useAppSelector(toggleState_2);
	const singlePartyInfo = useAppSelector(getSingleParty);
	const dispatch = useAppDispatch();
	const [products, setProducts] = useState<any[]>(getCase?.case_documents);
	const [totalPrice, setTotalPrice] = useState<number>();
	const [open, setOpen] = useState(false);
	const [blobUrl, setBlobUrl] = useState<any>(null);
	// console.log(user);

	// Function to handle input value changes
	const handleInputChange = (index: any, event: any) => {
		const { value } = event.target;

		setProducts((prevProducts) => {
			const updatedProducts = [...prevProducts];
			updatedProducts[index].price = value;

			// Calculate the total sum
			const total = updatedProducts.reduce((sum, product) => {
				return sum + (parseFloat(product.price) || 0); // Convert price to a number before adding
			}, 0);

			setTotalPrice(total); // Update the total state variable

			return updatedProducts;
		});
	};

	useEffect(() => {
		fetchDataFromBackend();
	}, [getCase?.case_documents]);

	const fetchDataFromBackend = () => {
		// Simulated data from the backend

		const updatedProducts = getCase?.case_documents.map((data: any) => ({
			case_document_id: data.id,
			file_path: data.file_path,
			title: data.title,
			price: 0,
		}));
		// Initialize price as empty string

		setProducts(updatedProducts);
	};

	const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
		selectFromResult: ({ data, isLoading, isError }: any) => ({
			paymentData: data?.data,
			isLoadingCases: isLoading,
		}),
	});

	useEffect(() => {
		handlePaymentData(paymentData);
	}, [paymentData]);

	useEffect(() => {
		refetch();
	}, [page]);

	const handlePaymentData = (paymentData: any[]) => {
		paymentData?.map((item: any) => {
			if (item?.service?.id === getCase?.id) {
				// console.log(item, 'ddd');

				setPaymentID(item);
				return item;
			}
		});
	};

	const handleSubmitToRegistrar_2 = async () => {
		setLoading(true);
		Api.post(
			`/registra1/case-folders/${getCase?.id}/submit`,
			{
				court: 'HIGH COURT',
			},
			getJSONheader()
		)
			.then((res) => {
				toast.success(`${res?.data.message}`, {
					duration: 3000,
				});
				// console.log(res.data);
				setLoading(false);

				navigate('/registrar-1/case-folders');
			})
			.catch((error: any) => {
				setLoading(false);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
			});
	};

	const handleAdvisePayment = async () => {
		if (remark) {
			setLoading(true);
			Api.post(
				`/registra1/case-folders/${getCase?.id}/advice-payment`,
				{
					require_payment: true,
					remark: remark,
					case_documents: products,
				},
				getJSONheader()
			)
				.then((res) => {
					toast.success(`${res?.data.message}`, {
						duration: 3000,
					});
					// console.log(res.data);
					setLoading(false);

					navigate('/registrar-1/case-folders');
				})
				.catch((error: any) => {
					setLoading(false);
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
				});
		} else {
			setRemarkRequire('A remark is required');
		}
	};
	const handleReject = () => {
		if (remark) {
			setRjLoading(true);
			Api.post(`/registra1/case-folders/${getCase?.id}/reject`, { remark: remark }, getJSONheader())
				.then((res) => {
					// console.log(res);
					navigate('/registrar-1/case-folders');
					toast.success(`${res?.data.message}`, {
						duration: 3000,
					});
					setRjLoading(false);
				})
				.catch((error) => {
					setRjLoading(false);
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
				});
		} else {
			setRemarkRequire('A remark is required');
		}
	};

	const handleWaiver = () => {
		setWaiverLoading(true);
		if (waiver === 0) {
			Api.put(
				`/registra1/case-folders/${getCase?.id}/set-payment-option`,
				{ require_payment: waiver },
				getJSONheader()
			)
				.then((res) => {
					// console.log(res);
					setBgColor('bg-red-700');
					setWaiverLoading(false);
					setWaiver(1);

					toast.success(`Waiver have been added successfully`, {
						duration: 3000,
					});
				})
				.catch((error) => {
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
					setWaiverLoading(false);
				});
		} else if (waiver === 1) {
			Api.put(
				`/registra1/case-folders/${getCase?.id}/set-payment-option`,
				{ require_payment: waiver },
				getJSONheader()
			)
				.then((res) => {
					// console.log(res);
					setBgColor('bg-green-700');
					setWaiver(0);
					setWaiverLoading(false);
					toast.success(`Waiver have been removed successfully`, {
						duration: 3000,
					});
				})
				.catch((error) => {
					toast.error(error?.response?.data?.message, {
						duration: 3000,
					});
					setWaiverLoading(false);
				});
		}
	};

	// console.log(getCase);

	const handleUpdateDoc = async (file: File, item: any) => {
		// console.log(item, 'na me be this');

		try {
			const formData = new FormData();

			// Convert the File into a Blob with a specific name
			const fileBlob = new Blob([file], { type: 'application/pdf' });
			formData.append('file_path', fileBlob, 'file.pdf');

			const res: any = await Api.post(
				`registra1/case-documents/${item?.id || item?.case_document_id}/update`,
				formData
				// getJSONheader() - If you need to set headers, you can add them here
			);
			console.log(res, 'eeee');

			if (res) {
				toast.success(`stamped successfully. ${res?.data?.message}`, {
					duration: 3000,
				});
			}
			setOpen(true);
			setBlobUrl(res?.data?.data?.file_path);
		} catch (error: any) {
			toast.error(error?.response?.data?.message, {
				duration: 3000,
			});
		}
	};

	const HandleOpenDoc = () => {
		return (
			<Modal
				open={open}
				close={() => {
					setOpen(false);
					setBlobUrl(null);
				}}
				maxWidth='lg'
			>
				<iframe title='PDF Viewer' src={blobUrl} className='w-full ' height={900} />
			</Modal>
		);
	};

	const addStamp = async (pdf: string, item: any) => {
		const url = pdf;
		const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

		const pdfDoc = await PDFDocument.load(existingPdfBytes);

		const pages = pdfDoc.getPages();
		const lastPage = pages[pages.length - 1];

		const pngUrl = user?.stamp_photo;
		const pngImageBytes = await fetch(pngUrl).then((res) => res.arrayBuffer());
		const pngImage = await pdfDoc.embedPng(pngImageBytes);

		lastPage.drawImage(pngImage, {
			x: 150,
			y: 100,
			width: 150,
			height: 150,
			rotate: degrees(0),
			opacity: 0.6,
		});

		// Get today's date and format it as "10-12-2023"
		const currentDate = new Date();
		const formattedDate = `${currentDate.getDate()}-${
			currentDate.getMonth() + 1
		}-${currentDate.getFullYear()}`;

		lastPage.drawText(formattedDate, {
			x: 200, // Adjust the x-coordinate to position the text as desired
			y: 150, // Adjust the y-coordinate to position the text as desired
			size: 12, // Adjust the font size
			color: rgb(0, 0, 0), // Text color (black)
			opacity: 0.6,
		});

		const pdfBytes = await pdfDoc.save();

		const blob = new Blob([pdfBytes], { type: 'application/pdf' });
		const blobUrl = URL.createObjectURL(blob);

		const file = new File([blob], 'stamped.pdf', { type: 'application/pdf' });

		handleUpdateDoc(file, item);

		URL.revokeObjectURL(blobUrl);
	};

	return (
		<>
			<div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
				<Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
			</div>
			{getCase?.payment_status !== 'success' && (
				<div className='flex justify-end items-center mt-4 pr-8'>
					<Button
						isLoading={waiverloading}
						icons={waiver === 0 ? PLUS : MINUS}
						iconClassName='h-[0.9rem] w-3'
						title={waiver === 0 ? 'Add Fee Waiver' : 'Remove Waiver'}
						bgColor={bgColor}
						onClick={() => handleWaiver()}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-2 rounded-lg'}
					/>
				</div>
			)}
			<div className='pt-4 pb-8 px-4 lg:px-8'>
				<Typography textStyle='text-[20px] font-medium'>Party Information</Typography>
				<div className='my-2'>
					{getCase?.parties?.length > 0 ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getCase?.parties}
							COLUMNS={PARTY_COLUMNS_view}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={(value: number) => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Party Information
						</Typography>
					)}
				</div>
				<Typography textStyle='text-[20px] font-medium'>Uploaded Documents</Typography>
				<div className='my-2 lg:flex justify-between items-center flex-wrap '>
					{getCase?.payment_status !== 'success' && products?.length > 0 ? (
						products.map((item: any, index: number) => (
							<div className='w-full lg:w-[48%]'>
								<div
									key={index}
									className='flex justify-between items-center mb-3 border border-gray-200 w-full  p-3  rounded-lg'
								>
									<div className='flex justify-start items-center '>
										<a target='_blank'>
											<img src={PDF} className='h-6 w-6' />
										</a>
										<div className='ml-4'>
											<p className=' text-gray-400 text-xs '>Document Title</p>
											<p className=' text-black font-bold text-sm mt-1'>{item?.title}</p>
										</div>
									</div>

									{getCase?.payment_status !== 'success' ? (
										<input
											className={`${
												waiver === 1 ? 'hidden' : 'visible'
											} border border-gray-300 p-3 rounded-md w-28 h-12`}
											value={item?.price}
											onChange={(event) => handleInputChange(index, event)}
										/>
									) : (
										<p className='text-green-600 text-xs font-semibold'>Paid</p>
									)}
								</div>

								{waiver === 1 && (
									<div className='mb-4'>
										<a
											href={item?.file_path}
											target='_blank'
											className='px-2 py-1 bg-brand_indigo text-[#fff] text-xs  w-28  rounded-md'
										>
											View only
										</a>
										<button
											onClick={() => addStamp(item?.file_path, item)}
											className='px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20 ml-4 rounded-md'
										>
											Append Stamp
										</button>
									</div>
								)}
							</div>
						))
					) : getCase?.payment_status !== 'success' && products?.length < 1 ? (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Uploaded Documents
						</Typography>
					) : null}
					{getCase?.payment_status === 'success' && getCase?.case_documents?.length > 0 ? (
						getCase?.case_documents.map((item: any, index: number) => (
							<div className='w-full lg:w-[48%]'>
								<div
									key={index}
									className='flex justify-between items-center mb-3 border border-gray-200 w-full  p-3  rounded-lg'
								>
									<div className='flex justify-start items-center '>
										<a target='_blank'>
											<img src={PDF} className='h-6 w-6' />
										</a>
										<div className='ml-4'>
											<p className=' text-gray-400 text-xs '>Document Title</p>
											<p className=' text-black font-bold text-sm mt-1'>{item?.title}</p>
										</div>
									</div>

									<div>
										<p className='text-center text-gray-400 text-xl  border-gray-300 rounded-lg'>
											{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(item?.price)}
										</p>
										<p className='text-green-600 text-md font-semibold text-center'>Paid</p>
									</div>
								</div>

								{waiver === 1 && (
									<div className='mb-4'>
										<a
											href={item?.file_path}
											target='_blank'
											className='px-2 py-1 bg-brand_indigo text-[#fff] text-xs  w-28  rounded-md'
										>
											View only
										</a>

										<button
											onClick={() => addStamp(item?.file_path, item)}
											className='px-1 py-1 bg-brand_indigo text-[#fff] text-xs  w-20 ml-4 rounded-md'
										>
											Append Stamp
										</button>
									</div>
								)}
							</div>
						))
					) : getCase?.payment_status === 'success' && getCase?.case_documents?.length > 1 ? (
						<Typography variant='label' textStyle='font-medium text-center'>
							No Uploaded Documents
						</Typography>
					) : null}
				</div>
				{pdfBytes && (
					<iframe src={URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }))} />
				)}
				<div className={` justify-between lg:flex  items-end`}>
					<div className='w-full lg:w-[40%]'>
						<Typography textStyle='text-[20px] font-medium mb-2'>
							<>
								Remarks <sup className='text-red-600 text-xs'>*</sup>
							</>
						</Typography>

						<textarea
							name='remark'
							placeholder={'Kindly add remarks'}
							className='bg-[#13BAED0D] h-40 w-full resize-none flex justify-start p-4 text-sm'
							value={remark}
							onChange={(e) => {
								setRemark(e.target.value);
								setRemarkRequire('');
							}}
						/>
						<small className='text-red-600'>{remarkRequire}</small>
					</div>

					<div className='ml-4 w-full lg:w-[30%] mt-2 lg:mt-0'>
						{waiver === 1 && getCase?.payment_status !== 'success' ? (
							<>
								<Typography textStyle='text-[20px] font-medium text-center'>Total</Typography>
								<p className='text-center text-green-600 bg-[#D9D9D91A] text-sm text-semibold  p-2 '>
									<sup className='text-red-700'>*</sup> Fee has been been waived !!!
								</p>
							</>
						) : waiver === 0 && getCase?.payment_status !== 'success' ? (
							<>
								<Typography textStyle='text-[20px] font-medium text-center'>Total</Typography>
								<p className='text-center text-green-600 bg-[#D9D9D91A] text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
									{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(
										totalPrice ? totalPrice : 0
									)}
								</p>
							</>
						) : getCase?.payment_status === 'success' ? (
							<>
								<Typography textStyle='text-[20px] font-medium text-center'>Amount Paid</Typography>
								<p className='text-center text-green-600 bg-[#D9D9D91A] text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
									{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(getCase?.amount_paid)}
								</p>
							</>
						) : null}
						<a
							type='button'
							className='text-sm mt-2 flex justify-center items-center w-full cursor-pointer'
							onClick={() => dispatch(setToggle_2(true))}
						>
							<img src={QUESTION} className='h-4' />{' '}
							<span className='mx-1'>{` Information on `}</span>
							<span className='text-brand_blue font-bold'>{` filing fees`}</span>
						</a>
					</div>
				</div>
				<div className='w-full md:flex justify-between items-end mt-10'>
					<div className='w-full lg:w-[45%] flex justify-between items-center flex-wrap'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/registrar-1/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 hidden md:flex'}
						/>
						{getCase?.payment_status !== 'success' && (
							<Button
								isLoading={rjloading}
								icons={MINUS}
								iconClassName='h-[0.9rem] w-3'
								title='Reject  Folder'
								bgColor={'bg-red-600'}
								onClick={() => handleReject()}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 w-40 lg:w-44'}
							/>
						)}
						<div className='w-40 md:w-44 md:hidden'>
							<Button
								isLoading={loading}
								icons={FLAG}
								iconClassName='h-[0.9rem] w-3'
								title={waiver === 1 ? 'Submit to Registrar 2' : 'Advise  Payment'}
								bgColor={'bg-[#27AE60]'}
								onClick={() => (waiver === 1 ? handleSubmitToRegistrar_2() : handleAdvisePayment())}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 w-full'}
							/>
						</div>
					</div>

					<div className='w-[30%] md:w-44 mx-auto lg:mx-0 mt-3 lg:mt-0'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/registrar-1/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 md:hidden flex'}
						/>
						<Button
							isLoading={loading}
							icons={FLAG}
							iconClassName='h-[0.9rem] w-3'
							title={waiver === 1 ? 'Submit to Registrar 2' : 'Advise  Payment'}
							bgColor={'bg-[#27AE60]'}
							onClick={() => (waiver === 1 ? handleSubmitToRegistrar_2() : handleAdvisePayment())}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-full hidden md:flex'}
						/>
					</div>
				</div>
				{HandleOpenDoc()} {/* Conditionally render the modal */}
				<PartyInformation
					open={toggleStatus}
					close={() => dispatch(setToggle(false))}
					data={singlePartyInfo}
				/>
				<FillingFee open={toggleStatus_2} close={() => dispatch(setToggle_2(false))} />
			</div>
		</>
	);
}

export default ProcessPayment;

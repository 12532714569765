import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState, Store } from '../states/store';

type callbackProps = (headers: Headers) => Headers;

export const baseQuery = (callback?: callbackProps) => {
	return fetchBaseQuery({
		baseUrl: process.env.REACT_APP_BASE_URL,
		prepareHeaders: (headers, { getState }) => {
			// const token = localStorage.getItem('token');
			const token = Store.getState().auths.token;

			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}

			callback && callback(headers);
			return headers;
		},
	});
};

import Typography from '../../../components/Typography';
import Button from '../../../components/button';
import { PLUS } from '../../../assets';
import PieCharts from '../../../components/chart/Piechart';
import { useEffect, useState } from 'react';
import { useGetAnalyticsQuery, useGetCaseFoldersQuery } from '../../../services/apis/cases';
import CreateCases from '../components/create-cases';
import DropDown from '../../../components/dropdownBtn';
import { useNavigate } from 'react-router-dom';
import {
	BarChart,
	Bar,
	Cell,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { formatCurrency } from '../../../utils';
import { MdOutlinePendingActions, MdOutlinePhoneCallback, MdWifiCalling } from 'react-icons/md';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { CgMenuMotion } from 'react-icons/cg';
import { AiOutlinePartition } from 'react-icons/ai';
import { VscLaw, VscServer } from 'react-icons/vsc';
import { FcCallback } from 'react-icons/fc';
import { Zoom } from 'react-reveal';
import { resetDoc, resetValue } from '../../../states/slices/partyInfo.slice';
import { useDispatch } from 'react-redux';

function Dashboard() {
	const [dataGraph, setDataGraph] = useState();
	const navigate = useNavigate();
	const { getanalytics, isLoadingAnalytics, refetch } = useGetAnalyticsQuery('admin', {
		selectFromResult: ({ data, isLoading, isError }) => ({
			getanalytics: data?.data,
			isLoadingAnalytics: isLoading,
		}),
	});
	const dispatch = useDispatch();

	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		fetchGraphData();
	}, []);
	useEffect(() => {
		const controller = new AbortController();
		dispatch(resetDoc());
		dispatch(resetValue());

		return () => controller.abort();
	}, []);

	const fetchGraphData = () => {
		const labels = getanalytics?.payment_chart?.label;
		const data = getanalytics?.payment_chart?.data;
		const mergedArray = labels?.map((label: any, index: number) => ({
			name: label.charAt(0).toUpperCase() + label.slice(1),
			pv: data[index],
			// amt: data[index]
		}));
		setDataGraph(mergedArray);
		// console.log(mergedArray, 'hfhfh');
	};

	return (
		<div className='pt-4 px-4 lg:px-8'>
			<Typography textStyle='text-[20px] font-medium'>My Dashboard</Typography>
			<div className='w-full  pb-20 lg:pb-8'>
				<div className='w-full flex flex-row justify-end items-start pl-5 pt-4 lg:pt-0'>
					<Button
						icons={PLUS}
						iconClassName='h-[0.9rem] w-3'
						title='Create Case Folder'
						bgColor={'bg-[#13BAED]'}
						onClick={() => navigate('/admin/create-case')}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-3'}
					/>
				</div>
				<Zoom>
					<section className='flex justify-around items-center flex-wrap mt-6'>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-purple-600 bg-purple-100 rounded-full mr-6'>
								<svg
									aria-hidden='true'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									className='h-6 w-6'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z'
									/>
								</svg>
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.all_files}
								</span>
								<span className='block text-gray-500 text-sm'>All case files</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-100 rounded-full mr-6'>
								<svg
									aria-hidden='true'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									className='h-6 w-6'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M13 7h8m0 0v8m0-8l-8 8-4-4-6 6'
									/>
								</svg>
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.fresh_cases}
								</span>
								<span className='block text-gray-500 text-sm'>Fresh cases</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-red-600 bg-red-100 rounded-full mr-6'>
								<svg
									aria-hidden='true'
									fill='none'
									viewBox='0 0 24 24'
									stroke='currentColor'
									className='h-6 w-6'
								>
									<path
										strokeLinecap='round'
										strokeLinejoin='round'
										strokeWidth={2}
										d='M13 17h8m0 0V9m0 8l-8-8-4 4-6-6'
									/>
								</svg>
							</div>
							<div>
								<span className='inline-block text-2xl font-bold'>
									{getanalytics?.case_folder?.not_served}
								</span>
								<span className='block text-gray-500 text-sm'>Not served</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full mr-6'>
								<VscLaw size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.in_court}
								</span>
								<span className='block text-gray-500 text-sm'>In court</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-orange-600 bg-orange-100 rounded-full mr-6'>
								<HiOutlineSpeakerphone size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.mention}
								</span>
								<span className='block text-gray-500 text-sm'>mention</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-lime-600 bg-lime-100 rounded-full mr-6'>
								<CgMenuMotion size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.motion}
								</span>
								<span className='block text-gray-500 text-sm'>Motion</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-cyan-600 bg-cyan-100 rounded-full mr-6'>
								<AiOutlinePartition />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.part_heard}
								</span>
								<span className='block text-gray-500 text-sm'>Part heard</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-yellow-600 bg-yellow-100 rounded-full mr-6'>
								<MdOutlinePendingActions size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.pending}
								</span>
								<span className='block text-gray-500 text-sm'>Pending</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-slate-600 bg-slate-100 rounded-full mr-6'>
								<MdOutlinePhoneCallback size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.recalled}
								</span>
								<span className='block text-gray-500 text-sm'>Recalled</span>
							</div>
						</div>
						<div className='flex items-center p-4 bg-white shadow rounded-lg w-[24%] mb-2'>
							<div className='inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-rose-600 bg-rose-100 rounded-full mr-6'>
								<VscServer size={'1.7em'} />
							</div>
							<div>
								<span className='block text-2xl font-bold'>
									{getanalytics?.case_folder?.served}
								</span>
								<span className='block text-gray-500 text-sm'>Served</span>
							</div>
						</div>
					</section>
				</Zoom>
				{/* <div className='w-full lg:flex justify-between items-center'>
					<div className='w-full lg:w-[46%] p-8 mb-4 lg:mb-0 flex flex-col  lg:flex-row justify-between items-center shadow-widget rounded-lg'>
						<div className='w-full lg:w-[46%] '>
							<Typography
								variant='label'
								textStyle='text-center lg:text-start lg:px-2  mb-2 lg:mb-0'
							>
								Total Cases Filed
							</Typography>
							<Typography textStyle='text-center lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
								{completedCaseStat === 'Year'
									? getanalytics?.total_case_filed_this_year
									: completedCaseStat === 'Month'
									? getanalytics?.total_case_filed_this_month
									: getanalytics?.total_case_filed_this_week}
							</Typography>
							<div className='flex justify-center lg:justify-start items-center'>
								<DropDown
									{...{
										state: completedCaseStat,
										setState: setCompletedCaseStat,
									}}
								/>
							</div>
						</div>

						<div className='w-full lg:w-[48%] h-52'>
							<div className=' w-full'>
								<PieCharts />
							</div>
						</div>
					</div>
					<div className='w-full lg:w-[46%] p-8 mb-4 lg:mb-0 flex flex-col  lg:flex-row justify-between items-center shadow-widget rounded-lg'>
						<div className='w-full lg:w-[46%] '>
							<Typography
								variant='label'
								textStyle='text-center lg:text-start lg:px-2  mb-2 lg:mb-0'
							>
								On-going Cases
							</Typography>
							<Typography textStyle='text-center lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
								{ongoinCaseStat === 'Year'
									? getanalytics?.total_case_outgoing_this_year
									: ongoinCaseStat === 'Month'
									? getanalytics?.total_case_outgoing_this_month
									: getanalytics?.total_case_outgoing_this_week}
							</Typography>

							<DropDown {...{ state: ongoinCaseStat, setState: setOngoinCaseStat }} />
						</div>

						<div className='w-full lg:w-[48%] h-52'>
							<div className=' w-full'>
								<PieCharts />
							</div>
						</div>
					</div>
				</div> */}

				<div className='w-full  p-8 my-8  shadow-widget rounded-lg'>
					<Typography variant='label'>Total Payments</Typography>
					<Typography textStyle='mb-2 lg:mb-0 font-bold text-[44px]'>
						{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(
							getanalytics?.payment_sum ? getanalytics?.payment_sum : 0
						)}
					</Typography>
				</div>

				<div className='w-[75%] h-[250px]  mt-6 bg-[#0FC1E808] rounded-lg py-4 mx-auto'>
					<p className='text-brand_indigo text-center font-bold text-xl mb-4'>Payment Charts</p>
					<div className='w-[75%] h-[250px] flex justify-center items-center mt-6 bg-[#0FC1E808] rounded-lg py-4 mx-auto'>
						{dataGraph && (
							<ResponsiveContainer width='100%' height='100%'>
								<BarChart
									// width={350}
									height={200}
									data={dataGraph}
									margin={{
										top: 5,
										right: 30,
										left: 20,
										bottom: 5,
									}}
								>
									<CartesianGrid vertical={false} strokeDasharray='3 3' />
									<XAxis dataKey='name' style={{ fontSize: 12 }} />
									<YAxis style={{ fontSize: 12 }} />

									<Tooltip />
									{/* <Legend /> */}

									<Bar dataKey='pv' fill='url(#colorGradient)' minPointSize={5}></Bar>

									<defs>
										<linearGradient id='colorGradient' x1='0' y1='0' x2='0' y2='1'>
											<stop offset='5%' stopColor='#13BAED' stopOpacity={0.8} />
											<stop offset='95%' stopColor='#000' stopOpacity={0.9} />
										</linearGradient>
									</defs>
								</BarChart>
							</ResponsiveContainer>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;

import React, { useState } from 'react';
import { partyInfoTypes } from '../../types';
import { Zoom } from 'react-reveal';
import Modal from '../UI/modal';
import Typography from '../Typography';
import { FormInput } from '../form/Input';
import Button from '../button';
import { FLAG, PLUS } from '../../assets';
import { PARTY_COLUMNS_EXISTING } from '../table/columns';
import { toast } from 'react-hot-toast';
import Api from '../../config';
import { getJSONheader } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setReload, setToggle, setToggle_2 } from '../../states/slices/partyInfo.slice';
import { useNavigate } from 'react-router-dom';

interface PartyInformationTypes {
	data?: partyInfoTypes;
	close: () => void;
	open: boolean;
	case: any;
	isNewParty?: any;
}
function EditPartyInfo(props: PartyInformationTypes) {
	const [required, setRequired] = useState('');
	const [partyValue, setPartyValue] = useState<partyInfoTypes>({
		first_name: props.data?.first_name ?? '',
		last_name: props.data?.last_name ?? '',
		other_name: props.data?.other_name ?? '',
		phone_number: props.data?.phone_number ?? '',
		email: props.data?.email ?? '',
		title: props.data?.title ?? '',
		address: props.data?.address ?? '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const handleAddExistingParty = (e: any) => {
		e.preventDefault();

		let x = 5;
		if (
			!partyValue.first_name &&
			!partyValue.title &&
			!partyValue.last_name &&
			!partyValue?.address
		) {
			setRequired('required');
		} else {
			setLoading(true);
			Api.post(`lawyer/case-folders/${props.case?.id}/add-party`, partyValue, getJSONheader())
				.then((res) => {
					// console.log(res);
					setPartyValue({
						...partyValue,
						first_name: '',
						last_name: '',
						other_name: '',
						phone_number: '',
						email: '',

						address: '',
					});
					setRequired('');
					setLoading(false);
					dispatch(setReload(x++));
					dispatch(setToggle(false));
					// dispatch(setToggle_2(false));
					toast.success(res.data.message, {
						duration: 3000,
					});
				})
				.catch((err) => {
					setLoading(false);
					toast.error(err.response.data.message, {
						duration: 3000,
					});
				});
		}
	};
	const handleAddNewParty = (e: any) => {
		e.preventDefault();
		setLoading(true);
		let x = 5;
		if (
			!partyValue.first_name &&
			!partyValue.title &&
			!partyValue.last_name &&
			!partyValue?.address
		) {
			setRequired('required');
		} else {
			Api.post(`lawyer/case-folders/${props.case?.id}/add-party`, partyValue, getJSONheader())
				.then((res) => {
					// console.log(res);
					setPartyValue({
						...partyValue,
						first_name: '',
						last_name: '',
						other_name: '',
						phone_number: '',
						email: '',

						address: '',
					});
					setRequired('');
					setLoading(false);
					dispatch(setReload(x++));
					navigate('/lawyer/my-files');
					// dispatch(setToggle(false));
					dispatch(setToggle_2(false));
					toast.success(res.data.message, {
						duration: 3000,
					});
				})
				.catch((err) => {
					setLoading(false);
					toast.error(err.response.data.message, {
						duration: 3000,
					});
				});
		}
	};

	// console.log(props.data);

	return (
		<Modal open={props.open} close={props.close} maxWidth='md'>
			<>
				<div className='px-8'>
					<Zoom bottom>
						<div className='px-8  pb-12'>
							<Typography variant='h3'>Party Information</Typography>
							<form className=''>
								<div className='flex justify-between items-center flex-wrap'>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2 lg:mb-6'>
										<label htmlFor='email' className='text-sm'>
											Title <small className='text-red-500'>*</small>
										</label>
										<select
											defaultValue={partyValue?.title}
											onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
												const selectedOption = event.target.value;
												setPartyValue({
													...partyValue,
													title: selectedOption,
												});
											}}
											className={`mb-2 appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
										>
											<option value='' className='text-gray-200'>
												Select an option
											</option>
											<option value='CLAIMANT'>Claimant</option>
											<option value='DEFENDANT'>Defendant</option>
											<option value='APPLICANT'>Applicant</option>
											<option value='RESPONDENT'>Respondent</option>
											<option value='APPELLANT'>Appellant</option>
										</select>
										<small className='text-red-500'>{required}</small>
									</div>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2'>
										<label htmlFor='email' className='text-sm'>
											First name
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your first name'
											defaultValue={partyValue.first_name}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													first_name: text.target.value,
												});
											}}
										/>
										<small className='text-red-500'>{required}</small>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='lastname' className='text-sm'>
											Last name
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your last name'
											defaultValue={partyValue.last_name}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													last_name: text.target.value,
												});
											}}
										/>
										<small className='text-red-500'>{required}</small>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Other name
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your other name'
											defaultValue={partyValue.other_name}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													other_name: text.target.value,
												});
											}}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Email name
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your email'
											defaultValue={partyValue.email}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													email: text.target.value,
												});
											}}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Phone / Whatsapp Number
										</label>
										<FormInput
											isDefault={true}
											type='text'
											placeholder='Enter your phone number or whatsapp number'
											defaultValue={partyValue.phone_number}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													phone_number: text.target.value,
												});
											}}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Contact Address
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your contact address'
											defaultValue={partyValue.address}
											onChange={(text: React.ChangeEvent<HTMLInputElement>) => {
												setPartyValue({
													...partyValue,
													address: text.target.value,
												});
											}}
										/>
										<small className='text-red-500'>{required}</small>
									</div>
								</div>
								<div className='flex justify-end items-center'>
									<Button
										icons={PLUS}
										iconClassName='h-[0.9rem] w-3'
										isLoading={loading}
										title={props?.data ? 'Edit Party Info' : 'Add Party Info'}
										bgColor={'bg-brand_indigo'}
										onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
											props.isNewParty ? handleAddNewParty(e) : handleAddExistingParty(e)
										}
										hoverColor={''}
										textColor={'text-white'}
										className={'py-4 rounded-lg'}
									/>
								</div>
							</form>
						</div>
					</Zoom>
				</div>
			</>
		</Modal>
	);
}

export default EditPartyInfo;

import {
	CreateCases,
	Dashboard,
	ManageUsers,
	MyFiles,
	Notification,
	Payment,
	Profile,
} from '../features/admin';
import ViewCases from '../features/admin/view-cases';
import ViewUsers from '../features/admin/view-users';

export const admin_Routes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Dashboard,
		path: 'admin/dashboard',
	},
	{
		id: '2',
		title: 'Case Folders',
		component: MyFiles,
		path: 'admin/case-folders',
	},
	{
		id: '3',
		title: 'Notification',
		component: Notification,
		path: 'admin/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Profile,
		path: 'admin/profile',
	},
	{
		id: '5',
		title: 'Payment',
		component: Payment,
		path: 'admin/payments',
	},

	{
		id: '6',
		title: 'Create Case',
		component: CreateCases,
		path: '/admin/create-case',
	},
	{
		id: '9',
		title: 'Create Case',
		component: ViewCases,
		path: '/admin/view-case',
	},
	{
		id: '7',
		title: 'Manage Users',
		component: ManageUsers,
		path: 'admin/manage-users',
	},
	{
		id: '8',
		title: 'Manage Users',
		component: ViewUsers,
		path: 'admin/view-user',
	},
];

import { configureStore, Reducer, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
// api reducers
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './slices/authReducer';
import { formReducer } from './slices/partyInfo.slice';
import { casesApi } from '../services/apis/cases';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
};

const appReducer = combineReducers({
	// all reducers here
	auths: authReducer,
	form: formReducer,
	[casesApi.reducerPath]: casesApi.reducer,
});

const persistedAuthReducer = persistReducer(persistConfig, appReducer);
const rootReducer: Reducer = (state, action) => {
	if (action.type === 'auths/reset') {
		storage.removeItem('persist:root');
		// storage.removeItem("persist:message");
		state = {};
	}
	return persistedAuthReducer(state, action);
};

export const Store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(
			//all concat here
			casesApi.middleware
		),
});

setupListeners(Store.dispatch);
export const persistor = persistStore(Store);

export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

import Judges from '../features/presiding-magistrate/judges';
import Open from '../features/presiding-magistrate/open-judges';
import ProcessPayment from '../features/presiding-magistrate/proceed-payment';
import {
	Presiding_Magistrate_ConcludedCases,
	Presiding_Magistrate_Dashboard,
	Presiding_Magistrate_MyFiles,
	Presiding_Magistrate_Notification,
	Presiding_Magistrate_Profile,
} from '../features/presiding-magistrate';

export const presiding_magistrate_Routes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Presiding_Magistrate_Dashboard,
		path: 'presiding-magistrate',
	},
	{
		id: '2',
		title: 'Case Folders',
		component: Presiding_Magistrate_MyFiles,
		path: 'presiding-magistrate/case-folders',
	},
	{
		id: '3',
		title: 'Notification',
		component: Presiding_Magistrate_Notification,
		path: 'presiding-magistrate/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Presiding_Magistrate_Profile,
		path: 'presiding-magistrate/profile',
	},

	{
		id: '6',
		title: 'Concluded Cases',
		component: Presiding_Magistrate_ConcludedCases,
		path: 'presiding-magistrate/concluded-cases',
	},
	{
		id: '7',
		title: 'process',
		component: ProcessPayment,
		path: 'presiding-magistrate/process',
	},
	{
		id: '8',
		title: 'Magistrates',
		component: Judges,
		path: 'presiding-magistrate/magistrates',
	},
	{
		id: '9',
		title: 'Open',
		component: Open,
		path: 'presiding-magistrate/judges/open',
	},
];

import React from 'react';
import logo from '../../../assets/icons/logo.svg';
import twitter from '../../../assets/icons/twitter.png';
import instagram from '../../../assets/icons/instagram.png';
import facebook from '../../../assets/icons/facebook.svg';

const Footer = () => {
	const date = new Date();

	return (
		<div className='bg-[#262553] text-[#fff] py-[5%] md:py-[2%] font-luxia'>
			<div className='lg:flex lg:justify-between lg:items-center px-4 lg:px-[7%] '>
				<div className='lg:flex lg:justify-between lg:text-left lg:w-[60%]'>
					<div className='my-[5%] '>
						<h4 className='font-extrabold'>INFORMATION</h4>
						<ul className='text-[12px] text-[#8E8E93] flex flex-col h-[10vh] justify-evenly md:h-[15vh] '>
							<a href='/#faqs'>
								<li>FAQ </li>
							</a>
							<a href='/#about'>
								<li>ABOUT US </li>
							</a>
						</ul>
					</div>
					<div className='my-[5%]'>
						<h4 className='font-extrabold'>CONTACTS</h4>
						<ul className='text-[12px] text-[#8E8E93] font-bold flex flex-col h-[10vh] justify-evenly md:h-[15vh]'>
							<li>08180999933</li>

							<li>08069579433</li>
							<li>09043305205</li>
							<li>08118664838</li>
						</ul>
					</div>
					<div className='my-[5%]'>
						<h4 className='font-extrabold'>T&C'S</h4>
						<ul className='text-[12px] text-[#8E8E93] flex flex-col h-[7vh] justify-evenly md:h-[14vh]'>
							<li>PRIVACY POLICY</li>
							<li>TERMS AND CONDITIONS</li>
						</ul>
					</div>
				</div>
				<div className='flex flex-col items-center lg:pr-[3%]'>
					<img src={logo} alt='logo' className='h-[15vh]' />
					<div className='flex justify-between w-[40%] my-[5%] md:w-[20%] lg:w-[180%] lg:mt-[35%] '>
						<a target='blank' href='https://instagram.com/riversstatefamilycourt'>
							<img src={instagram} alt='instagram' />
						</a>

						<a target='blank' href='https://twitter.com/familycourtRS'>
							<img src={twitter} alt='twitter' />
						</a>
						<a target='blank' href='https://facebook.com/riversfamilycourt'>
							<img src={facebook} alt='facebook' />
						</a>
					</div>
				</div>
			</div>
			<div className='lg:px-[5%]'>
				<hr />
				{/* @ts-ignore */}
				<p className='w-[80%] mx-[auto] my-[3%] text-sm lg:my-[1%] lg:text-left lg:mx-[0%]'>
					Copyright @ {date.getFullYear()} Designed by Lextech. All rights reserved
				</p>
			</div>
		</div>
	);
};

export default Footer;

import React, { useEffect, useState } from 'react';
import Typography from '../../../components/Typography';
import { selectUser } from '../../../states/slices/authReducer';
import { useAppSelector } from '../../../hooks';

import Api from '../../../config';
import PaginatedTable from '../../../components/table/Paginated-table';
import { getJSONheader } from '../../../utils';
import { ADMIN_PAYMENT_COLUMNS } from '../../../components/table/columns/admin';
import { useGetAnalyticsQuery } from '../../../services/apis/cases';

function Payment() {
	// const [active, setActive] = useState(0);
	const user = useAppSelector(selectUser);
	// const [open, setOpen] = useState(false);

	const [data, setData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setLoading] = useState(false);

	const fetchData = async (page: any, pageSize: any) => {
		setLoading(true);
		try {
			const response = await Api.get(
				`registra1/payments/requests?page=${page + 1}`,
				getJSONheader()
			);
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
			setTotalPages(meta.last_page);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	return (
		<div className='pt-4 px-2 lg:px-8 pb-36 lg:pb-0'>
			<Typography textStyle='text-[20px] font-medium'>Payments</Typography>
			<div className='w-full  pb-20 lg:pb-8'>
				<div className='mt-8 lg:flex justify-between items-end'>
					<div className=' w-full lg:mb-0'>
						{data?.length < 1 ? (
							<p className='text-center'>No payment information available at the moment</p>
						) : (
							<div className=' border-b  border-[#F0F0F0] rounded-b-xl'>
								<PaginatedTable
									columns={ADMIN_PAYMENT_COLUMNS}
									data={data}
									totalPages={totalPages}
									fetchData={fetchData}
									isLoading={isLoading}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Payment;

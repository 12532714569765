import Api from '../config';

export const forgotPassword = async (data: any) => {
	const response = await Api.post('/auth/forgot-password', data);
	return response.data;
};
export const handleHearing = async (data: any) => {
	const response = await Api.post('/judge-magistrate/cause-lists', data);
	return response.data;
};
export const getHearing = async () => {
	const response = await Api.get('/judge-magistrate/cause-lists');
	return response.data;
};

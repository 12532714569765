import { Zoom } from 'react-reveal';
import Modal from '../UI/modal';
import Typography from '../Typography';
import { FormInput } from '../form/Input';

interface modalTypes {
	close: () => void;
	open: boolean;
}
function FillingFee(props: modalTypes) {
	return (
		<Modal open={props.open} close={props.close} maxWidth='md'>
			<div className='px-6 pb-6'>
				<h2 className='font-bold mb-4'>Filing Fees</h2>
				<div className='container   text-sm  overflow-y-auto grid grid-cols-1 gap-[3rem]'>
					<table className='table-auto'>
						<thead>
							<tr>
								<b>1. Originating Process / Applications:</b>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='border px-4 py-2'>(a) Originating Application</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 500.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(b) Oath</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(c) Filing</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 300.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(d) Exhibit (per exhibit)</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 100.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(e) Service per distance (per kilometer)</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 50.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(f) Sealing</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 400.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(g) Statement of Truth/Affidavit of Fact</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
						</tbody>
					</table>

					<table className='table-auto'>
						<thead>
							<tr>
								<b>2. Motions:</b>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='border px-4 py-2'>(a) Originating Application</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 500.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(b) Oath</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(c) Filing</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 300.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(d) Exhibit (per exhibit)</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 100.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(e) Service per distance (per kilometer)</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 50.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(f) Sealing</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 400.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(g) Statement of Truth/Affidavit of Fact</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
						</tbody>
					</table>

					<table className='table-auto'>
						<thead>
							<tr>
								<b>3. Other fees::</b>
							</tr>
						</thead>

						<tbody>
							<tr>
								<td className='border px-4 py-2'>
									(a) For any other relief or assistance not specially provided for, such as
									Applications, Judgments, Orders, Security bonds, Warrants and Writs
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1000.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(b) On filing affidavit</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 100.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(c) On filing security bonds/order of recognizance
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(d) For issuance of writ of Habeas Corpus/Writ of Mandamus
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1000.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(e) For an inquiry of a Court Officer where so ordered: for each sitting
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 300.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(f) For filing of discoveries where so ordered by the Court
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 400.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(g) For certifying a true copy per folio of 100 words or parts thereof
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 10.00</td>
							</tr>

							<tr>
								<td className='border px-4 py-2'>
									(h) For certifying a record of proceeding per folio
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 50.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(i) For certifying a true copy per folio of 100 words or parts thereof
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 10.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(j) On transfer of a foreign judgment/order of adoption
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1000.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(k) For certification of service of foreign process (where not disallowed by
									convention)
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1000.00</td>
							</tr>

							<tr>
								<td className='border px-4 py-2'>(l) Enrolment of Order</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1000.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(m) Registration of Certification of Judgment of any Court
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 500.00</td>
							</tr>
						</tbody>
					</table>

					<table className='table-auto'>
						<thead>
							<tr>
								<b>4. Allowances to Witnesses:</b>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='border px-4 py-2'>
									Expert witnesses, child psychologists, bank officers / managers, investigators,
									medical personnel and any officer of the public service whose salary is not less
									than ₦18,000
								</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 1,000.00</td>
							</tr>
						</tbody>
					</table>

					<table className='table-auto'>
						<thead>
							<tr>
								<b>5. Translations (Documents)::</b>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className='border px-4 py-2'>(a) For every folio of 100 words</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(b) Attestation to translation</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>
									(c) Application for emergency orders by public officers
								</td>
								<td className='border px-4 py-2 w-[20%]'> gratis</td>
							</tr>
							<tr>
								<td className='border px-4 py-2'>(d) Administrative fee and filing charges</td>
								<td className='border px-4 py-2 w-[20%]'>₦ 200.00</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</Modal>
	);
}

export default FillingFee;

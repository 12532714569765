import { Link, useNavigate } from "react-router-dom";
import {
  admin_sidebar,
  bailiff_sidebar,
  judge_sidebar,
  lawyer_sidebar,
  magistrate_sidebar,
  nonlawyer_sidebar,
  presiding_magistrate_sidebar,
  presiding_registrar_sidebar,
  registrar_1_sidebar,
  registrar_2_sidebar,
  SidebarDataTypes,
} from "./SidebarData";
import { Transition } from "@headlessui/react";
import { useLocation } from "react-router-dom";
import Typography from "../Typography";
import { LOGOUT, USER_ICON } from "../../assets";
import Button from "../button";
import { useAppDispatch, useAppSelector, useMediaQuery } from "../../hooks";
import AuthService from "../../services/auth";
import { selectRole, selectUser } from "../../states/slices/authReducer";
import {
  resetDoc,
  resetValue,
  updateCase,
} from "../../states/slices/partyInfo.slice";

interface sidebarProps {
  open: boolean;
  onClose: () => void;
  setOpen: any;
}

export const Sidebar = (props: sidebarProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const role = useAppSelector(selectRole);
  const dispatch = useAppDispatch();

  return (
    <Transition
      className={`flex-none h-full w-60 lg:w-[18vw] fixed lg:static z-30 lg:z-0`}
      as={"div"}
      show={props.open}
      enter='transition-all ease-in duration-500'
      enterFrom='transform -translate-x-full'
      enterTo='transform -translate-x-0'
      leave='transition-all ease-out duration-500'
      leaveFrom='transform -translate-x-0'
      leaveTo='transform -translate-x-full'
    >
      <div className='h-full bg-deep-surface  relative'>
        <div
          className={`${
            role !== "LAWYER" ? "h-[11vh]" : "h-[15vh]"
          } w-full  px-2 flex flex-wrap justify-around items-center bg-white`}
        >
          <Link
            to={
              role === "LAWYER"
                ? "/lawyer/profile"
                : role === "REGISTRAR 1"
                ? "/registrar-1/profile"
                : role === "REGISTRAR 2"
                ? "/registrar-2/profile"
                : role === "PRESIDING JUDGE"
                ? "/presiding-judge/profile"
                : role === "PRESIDING MAGISTRATE"
                ? "/presiding-magistrate/profile"
                : role === "JUDGE"
                ? "/judge/profile"
                : role === "ADMIN"
                ? "/admin/profile"
                : role === "NON LAWYER"
                ? "/non-lawyer/profile"
                : "/"
            }
          >
            <img
              src={user?.profile_photo ? user?.profile_photo : USER_ICON}
              alt='user'
              className='w-12 h-12 border-4 border-brand_blue rounded-full'
            />
          </Link>
          <div className='ml-2'>
            <Typography variant='label'>
              {role === "ADMIN"
                ? "Hello Admin"
                : `${user?.first_name} 	${user?.last_name}`}
            </Typography>
            <Typography variant='caption' textStyle='text-gray-500'>
              {role}
            </Typography>
          </div>
        </div>

        <div className='flex flex-col justify-between h-[89vh] pb-10 pl-5 pt-12 bg-brand_indigo'>
          <div className=''>
            <ul>
              <Tabs
                {...{
                  data:
                    role === "LAWYER"
                      ? lawyer_sidebar
                      : role === "REGISTRAR 1"
                      ? registrar_1_sidebar
                      : role === "REGISTRAR 2"
                      ? registrar_2_sidebar
                      : role === "PRESIDING JUDGE"
                      ? presiding_registrar_sidebar
                      : role === "PRESIDING MAGISTRATE"
                      ? presiding_magistrate_sidebar
                      : role === "MAGISTRATE"
                      ? magistrate_sidebar
                      : role === "JUDGE"
                      ? judge_sidebar
                      : role === "ADMIN"
                      ? admin_sidebar
                      : role === "BAILIFF"
                      ? bailiff_sidebar
                      : role === "NON LAWYER"
                      ? nonlawyer_sidebar
                      : null,
                  className: "hover:font-medium",
                  onClose: props.onClose,
                  active: (item: SidebarDataTypes) =>
                    location.pathname === item.path,
                }}
              />
            </ul>
          </div>

          <div className='flex flex-col justify-start items-start pl-5'>
            <Button
              icons={LOGOUT}
              iconClassName='h-[0.9rem] w-3'
              title='Logout'
              bgColor={"bg-transparent"}
              onClick={() => {
                dispatch(resetDoc());
                dispatch(resetValue());
                dispatch(updateCase(null));
                AuthService.logout();
                navigate("/login");
              }}
              hoverColor={""}
              textColor={"text-black"}
              className={"border border-black bg-white "}
            />
          </div>
        </div>
      </div>
    </Transition>
  );
};

interface tabsProps {
  data: SidebarDataTypes[] | null;
  active: any;
  className?: string;
  containerClassName?: string;
  onClose: () => void;
}

const Tabs = ({
  data,
  active,
  className,
  containerClassName,
  onClose,
}: tabsProps) => {
  const isMobileView = useMediaQuery("(max-width: 640px)");
  const isTabletView = useMediaQuery("(max-width: 840px)");

  return (
    <>
      {data !== null &&
        data.map((item) => (
          <li
            onClick={isMobileView || isTabletView ? onClose : () => {}}
            key={item.id}
            className={`${containerClassName} mb-2`}
          >
            <Link to={item.path} className=' '>
              <div
                className={` flex  w-full items-center py-2 px-5 ${className} ${
                  active(item)
                    ? "border-r-8 border-tertiary text-brand_blue font-bold"
                    : "font-medium text-white"
                }`}
              >
                {item.icon && <item.icon className='h-5  mr-4' />}
                <Typography textStyle='text-base'>{item.title}</Typography>
              </div>
            </Link>
          </li>
        ))}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useTable, usePagination } from 'react-table';
import { getJSONheader } from '../../utils';
import { Box, CircularProgress, Skeleton, Stack } from '@mui/material';
// import Skeleton from 'react-loading-skeleton';

const PaginatedTable = ({ columns, data, fetchData, totalPages, isLoading }) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		state: { pageIndex, pageSize },
		gotoPage,
		previousPage,
		nextPage,
		setPageSize,
		canPreviousPage,
		canNextPage,
		pageOptions,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageIndex: 0 },
			manualPagination: true,
			pageCount: totalPages,
		},
		usePagination
	);

	// console.log('pageIndex', pageIndex);

	useEffect(() => {
		fetchData(pageIndex, pageSize); // Pass the current pageIndex and pageSize to the fetchData function
	}, [pageIndex, pageSize]);

	return (
		<>
			{!data ? (
				<Box sx={{ pt: 0.5 }}>
					<Skeleton width='60%' />
					<Skeleton />
					<Skeleton variant='rectangular' width={'100%'} height={100} />
				</Box>
			) : (
				<div className='w-full overflow-scroll bg-transparent'>
					<table className='w-full table  px-8' {...getTableProps()}>
						<thead className='w-full '>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th
											className=' whitespace-nowrap text-left pl-6 pr-3 pt-3 text-sm font-semibold'
											{...column.getHeaderProps()}
										>
											{column.render('Header')}
										</th>
									))}
								</tr>
							))}
						</thead>

						<tbody className='px-8' {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr {...row.getRowProps()}>
										{row.cells.map((cell) => (
											<td className='pl-6 pr-3 py-4 border-0  text-xs' {...cell.getCellProps()}>
												{cell.render('Cell')}
											</td>
										))}
									</tr>
								);
							})}
						</tbody>
					</table>

					<div className='flex w-full justify-evenly items-center text-xs border rounded-sm py-1 border-[#F0F0F0]'>
						<button className='pl-4' onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
							{'Go to first '}
						</button>
						<button onClick={() => previousPage()} disabled={!canPreviousPage}>
							{' < Prev'}
						</button>
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{' '}
						</span>
						<button onClick={() => nextPage()} disabled={!canNextPage}>
							{' Next >'}
						</button>
						<button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
							{'Go to last'}
						</button>
						<span>
							| Go to page:{' '}
							<input
								type='number'
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									gotoPage(page);
								}}
								style={{ width: '50px' }}
							/>
						</span>
					</div>
				</div>
			)}
		</>
	);
};

export default PaginatedTable;

import React from 'react';
import comp from '../../../assets/icons/computer.svg';
import file from '../../../assets/icons/file.svg';
import pay from '../../../assets/icons/pay.svg';
import page from '../../../assets/icons/page.svg';
import Typography from '../../../components/Typography';
import { Fade } from 'react-reveal';

const How = () => {
	return (
		<div className='md:min-h-[600px] px-3 lg:px-20  flex flex-col lg:justify-center py-[3rem] bg[#F8F8FE]'>
			<Typography variant='h3' textStyle='text-brand_blue mx-auto lg:text-3xl mb-8 text-center'>
				How it Works
			</Typography>
			<div className='flex flex-col justify-center lg:flex-row lg:pt-20'>
				<Fade left>
					<div className='flex flex-col items-center mt-[8%]  lg:mt-[0%]'>
						<img src={comp} alt='' className='lg:h-[10vh]' />
						<div className='flex flex-col'>
							<h4 className=' text-[#3d3b5] mx-auto lg:my-[5%] font-semibold'>eFiling</h4>
							<p className='text-[#1a1a1b] w-[80%] mx-auto text-sm text-center mt-[5%] lg:mt-0'>
								Create an account as a user (Lawyer/non-Lawyer) and upload processes
							</p>
						</div>
					</div>
					<div className='flex flex-col items-center mt-[8%] lg:mt-[0%]'>
						<img src={file} alt='' className='lg:h-[10vh]' />
						<div className='flex flex-col'>
							<h4 className='text-[#3d3b5] mx-auto lg:my-[5%] font-semibold'>eAssessment</h4>
							<p className='text-[#1a1a1b] w-[80%] mx-auto text-sm text-center mt-[5%] lg:mt-[0%]'>
								Registrar makes assessment of filed processes and sends payment advice.
							</p>
						</div>
					</div>
				</Fade>

				<Fade right>
					<div className='flex flex-col items-center text-center mt-[8%] lg:mt-[0%]'>
						<img src={pay} alt='' className='lg:h-[10vh]' />
						<div className='flex flex-col'>
							<h4 className='text-[#3d3b5] mx-auto lg:my-[5%] font-semibold'>ePayment</h4>
							<p className='text-[#1a1a1b] w-[80%] text-sm mx-auto mt-[5%] lg:mt-[0%]'>
								User makes payment.
							</p>
						</div>
					</div>
					<div className='flex flex-col items-center mt-[8%] lg:mt-[0%]'>
						<img src={page} alt='' className='lg:h-[10vh]' />
						<div className='flex flex-col'>
							<h4 className='text-[#3d3b5] mx-auto lg:my-[5%] font-semibold'>eAssignment</h4>
							<p className='text-[#1a1a1b] text-center text-sm w-[80%] mx-auto mt-[5%] lg:mt-[0%]'>
								User receives notification when case is assigned to a Judge or Magistrate .
							</p>
						</div>
					</div>
				</Fade>
			</div>
		</div>
	);
};

export default How;

import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import background from "../../assets/icons/BackgroundImage.png";
import { Formik } from "formik";
import Api from "../../config";
import { toast } from "react-hot-toast";
import { useAppSelector } from "../../hooks";
import {
  selectRefreshToken,
  selectUser,
  setRefreshToken,
  setToken,
} from "../../states/slices/authReducer";
import Input from "../../components/form/Input";
import Button from "../../components/button";
import { LOGO } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "../../states/store";

function PasswordReset() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);

  const token = useSelector(selectRefreshToken);

  return (
    <div className='w-screen  h-screen relative'>
      <div className='flex justify-between pt-12 md:pl-12 md:-mb-24'>
        <Link to='/'>
          <img
            src={LOGO}
            className='h-full w-full rounded-full'
            alt='...logo'
          />
        </Link>
      </div>
      <div className='flex w-full h-full justify-center items-center'>
        <img
          src={background}
          alt='background'
          className='absolute z-[0] ml-[-8%] h-1/2 w-[100%] top-[25%] '
        />
        <div className='bg-[#fff]  shadow-lg shadow-indigo-500/40 relative z-[2] '></div>
        <div className='bg-[#fff] w-2/3 shadow-lg shadow-indigo-500/40 relative z-[2] h-[60vh] p-8 flex flex-col justify-center '>
          <h3 className='text-[#262553] font-bold text-[1.7rem]'>
            Please enter the One-Time Password to verify your account
          </h3>
          <p>
            One-Time password has been sent to{" "}
            <span className='text-brand_blue'>{user?.email}</span>{" "}
            <address></address>
          </p>
          <Formik
            initialValues={{
              token: "",
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // console.log(values, 'cccc');
              // setSubmitting(true);
              setIsLoading(true);
              const formdata = {
                token: values.token,
                type: "REGISTER",
              };

              Api.post("/profile/update", formdata, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
                .then(({ data }) => {
                  // Redirect or perform any other action upon successful login
                  toast.success(data.message, {
                    duration: 3000, // Duration for which the toast is displayed (in milliseconds)
                  });
                  setIsLoading(false);
                  resetForm();

                  navigate("/login");
                })
                .catch((error: any) => {
                  // console.log(error.response.data.message);
                  toast.error(error?.response?.data?.message, {
                    duration: 3000,
                  });
                  setIsLoading(false);
                });
            }}
          >
            {({
              handleSubmit,
              isSubmitting,
              values,
              handleChange,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className='w-1/2'>
                  <label className='mb-[2%] '>Old Password</label>
                  <Input
                    type='password'
                    placeholder='password'
                    isPassword={true}
                    name={"old_password"}
                  />
                </div>
                <div className='w-1/2'>
                  <label className='mb-[2%] '>New Password</label>
                  <Input
                    type='password'
                    placeholder='password'
                    isPassword={true}
                    name={"new_password"}
                  />
                </div>
                <div className='w-44'>
                  <Button
                    isLoading={isLoading}
                    title={"Reset password"}
                    type={"button"}
                    onClick={() => handleSubmit()}
                    bgColor={"bg-brand_indigo"}
                    textColor={"text-white"}
                    className={" my-2  lg:my-0  py-4 w-full rounded-2xl"}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;

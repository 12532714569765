import Typography from '../../../components/Typography';
import { DONE_ALL, FOLDER, PENDING } from '../../../assets';
import { TableComponent } from '../../../components/table/Table';
import { CASE_REGISTRA_COLUMNS } from '../../../components/table/columns';
import { useEffect, useState } from 'react';
import { useGetAnalyticsQuery, useGetCaseFoldersQuery } from '../../../services/apis/cases';
import DropDown from '../../../components/dropdownBtn';
import { Roll } from 'react-reveal';
import { Box, Skeleton } from '@mui/material';
import Api from '../../../config';
import { getJSONheader } from '../../../utils';

function Dashboard() {
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [completedCaseStat, setCompletedCaseStat] = useState('Year');
	const [ongoinCaseStat, setOngoinCaseStat] = useState('Year');
	const [data, setData] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const { getanalytics, isLoadingAnalytics, refetch } = useGetAnalyticsQuery('registra1', {
		selectFromResult: ({ data, isLoading, isError }) => ({
			getanalytics: data?.data,
			isLoadingAnalytics: isLoading,
		}),
	});
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await Api.get(`/registra1/case-folders`, getJSONheader());
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};
	useEffect(() => {
		refetch();
		fetchData();
	}, []);
	// useEffect(() => {
	// 	fetchData();
	// }, []);

	return (
		<div className='pt-4 px-2 lg:px-8'>
			<Typography textStyle='text-[20px] font-medium mb-3'>My Dashboard</Typography>
			<div className='w-full  pb-20 lg:pb-8'>
				<div className='w-full lg:flex justify-between items-center relative'>
					<div className='relative lg:w-[46%] flex justify-center items-center h-full'>
						<div className='absolute -inset-1 animate-pulse bg-gradient-to-r from-blue-600 to-brand_blue rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200'></div>

						<div className='w-[96%] h-[96%] relative  bg-gradient-to- bg-white  p-8 mb-4 lg:mb-0 flex flex-row justify-between items-center shadow-widget rounded-lg'>
							<div className='w-[46%] flex lg:block  justify-center items-center flex-col'>
								<Typography
									variant='label'
									textStyle='text-center lg:text-start  text-black  lg:px-2  mb-2 lg:mb-0'
								>
									Pending
								</Typography>
								<Typography textStyle='text-center  text-black  lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
									{completedCaseStat === 'Year'
										? getanalytics?.total_case_pending_this_year
										: completedCaseStat === 'Month'
										? getanalytics?.total_case_pending_this_month
										: getanalytics?.total_case_pending_this_week}
								</Typography>

								<DropDown
									{...{
										state: completedCaseStat,
										setState: setCompletedCaseStat,
									}}
								/>
							</div>
							<Roll left>
								<div className='w-[38%] flex justify-end '>
									<img src={PENDING} className='h-20 ' />
								</div>
							</Roll>
						</div>
					</div>
					<div className='relative lg:w-[46%] flex justify-center items-center h-full'>
						<div className='absolute -inset-1 animate-pulse bg-gradient-to-r from-blue-600 to-brand_blue rounded-lg blur opacity-25 group-hover:opacity-100 transition duration-1000 group-hover:duration-200'></div>
						<div className='w-[96%] h-[96%] relative bg-gradient-to-r from-white via-blue-100 to-white p-8 mb-4 lg:mb-0 flex flex-row justify-between items-center shadow-widget rounded-lg'>
							<div className='w-[46%] flex lg:block  justify-center items-center flex-col'>
								<Typography
									variant='label'
									textStyle='text-center lg:text-start lg:px-2 text-black  mb-2 lg:mb-0'
								>
									Treated
								</Typography>
								<Typography textStyle='text-center  text-black lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
									{ongoinCaseStat === 'Year'
										? getanalytics?.total_case_treated_this_year
										: ongoinCaseStat === 'Month'
										? getanalytics?.total_case_treated_this_month
										: getanalytics?.total_case_treated_this_week}
								</Typography>

								<DropDown {...{ state: ongoinCaseStat, setState: setOngoinCaseStat }} />
							</div>
							<Roll right>
								<div className='w-[38%] flex justify-end'>
									<img src={DONE_ALL} className='h-20 ' />
								</div>
							</Roll>
						</div>
					</div>
				</div>

				<div className='mt-8 lg:flex justify-between items-end'>
					<div className=' w-full lg:mb-0'>
						{data?.length > 0 ? (
							<TableComponent
								className='rounded-none rounded-t-0'
								DATA={data}
								COLUMNS={CASE_REGISTRA_COLUMNS}
								TITLE=''
								isExport={null}
								isPaginated={null}
								isSearch={null}
								size={size}
								setSize={setSize}
								setPageIndex={(value: number) => page + 1}
							/>
						) : isLoading === false && data?.length < 1 ? (
							<div className='flex flex-col justify-center items-center h-52'>
								<img src={FOLDER} className='h-20' />
								<p className='text-center text-xs'>No Case Folder available at the moment</p>
							</div>
						) : isLoading ? (
							<Box sx={{ pt: 0.5, pb: 2 }}>
								<Skeleton width='60%' />
								<Skeleton />
								<Skeleton variant='rectangular' width={'100%'} height={100} />
							</Box>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Dashboard;

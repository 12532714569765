import add from '../../../assets/icons/add.svg';
import minus from '../../../assets/icons/minus.svg';
import pdf from '../../../assets/icons/pdf.svg';
import Typography from '../../../components/Typography';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import { MdExpandMore } from 'react-icons/md';

const FAQ = () => {
	const [openfaq, setOpenFaq] = React.useState(false);

	return (
		<div id='faqs' className='bg-brand_hero py-[7%] flex flex-col px-4 lg:px-20 '>
			<Typography variant='h3' textStyle='text-brand_indigo max-m_large:text-lg text-center mb-5'>
				Frequently Asked Questions
			</Typography>

			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>How do I register as a new user?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between '>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px] '>
								- Go to www.familycourt.rv.gov.ng <br />
								- Click on Create Account. <br />
								- Register as a Lawyer or Non-Lawyer.
								<br />
								- Enter your details, then click Submit.
								<br />
								- You will now receive an email to verify your account.
								<br />
								- Click on the email to verify your account.
								<br />
								- You will now be able to login. <br />
								Your username is the email address you used to register.
								<br /> Your password is the password you created during registration.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>How do I begin upload process?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								- Login to your dashboard
								<br />
								- Check the menu and follow the prompts <br />- Upload the documents and click
								submit.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>How do I view my documents?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								- Login to your dashboard.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>How will I know how much to pay?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								You will receive a payment advise.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>How does eFiling work?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								- Electronic filing or eFiling allows lawyers and <br /> non-lawyers to efficiently
								process documents and fees online.
								<br />
								- The user will submit the documents online via the Family Court Platform. <br />
								- After review, the Court will accepthere the user will be asked to pay <br /> fees
								and then a case folder is created.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						Are there fees associated with using eFiling?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								Yes, there are fees associated with filing documents.
								<br />- Every document is assessed by the Registrar and if approved, <br /> a
								payment advice is sent to the filer for ePayment.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						Is the Family Court eFiling platform secure?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								Yes, the platform adheres to state and federal security regulations and meets
								Payment Card Industry Security Standards to protect users information.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>When can I file a document online?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								eFiling is available 24 hours a day, seven days a folder.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>Should a document be password protected?</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								No, documents should not be password protected and will be rejected if the content
								cannot be viewed.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						Are my electronically filed documents printed for the court file?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								No, documents that are electronically filed and accepted by the court are
								automatically uploaded <br />
								to the court's case management system. This allows the court staff and judicial
								officers to <br />
								electronically view the case documents without printing the document or maintaining
								a physical <br />
								court file.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						What are typical reasons that my eFiling would be rejected by the Court?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								- Documents scanned are too large <br />
								- Subsequent filings submitted as new cases <br />- Missing out required information
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						Can I upload documents together or must they be separated?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								Each stand-alone document must be filed separately. <br />- Attachments to a filing
								must be included with that document as one PDF document.
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						What format do I have to save my documents in?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								Save them in PDF format,
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>
			<Accordion>
				<AccordionSummary
					expandIcon={<MdExpandMore />}
					aria-controls='panel1a-content'
					id='panel1a-header'
				>
					<h4 className='py-2 font-semibold text-left'>
						Is there a size limit for which the document can be uploaded?
					</h4>
				</AccordionSummary>
				<AccordionDetails>
					<div className='flex justify-between'>
						<div className='text-left'>
							<p className='text-left text-[10px] leading-loose w-[100%] text-[#1a1a1b] lg:text-[15px]'>
								Yes, .....
							</p>
						</div>
					</div>
				</AccordionDetails>
			</Accordion>

			{/* <div className='bg-[#55B7E8] flex flex-col justify-evenly  mx-[5%] mt-[8%] rounded-lg h-[28vh] lg:flex-row lg:items-center lg:px-[5%] xl:h-[18vh]'>
				<h3 className='text-[#fff] text-center font-extrabold lg:text-3xl lg:text-left xl:w-[50%]'>
					Children’s legal rights before and during any court process.
				</h3>
				<img src={pdf} alt='' className='h-[8vh] cursor-pointer' />
			</div> */}
		</div>
	);
};

export default FAQ;

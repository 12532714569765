import React from "react";
import { buttonPropTypes } from "../../types";

function Button(props: buttonPropTypes) {
  return (
    <div>
      {props.isLoading ? (
        <button
          disabled={props.isLoading}
          type='button'
          className={`w-full px-2 items-center   rounded py-2 mt-[2%] mb-2 inline-flex  justify-center   text-sm font-medium  transition appearance-none cursor-pointer select-none  focus:outline-none focus:ring-2 disabled:pointer-events-none disabled:opacity-75 ${props.bgColor}  ${props.hoverColor} ${props.textColor}  duration-300 text-xs ${props.className}`}
        >
          <svg
            className='w-5 h-5 mr-3 -ml-1 text-black animate-spin'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx={12}
              cy={12}
              r={10}
              stroke='#fff'
              strokeWidth={4}
            />
            <path
              className='opacity-75'
              fill='#fff'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>

          <span className='ml-2 text-xs'>processing...</span>
        </button>
      ) : (
        <button
          type={props.type}
          onClick={props.onClick}
          className={`transition ease-in-out  duration-500 hover:-translate-y-1 hover:scale-105 py-2 px-3 flex justify-center items-center rounded-[10px] ${props.bgColor}  ${props.hoverColor} ${props.textColor} duration-300 text-xs ${props.className}`}
        >
          {props.icons && (
            <img
              src={props.icons}
              alt={props.icons}
              className={`h-4  mr-4 ${props.iconClassName}`}
            />
          )}
          {props.title}
        </button>
      )}
    </div>
  );
}

export default Button;

import axios from 'axios';
import jwt_decode from 'jwt-decode';
import Api from '../config';

// const Api = process.env.REACT_APP_BASE_URL;

class AdminAuthService {
	async login(email: string, password: string) {
		const { data } = await Api.post(`/admin/login`, { email, password });

		return data;
	}

	logout() {
		localStorage.removeItem('token');
	}

	getToken() {
		return localStorage.getItem('token');
	}

	decodeToken() {
		const token = this.getToken();
		if (token) {
			return jwt_decode(token);
		}
		return null;
	}

	isAuthenticated() {
		const token = this.getToken();
		return token !== null;
	}
}

export default new AdminAuthService();

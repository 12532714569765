import React, { useState } from 'react';
import { NavProvider } from '../home/NavigationBar/context/NavbarContext';
import NavBar from '../home/NavigationBar';
import Footer from '../home/Footer';
import Input from '../../components/form/Input';
import Button from '../../components/button';
import { Formik } from 'formik';
import { toast } from 'react-hot-toast';
import { Fade, Zoom } from 'react-reveal';
import Api from '../../config';
import { FOLDER, SEARCH_WHITE } from '../../assets';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import { TableComponent } from '../../components/table/Table';
import { CAUSELIST_COLUMNS, CAUSELIST_COLUMNS_2 } from '../../components/table/columns/causeList';
import moment from 'moment';

function CauseList() {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [causeListData, setCauseListData] = useState<any>();
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [selectedDate, setSelectedDate] = useState<any>();
	const [selectedStartDate, setSelectedStartDate] = useState<any>();

	const handleStartDateChange = (newDate: any) => {
		setSelectedStartDate(newDate.target.value);
	};

	const handleDateChange = (newDate: any) => {
		setSelectedDate(newDate.target.value);
	};

	// Get the current date
	const currentDate = moment();

	// Calculate the start of the week (Sunday)
	const startOfWeek = currentDate.clone().startOf('week');

	// Calculate the end of the week (Saturday)
	const endOfWeek = currentDate.clone().endOf('week');

	// Format the start and end of the week as desired
	const startOfWeekFormatted = startOfWeek.format('YYYY-MM-DD');
	const endOfWeekFormatted = endOfWeek.format('YYYY-MM-DD');

	const handleGetCauseList = (data: any) => {
		setIsLoading(true);
		Api.get(
			`cause-list?name=${data?.name}&suit_number=${data?.suit_number}&title=${
				data?.title
			}&start_date=${selectedStartDate ? selectedStartDate : startOfWeekFormatted}&end_date=${
				selectedDate ? selectedDate : endOfWeekFormatted
			}`
		)
			.then(({ data }) => {
				// Redirect or perform any other action upon successful login
				// console.log(data);
				setCauseListData(data.data);
				toast.success('Retrieved Cause List Information Successfully', {
					duration: 3000, // Duration for which the toast is displayed (in milliseconds)
				});
				setIsLoading(false);
			})
			.catch((error: any) => {
				// console.log(error.response.data.message);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
				setIsLoading(false);
			});
	};

	return (
		<div>
			<NavProvider>
				<div className='sticky top-0  z-50 '>
					<NavBar />
				</div>
				<div className='flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 '>
					<p className='text-center text-xl font-bold'>Cause List</p>

					<Formik
						initialValues={{ judges: '', parties: '', suit: '' }}
						// validationSchema={loginSchema}
						onSubmit={(values, { setSubmitting, resetForm }) => {
							const data = {
								name: values.judges,
								title: values.parties,
								suit_number: values.suit,
							};

							handleGetCauseList(data);
						}}
					>
						{({ handleSubmit, isSubmitting, resetForm }) => (
							<form
								className='flex flex-col pt-3 md:pt-8 w-full lg:w-[70%] mx-auto'
								onSubmit={handleSubmit}
							>
								<Fade left>
									<>
										<div className='flex justify-between items-start flex-wrap w-full'>
											<div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
												<label htmlFor='email' className='text-base pt-4 ml-2 font-semibold'>
													Judges Name
												</label>
												<div className='w-full '>
													<Input type='text' placeholder='Enter Judges Name' name={'judges'} />
												</div>
											</div>
											<div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
												<label htmlFor='email' className='text-base pt-4 ml-2 font-semibold'>
													Parties
												</label>
												<div className='w-full'>
													<Input
														type='text'
														placeholder='Enter Parties to the Case'
														name={'parties'}
													/>
												</div>
											</div>
											<div className='lg:flex flex-col justify-start items-start   w-full lg:w-[32%]'>
												<label htmlFor='email' className='text-base pt-4 ml-2 font-semibold'>
													Suit number
												</label>
												<div className='w-full'>
													<Input type='text' placeholder='Enter Suit Number' name={'suit'} />
												</div>
											</div>
											<div className='lg:flex flex-col justify-start items-start  w-full lg:w-[49%]'>
												<label htmlFor='email' className='text-base pt-4 ml-2 font-semibold'>
													Start date
												</label>
												<input
													name='Start date'
													type='date'
													value={selectedStartDate}
													onChange={handleStartDateChange}
													className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
												/>
											</div>
											<div className='lg:flex flex-col justify-start items-start  w-full lg:w-[49%]'>
												<label htmlFor='End date' className='text-base pt-4 ml-2 font-semibold'>
													End date
												</label>

												<input
													name='End date '
													type='date'
													value={selectedDate}
													onChange={handleDateChange}
													className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200`}
												/>
											</div>
										</div>
										<div className='lg:flex flex-row justify-center items-center mt-10 mb-4 w-full'>
											<Button
												icons={SEARCH_WHITE}
												isLoading={isLoading}
												title={'Search'}
												bgColor={'bg-brand_indigo'}
												textColor={'text-white'}
												className={' mb-2 lg:mb-0  w-52 lg:w-80 py-4 rounded-2xl'}
											/>
										</div>
									</>
								</Fade>
								<Zoom>
									<>
										{causeListData !== undefined && causeListData?.length > 0 && (
											<>
												<div className='mb-6'>
													<p className='text-xl font-bold'>Case Details</p>
												</div>
												<small className='text-gray-400'>Result shown below</small>
											</>
										)}

										{causeListData?.length > 0 && (
											<TableComponent
												className='rounded-none rounded-t-0'
												DATA={causeListData}
												COLUMNS={CAUSELIST_COLUMNS_2}
												TITLE=''
												isExport={null}
												isSearch={null}
												size={size}
												setSize={setSize}
												setPageIndex={(value: number) => page + 1}
												isPaginated={true}
											/>
										)}

										{/* <div className='mb-6'>
											{causeListData !== undefined && causeListData?.length > 0 ? (
												causeListData?.map((item: any) => (
													<>
														<Accordion>
															<AccordionSummary
																expandIcon={<MdExpandMore />}
																aria-controls='panel1a-content'
																id='panel1a-header'
															>
																<div>
																	<h4 className='py-2 font-semibold text-left'>{`Hon ${item?.user?.first_name} ${item?.user?.last_name}`}</h4>

																	<h4 className='py-2 font-medium text-left'>{`${item?.title}`}</h4>
																	<h4 className='py-2 font-medium text-left'>{`${item?.case_folder?.suit_number}`}</h4>

																	<h4 className='py-2 font-medium text-left capitalize'>{`${item?.type} `}</h4>
																	<h4 className='py-2 font-medium text-left capitalize'>
																		{moment(item?.scheduled_at).format('MMM DD, YYYY')}
																	</h4>
																	{item?.court && (
																		<h4 className='py-2 font-medium text-left capitalize'>{`${item?.court} `}</h4>
																	)}
																	{item?.join_url && (
																		<a
																			target='_blank'
																			href={item.join_url}
																			className='px-4  self-end py-1 mr-4 bg-brand_indigo text-center text-[#fff] text-xs  w-20  rounded-md'
																			rel='noreferrer'
																		>
																			Join
																		</a>
																	)}
																</div>
															</AccordionSummary>
														</Accordion>
													</>
												))
											) : causeListData !== undefined && causeListData?.length < 1 ? (
												<div className='flex flex-col justify-center items-center h-52'>
													<img src={FOLDER} className='h-20' alt='' />
													<p className='text-center text-xs'>
														No Case Folder available at the moment
													</p>
												</div>
											) : null}
										</div> */}
									</>
								</Zoom>
							</form>
						)}
					</Formik>
				</div>
				<Footer />
			</NavProvider>
		</div>
	);
}

export default CauseList;

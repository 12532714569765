import { Zoom } from 'react-reveal';
import Modal from '../UI/modal';
import Typography from '../Typography';
import { FormInput } from '../form/Input';

interface modalTypes {
	data: any;
	close: () => void;
	open: boolean;
}
function CaseInformation(props: modalTypes) {
	return (
		<Modal open={props.open} close={props.close} maxWidth='md'>
			<>
				<div className='px-8'>
					<Zoom bottom>
						<div className='px-8  pb-12'>
							<Typography variant='h3'>Case Information</Typography>
							<form className=''>
								<div className='flex justify-between items-center flex-wrap'>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2'>
										<label htmlFor='email' className='text-sm'>
											Case Title
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											placeholder='Enter your first name'
											disabled
											defaultValue={props.data?.title}
										/>
									</div>
									<div className='flex flex-col   w-full lg:w-[48%] mb-2'>
										<label htmlFor='email' className='text-sm'>
											Case Number
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											disabled
											defaultValue={props.data?.case_number}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='lastname' className='text-sm'>
											Is Payment Required?
										</label>
										<FormInput
											isDefault={true}
											required={true}
											type='text'
											disabled
											defaultValue={props.data?.require_payment ? 'Yes' : 'No'}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Suit Number
										</label>
										<FormInput
											isDefault={true}
											type='text'
											disabled
											defaultValue={
												props.data?.suit_number ? props.data?.suit_number : 'Not yet assigned'
											}
										/>
									</div>
									<div className='flex flex-col w-full lg:w-[48%] mb-2'>
										<label htmlFor='othername' className='text-sm'>
											Case Status
										</label>
										<FormInput
											isDefault={true}
											type='text'
											disabled
											defaultValue={props.data?.case_status?.status}
										/>
									</div>
								</div>
							</form>
						</div>
					</Zoom>
				</div>
			</>
		</Modal>
	);
}

export default CaseInformation;

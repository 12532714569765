import axios, { AxiosError } from 'axios';
import { Store } from '../states/store';
import { reset } from '../states/slices/authReducer';
import { resetDoc, resetValue } from '../states/slices/partyInfo.slice';

const Api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL,
});

Api.defaults.headers.common['Accept'] = 'application/json';
Api.defaults.headers.common['Content-Type'] = 'application/json';

//  request interceptor
Api.interceptors.request.use(
	async (config: any) => {
		const token = Store.getState().auths.token;
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// Add response interceptor
Api.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		if (error.response?.status === 401) {
			Store.dispatch(reset()); // Clear token using Redux action
			Store.dispatch(resetDoc()); // Clear token using Redux action
			Store.dispatch(resetValue()); // Clear token using Redux action

			// restore initial auth state
			window.location.href = '/signin';
		}
		return Promise.reject(error);
	}
);

export default Api;

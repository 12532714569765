import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	getSelectedCase,
	getSingleParty,
	reload,
	setReload,
	setToggle,
	setToggle_2,
	toggleState,
	toggleState_2,
} from '../../../states/slices/partyInfo.slice';
import { TableComponent } from '../../../components/table/Table';
import {
	PARTY_COLUMNS,
	PARTY_COLUMNS_Edit,
	PARTY_COLUMNS_view,
} from '../../../components/table/columns';
import Typography from '../../../components/Typography';
import { CHEVRON_LEFT, FLAG, PDF, PLUS, UPLOAD } from '../../../assets';
import { formatCurrency, getJSONheader } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Api from '../../../config';

import { Fade, Zoom } from 'react-reveal';
import { useGetPaymentsQuery } from '../../../services/apis/cases';
import { toast } from 'react-hot-toast';
import EditPartyInfo from '../../../components/modals/editParty';
import UploadDoc from '../../../components/modals/uploadModal';

function EditCases() {
	const getCase = useAppSelector(getSelectedCase);
	const [open_, setOpen_] = useState(false);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const navigate = useNavigate();
	const [paymentID, setPaymentID] = useState<any>();
	const toggleStatus = useAppSelector(toggleState);
	const toggleStatus_2 = useAppSelector(toggleState_2);
	const singlePartyInfo = useAppSelector(getSingleParty);
	const dispatch = useAppDispatch();
	// const reloadNow = useAppSelector(reload);

	const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
		selectFromResult: ({ data, isLoading, isError }) => ({
			paymentData: data?.data,
			isLoadingCases: isLoading,
		}),
	});

	useEffect(() => {
		handlePaymentData(paymentData);
	}, [paymentData]);

	useEffect(() => {
		refetch();
	}, [page]);

	const handlePaymentData = (paymentData: any[]) => {
		paymentData?.map((item: any) => {
			if (item?.service?.id === getCase?.id) {
				// console.log(item, 'ddd');

				setPaymentID(item);
				return item;
			}
		});
	};

	const handleCaseEdit = async () => {
		const formData = {
			// suit_number: getCase?.suit_number,
			parties: getCase?.parties?.map((item: any) => {
				const parties = {
					title: item?.title,
					first_name: item?.first_name,
					last_name: item?.last_name,
					other_name: item?.other_name,
					phone_number: item?.phone_number,
					address: item?.address,
					email: item?.email,
				};

				return parties;
			}),
			documents: getCase?.case_documents?.map((item: any) => {
				const parties = { title: item?.title, file_path: item?.file_path };

				return parties;
			}),
		};

		try {
			const res: any = Api.post(
				`/lawyer/case-folders/case-status/${getCase?.case_status?.id}/resubmit`,
				formData,
				getJSONheader()
			);

			// console.log(res);

			if (res) {
				toast.success(res?.data?.message ?? 'Case folder resubmited', {
					duration: 3000,
				});
				dispatch(setToggle(false));
			}
		} catch (error: any) {
			// console.log(error);
			toast.error(error?.response?.data?.message, {
				duration: 3000,
			});
		}
	};

	const handleDeleteExistingDoc = (docId: string) => {
		let x = 9;
		Api.delete(`/case-documents/${docId}`, getJSONheader()).then((res) => {
			// console.log(res);
			dispatch(setReload(x++));
			navigate('/lawyer/my-files');
			toast.success(res.data.message, {
				duration: 3000,
			});
		});
	};

	return (
		<>
			<div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
				<Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
			</div>

			<div className='pt-4 pb-8 px-8 '>
				<Typography textStyle='text-[20px] font-medium'>Party Information</Typography>

				<div className='my-2'>
					{getCase?.parties?.length > 0 ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getCase?.parties}
							COLUMNS={PARTY_COLUMNS_Edit}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={(value: number) => page + 1}
						/>
					) : (
						<Fade left>
							<div className='mb-8'>
								<Typography variant='label' textStyle='font-medium text-center'>
									No Party Information
								</Typography>
							</div>
						</Fade>
					)}
					<a
						onClick={() => dispatch(setToggle_2(true))}
						className='text-green-700 hover:opacity-70 font-semibold cursor-pointer'
					>
						+ Add a new party information
					</a>
				</div>

				<Typography textStyle='text-[20px] font-medium'>Uploaded Documents</Typography>
				<Zoom>
					{getCase?.case_documents?.length > 0 ? (
						<div className='my-2 lg:flex justify-between items-center flex-wrap '>
							{getCase?.case_documents.map((item: any, index: number) => (
								<>
									<div
										key={index}
										className='flex justify-between items-center mb-3 border border-gray-200 w-full lg:w-[48%] p-3  rounded-lg'
									>
										<div className='flex justify-start items-center '>
											<img src={PDF} className='h-6 w-6' />
											<div className='ml-4'>
												<p className='text-center text-black font-bold text-sm'>Document Title</p>
												<p className='text-left text-gray-400 text-xs mt-1'>{item?.title}</p>
											</div>
										</div>

										<button
											onClick={() => handleDeleteExistingDoc(item?.id)}
											className={`px-2 py-1 text-white text-xs  rounded-md bg-red-600`}
										>
											Delete
										</button>
									</div>
								</>
							))}
						</div>
					) : (
						<div className='mb-6'>
							<Typography variant='label' textStyle='font-medium text-center'>
								No Uploaded Documents
							</Typography>
						</div>
					)}
					<a
						onClick={() => setOpen_(true)}
						className='text-green-700 hover:opacity-70 font-semibold cursor-pointer'
					>
						+ Add a new document
					</a>
				</Zoom>
				<div
					className={`${
						getCase?.case_status?.remark ? 'justify-between' : 'justify-end'
					} flex  items-end`}
				>
					{getCase?.case_status?.remark && (
						<div className='w-full lg:w-[40%]'>
							<Typography textStyle='text-[20px] font-medium'>Remarks</Typography>
							<div className='my-2 flex justify-start items-center '>
								<p className='bg-[#D9D9D91A] overflow-auto min-h-[100px] w-full p-4 text-sm text-gray-600 border border-gray-200'>
									{getCase?.case_status?.remark}
								</p>
							</div>
						</div>
					)}
				</div>
				<div className=' flex justify-between items-end mt-10'>
					<div className='w-[45%] md:w-44'>
						<Button
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/lawyer/my-files')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-full'}
						/>
					</div>

					<div className='w-[53%] md:w-44'>
						<Button
							iconClassName='h-[0.9rem] w-3'
							title='Submit'
							bgColor={'bg-[#27AE60]'}
							onClick={() => handleCaseEdit()}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-full'}
						/>
					</div>
				</div>

				<EditPartyInfo
					open={toggleStatus}
					close={() => dispatch(setToggle(false))}
					data={singlePartyInfo}
					case={getCase}
				/>
				<EditPartyInfo
					open={toggleStatus_2}
					close={() => dispatch(setToggle_2(false))}
					case={getCase}
					isNewParty={true}
				/>
				<UploadDoc open={open_} close={() => setOpen_(false)} data={getCase} />
			</div>
		</>
	);
}

export default EditCases;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { toast } from 'react-hot-toast';

interface FormState {
	partyInfo: any[];
	document: any[];
	case: any;
	toggle: boolean;
	toggle_2: boolean;
	singleParty: {};
	SingleDoc: {};
	reload: number;
	waiver: number;
}
const initialState: FormState = {
	partyInfo: [],
	document: [],
	case: null,
	toggle: false,
	toggle_2: false,
	singleParty: {},
	SingleDoc: {},
	reload: 0,
	waiver: 1,
};

const formSlice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		addValue: (state, action: PayloadAction<any>) => {
			state.partyInfo.push(action.payload);
		},
		removeValue: (state, action: PayloadAction<any>) => {
			const filterPartyItems = state.partyInfo.filter(
				(item) => item.email !== action.payload.email
			);
			state.partyInfo = filterPartyItems;
			toast.error(`${action.payload.first_name} is removed from the party info!`);
		},
		resetValue: (state) => {
			state.partyInfo.length = 0;
		},

		addDoc: (state, action: PayloadAction<any>) => {
			state.document.push(action.payload);
		},
		removeDoc: (state, action: PayloadAction<any>) => {
			const filterDocItems = state?.document?.filter(
				(item) => item?.title !== action.payload?.title
			);
			state.document = filterDocItems;
			toast.error(`${action.payload?.title} is removed from the document upload!`);
		},
		resetDoc: (state) => {
			state.document.length = 0;
		},
		updateCase: (state, action: PayloadAction<any>) => {
			state.case = action.payload;
		},
		setToggle: (state, action: PayloadAction<boolean>) => {
			state.toggle = action.payload;
		},
		setToggle_2: (state, action: PayloadAction<boolean>) => {
			state.toggle_2 = action.payload;
		},
		setSingleParty: (state, action: PayloadAction<any>) => {
			state.singleParty = action.payload;
		},
		setSingleDoc: (state, action: PayloadAction<any>) => {
			state.SingleDoc = action.payload;
		},
		setReload: (state, action: PayloadAction<any>) => {
			state.reload = action.payload;
		},
		setWaiver: (state, action: PayloadAction<any>) => {
			state.waiver = action.payload;
		},
	},
});

export const {
	addValue,
	addDoc,
	resetValue,
	resetDoc,
	updateCase,
	setToggle,
	setToggle_2,
	setSingleParty,
	setSingleDoc,
	setReload,
	removeValue,
	setWaiver,
	removeDoc,
} = formSlice.actions;
export const getPartyInfo = (state: RootState) => state.form.partyInfo;
export const getWaiver = (state: RootState) => state.form.waiver;
export const getSelectedCase = (state: RootState) => state.form.case;
export const getDocument = (state: RootState) => state.form.document;
export const getSingleParty = (state: RootState) => state.form.singleParty;
export const toggleState = (state: RootState) => state.form.toggle;
export const toggleState_2 = (state: RootState) => state.form.toggle_2;
export const getSingleDoc = (state: RootState) => state.form.SingleDoc;
export const reload = (state: RootState) => state.form.reload;
export const formReducer = formSlice.reducer;

import Typography from '../../../components/Typography';
import Button from '../../../components/button';
import { FOLDER, PLUS } from '../../../assets';
import PieCharts from '../../../components/chart/Piechart';
import { TableComponent } from '../../../components/table/Table';
import { CASE_COLUMNS } from '../../../components/table/columns';
import { useEffect, useState } from 'react';
import { useGetAnalyticsQuery, useGetCaseFoldersQuery } from '../../../services/apis/cases';
import CreateCases from '../components/create-cases';
import DropDown from '../../../components/dropdownBtn';
import { Box, Skeleton } from '@mui/material';
import Api from '../../../config';
import { getJSONheader } from '../../../utils';

function Dashboard() {
	const [open, setOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const [completedCaseStat, setCompletedCaseStat] = useState('Year');
	const [ongoinCaseStat, setOngoinCaseStat] = useState('Year');

	const [data, setData] = useState([]);
	const [totalPages, setTotalPages] = useState(0);
	const [isLoading, setLoading] = useState(false);

	const { getanalytics, isLoadingAnalytics, refetch } = useGetAnalyticsQuery('lawyer', {
		selectFromResult: ({ data, isLoading, isError }) => ({
			getanalytics: data?.data,
			isLoadingAnalytics: isLoading,
		}),
	});
	// const { getCaseFolders, loadingCase, fetchCase } = useGetCaseFoldersQuery('lawyer', {
	// 	selectFromResult: ({ data, isLoading, isError }) => ({
	// 		getCaseFolders: data?.data,
	// 		loadingCase: isLoading,
	// 		fetchCase: refetch,
	// 	}),
	// });

	// console.log(getanalytics, 'gro');

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await Api.get(`/lawyer/case-folders`, getJSONheader());
			const { data, meta } = response.data;
			setLoading(false);
			setData(data);
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		fetchData();
	}, [data?.length]);

	return (
		<div className='pt-4 px-8'>
			<Typography textStyle='text-[20px] font-medium'>My Dashboard</Typography>
			<div className='w-full  pb-20 lg:pb-8'>
				<div className='w-full flex flex-row justify-end items-start pl-5 pt-4 lg:pt-0'>
					<Button
						icons={PLUS}
						iconClassName='h-[0.9rem] w-3'
						title='Create Case Folder'
						bgColor={'bg-[#13BAED]'}
						onClick={() => setOpen(true)}
						hoverColor={''}
						textColor={'text-white'}
						className={'py-3'}
					/>
				</div>
				<div className='w-full lg:flex justify-between items-center'>
					<div className='w-full lg:w-[46%] p-8 mb-4 lg:mb-0 flex flex-col  lg:flex-row justify-between items-center shadow-widget rounded-lg'>
						<div className='w-full lg:w-[46%] '>
							<Typography
								variant='label'
								textStyle='text-center lg:text-start lg:px-2  mb-2 lg:mb-0'
							>
								Total Cases Filed
							</Typography>
							<Typography textStyle='text-center lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
								{completedCaseStat === 'Year'
									? getanalytics?.total_case_filed_this_year
									: completedCaseStat === 'Month'
									? getanalytics?.total_case_filed_this_month
									: getanalytics?.total_case_filed_this_week}
							</Typography>
							<div className='flex justify-center lg:justify-start items-center'>
								<DropDown
									{...{
										state: completedCaseStat,
										setState: setCompletedCaseStat,
									}}
								/>
							</div>
						</div>

						<div className='w-full lg:w-[48%] h-52'>
							<div className=' w-full'>
								<PieCharts />
							</div>
						</div>
					</div>
					<div className='w-full lg:w-[46%] p-8 mb-4 lg:mb-0 flex flex-col  lg:flex-row justify-between items-center shadow-widget rounded-lg'>
						<div className='w-full lg:w-[46%] '>
							<Typography
								variant='label'
								textStyle='text-center lg:text-start lg:px-2  mb-2 lg:mb-0'
							>
								On-going Cases
							</Typography>
							<Typography textStyle='text-center lg:text-start px-2  mb-2 lg:mb-0 font-bold text-brand_indigo text-[44px]'>
								{ongoinCaseStat === 'Year'
									? getanalytics?.total_case_outgoing_this_year
									: ongoinCaseStat === 'Month'
									? getanalytics?.total_case_outgoing_this_month
									: getanalytics?.total_case_outgoing_this_week}
							</Typography>

							<DropDown {...{ state: ongoinCaseStat, setState: setOngoinCaseStat }} />
						</div>

						<div className='w-full lg:w-[48%] h-52'>
							<div className=' w-full'>
								<PieCharts />
							</div>
						</div>
					</div>
				</div>

				<div className='mt-8 lg:flex justify-between items-end'>
					<div className=' w-full lg:mb-0'>
						<div className=' border-b  border-[#F0F0F0] rounded-b-xl'>
							{data?.length > 0 ? (
								<TableComponent
									className='rounded-none rounded-t-0'
									DATA={data}
									COLUMNS={CASE_COLUMNS}
									TITLE=''
									isExport={null}
									isPaginated={null}
									isSearch={null}
									size={size}
									setSize={setSize}
									setPageIndex={(value: number) => page + 1}
								/>
							) : isLoading === false && data?.length < 1 ? (
								<div className='flex flex-col justify-center items-center h-52'>
									<img src={FOLDER} className='h-20' />
									<p className='text-center text-xs'>No Case Folder available at the moment</p>
								</div>
							) : isLoading ? (
								<Box sx={{ pt: 0.5, pb: 2 }}>
									<Skeleton width='60%' />
									<Skeleton />
									<Skeleton variant='rectangular' width={'100%'} height={100} />
								</Box>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<CreateCases {...{ open, setOpen }} />
		</div>
	);
}

export default Dashboard;

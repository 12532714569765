import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
	getSelectedCase,
	getSingleParty,
	setToggle,
	setToggle_2,
	toggleState,
	toggleState_2,
} from '../../../states/slices/partyInfo.slice';
import { TableComponent } from '../../../components/table/Table';
import { PARTY_COLUMNS_view, SINGLE_CASE_COLUMNS } from '../../../components/table/columns';
import Typography from '../../../components/Typography';
import { CHEVRON_LEFT, FLAG, MINUS, PDF, PLUS, QUESTION, UPLOAD } from '../../../assets';
import { formatCurrency, getJSONheader } from '../../../utils';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/button';
import Api from '../../../config';
import { useGetPaymentsQuery } from '../../../services/apis/cases';
import { toast } from 'react-hot-toast';
import PartyInformation from '../../../components/modals/Partinformation';
import FillingFee from '../../../components/modals/FillingFee';
import { selectUser } from '../../../states/slices/authReducer';
import { Autocomplete, TextField } from '@mui/material';
import Modal from '../../../components/UI/modal';

function ProcessPayment() {
	const getCase = useAppSelector(getSelectedCase);
	const user = useAppSelector(selectUser);
	const [page, setPage] = useState(1);
	const [size, setSize] = useState(3);
	const navigate = useNavigate();
	const [remark, setRemark] = useState<any>('');
	const [paymentID, setPaymentID] = useState<any>();
	const [remarkRequire, setRemarkRequire] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const toggleStatus = useAppSelector(toggleState);
	const toggleStatus_2 = useAppSelector(toggleState_2);
	const singlePartyInfo = useAppSelector(getSingleParty);
	const dispatch = useAppDispatch();
	const [products, setProducts] = useState<any[]>([getCase?.case_documents]);
	const [totalPrice, setTotalPrice] = useState<number>();
	const [userMagistrateData, setUserMagistrateData] = useState<any>(null);
	const [openDoc, setOpenDoc] = useState(false);
	const [url, setUrl] = useState<any>(null);
	const [sendToUser, setSendToUser] = useState<any>('');

	useEffect(() => {
		fetchDataFromBackend();
	}, [getCase?.case_documents]);

	useEffect(() => {
		if (!userMagistrateData) {
			handleMagistrateUsers();
		}
	}, [userMagistrateData]);

	const handleMagistrateUsers = () => {
		setLoading(true);
		Api.get(`/presiding/magistrates`, getJSONheader()).then((res) => {
			setLoading(false);
			setUserMagistrateData(res.data.data);
		});
	};

	const fetchDataFromBackend = () => {
		// Simulated data from the backend

		const updatedProducts = getCase?.case_documents.map((data: any) => ({
			case_document_id: data.id,
			file_path: data.file_path,
			title: data.title,
			price: 0,
		}));
		// Initialize price as empty string

		setProducts(updatedProducts);
	};

	const { paymentData, isLoadingCases, refetch } = useGetPaymentsQuery(null, {
		selectFromResult: ({ data, isLoading, isError }: any) => ({
			paymentData: data?.data,
			isLoadingCases: isLoading,
		}),
	});

	useEffect(() => {
		handlePaymentData(paymentData);
	}, [paymentData]);

	useEffect(() => {
		refetch();
	}, [page]);

	const handlePaymentData = (paymentData: any[]) => {
		paymentData?.map((item: any) => {
			if (item?.service?.id === getCase?.id) {
				// console.log(item, 'ddd');

				setPaymentID(item);
				return item;
			}
		});
	};

	const handleSubmit = async () => {
		setLoading(true);
		Api.post(
			`/presiding/case-folders/${getCase?.id}/assign`,
			{
				// user_id: user.id,
				user_id: sendToUser?.id,
			},
			getJSONheader()
		)
			.then((res) => {
				toast.success(`${res?.data.message}`, {
					duration: 3000,
				});
				// console.log(res.data);
				setLoading(false);

				navigate('/presiding-judge/case-folders');
			})
			.catch((error: any) => {
				setLoading(false);
				toast.error(error?.response?.data?.message, {
					duration: 3000,
				});
			});
	};

	const handleChangeTo = (event: any, newValue: any) => {
		setSendToUser(newValue);
	};

	const HandleOpenDoc = () => {
		return (
			<Modal
				open={openDoc}
				close={() => {
					setOpenDoc(false);
					setUrl(null);
				}}
				maxWidth='lg'
			>
				<iframe title='PDF Viewer' src={url} className='w-full ' height={900} />
			</Modal>
		);
	};

	return (
		<>
			<div className='bg-[#F8F8F9E5]/90  w-full py-2 px-8'>
				<Typography textStyle='text-base'>{`My Files  / ${getCase?.title}`}</Typography>
			</div>

			<div className='pt-4 px-4 lg:px-8 mb-4'>
				<Typography textStyle='text-base font-medium mb-2'>Case Folder Information</Typography>
				<div className='mt-2 mb-6'>
					{getCase ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={[getCase]}
							COLUMNS={SINGLE_CASE_COLUMNS}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={() => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center mb-6'>
							No Case Folder Information
						</Typography>
					)}
				</div>
				<Typography textStyle='text-base font-medium mb-2'>Party Information</Typography>
				<div className='mt-2 mb-6'>
					{getCase?.parties?.length > 0 ? (
						<TableComponent
							className='rounded-none rounded-t-0'
							DATA={getCase?.parties}
							COLUMNS={PARTY_COLUMNS_view}
							TITLE=''
							isExport={null}
							isPaginated={null}
							isSearch={null}
							size={size}
							setSize={setSize}
							setPageIndex={(value: number) => page + 1}
						/>
					) : (
						<Typography variant='label' textStyle='font-medium text-center mb-6'>
							No Party Information
						</Typography>
					)}
				</div>
				<div className='pt-8'>
					<Typography textStyle='text-base font-medium '>Uploaded Documents</Typography>
					<div className='my-2 lg:flex justify-between items-center flex-wrap '>
						{getCase?.case_documents?.length > 0 ? (
							getCase?.case_documents.map((item: any, index: number) => (
								<div key={index} className='w-full lg:w-[48%] '>
									<div className='flex justify-between items-center mb-3 border border-gray-200  p-3  rounded-lg'>
										<div className='flex justify-start items-center '>
											<img src={PDF} className='h-6 w-6' />
											<div className='ml-4'>
												<p className='text-left text-gray-400 text-xs '>Document Title</p>
												<p className=' text-black font-bold text-sm mt-1'>{item?.title}</p>
											</div>
										</div>

										<p className='text-[#22C55E] text-lg font-medium ml-4'>
											{`${formatCurrency({
												iso: 'en-ng',
												slug: 'NGN',
											}).format(item?.price)}`}
										</p>
									</div>
									<a
										onClick={() => {
											setUrl(item?.file_path);
											setOpenDoc(true);
										}}
										target='_blank'
										className='px-2 py-1 cursor-pointer bg-brand_indigo text-[#fff] text-xs  w-28  rounded-md'
									>
										View only
									</a>
								</div>
							))
						) : (
							<Typography variant='label' textStyle='font-medium text-center'>
								No Uploaded Documents
							</Typography>
						)}
					</div>
				</div>
				<div className='w-1/2 mt-2 mb-4'>
					<label className='text-sm mb-6'>Send to </label>
					{userMagistrateData?.length > 0 && (
						<Autocomplete
							disablePortal
							id='combo-box-demo'
							options={userMagistrateData}
							value={sendToUser}
							onChange={handleChangeTo}
							getOptionLabel={(option: any) => option.first_name ?? 'Select a Personnel'} // Specify the label property to be displayed in the input field
							sx={{ width: '100%' }}
							renderInput={(params) => (
								<TextField {...params} label='Search for required personnel ' />
							)}
							renderOption={(props, option) => (
								<>
									<li {...props}>
										<div>
											<span className='text-sm'>{`${option?.first_name}   ${
												option?.last_name ?? ''
											} `}</span>{' '}
											{/* Render the label */}
											<span style={{ marginLeft: '5px', fontSize: '10px', color: 'gray' }}>
												({option?.role}) {/* Render the year */}
											</span>
										</div>
									</li>
								</>
							)}
						/>
					)}
				</div>
				<div className={` justify-between lg:flex  items-end`}>
					<div className='w-full lg:w-[40%]'>
						<Typography textStyle='text-[20px] font-medium mb-2'>
							<>
								Remarks <sup className='text-red-600 text-xs'>*</sup>
							</>
						</Typography>

						<textarea
							name='remark'
							placeholder={'Kindly add remarks'}
							className='bg-[#13BAED0D] h-40 w-full resize-none flex justify-start p-4 text-sm'
							value={remark}
							onChange={(e) => {
								setRemark(e.target.value);
								setRemarkRequire('');
							}}
						/>
						<small className='text-red-600'>{remarkRequire}</small>
					</div>

					<div className='ml-4 w-full lg:w-[30%] mt-2 lg:mt-0'>
						<Typography textStyle='text-[20px] font-medium text-center'>Total</Typography>

						<p className='text-center text-green-600 bg-[#D9D9D91A] text-2xl text-semibold  p-2  min-w-44 rounded-lg'>
							{formatCurrency({ iso: 'en-ng', slug: 'NGN' }).format(totalPrice ? totalPrice : 0)}
						</p>
					</div>
				</div>
				<div className='w-full md:flex justify-between items-end mt-10'>
					<div className='w-full lg:w-[45%] flex justify-between items-center flex-wrap'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/presiding-magistrate/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 hidden md:flex'}
						/>

						<div className='w-40 md:w-44 md:hidden'>
							<Button
								isLoading={loading}
								icons={FLAG}
								iconClassName='h-[0.9rem] w-3'
								bgColor={'bg-[#27AE60]'}
								title={'Submit Case'}
								onClick={() => handleSubmit()}
								hoverColor={''}
								textColor={'text-white'}
								className={'py-3 w-full'}
							/>
						</div>
					</div>

					<div className='w-[30%] md:w-44 mx-auto lg:mx-0 mt-3 lg:mt-0'>
						<Button
							icons={CHEVRON_LEFT}
							iconClassName='h-[0.9rem] w-3'
							title='Back'
							bgColor={'bg-brand_indigo'}
							onClick={() => navigate('/presiding-magistrate/case-folders')}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-32 md:hidden flex'}
						/>
						<Button
							isLoading={loading}
							icons={FLAG}
							iconClassName='h-[0.9rem] w-3'
							bgColor={'bg-[#27AE60]'}
							title={'Submit Case'}
							onClick={() => handleSubmit()}
							hoverColor={''}
							textColor={'text-white'}
							className={'py-3 w-full hidden md:flex'}
						/>
					</div>
				</div>
				<PartyInformation
					open={toggleStatus}
					close={() => dispatch(setToggle(false))}
					data={singlePartyInfo}
				/>
				<FillingFee open={toggleStatus_2} close={() => dispatch(setToggle_2(false))} />
				{HandleOpenDoc()} {/* Conditionally render the modal */}
			</div>
		</>
	);
}

export default ProcessPayment;

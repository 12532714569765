import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useField, FieldHookConfig } from 'formik';

// interface InputProps {
// 	isPassword?: boolean | null | undefined;
// 	name?: string;
// 	type?: string;
// 	placeholder?: string;
// 	className?: string;
// 	// Additional props specific to the Input component
// 	// You can customize this interface according to your needs
// }
export const FormInput = (props) => {
	return (
		<>
			<div className=' mb-2  relative'>
				<input
					className={`bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`}
					{...props}
					type={props.type ? props.type : 'text'}
				/>
			</div>
		</>
	);
};

const Input = ({ ...props }) => {
	const [show, setShow] = useState(false);
	const [field, meta] = useField(props);
	return (
		<>
			{props.isPassword === true ? (
				<div className=' mb-2 lg:mb-6 relative'>
					<input
						className={`bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`}
						{...field}
						{...props}
						type={show === true ? 'text' : 'password'}
					/>
					{show === true ? (
						<FaEye
							className='absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl'
							onClick={() => setShow(!show)}
						/>
					) : (
						<FaEyeSlash
							className='absolute top-4 right-4 cursor-pointer text-gray-700 text-2xl'
							onClick={() => setShow(!show)}
						/>
					)}
					{meta.touched && meta.error && (
						<span className='error ml-4 text-[12px] text-red-500'>{meta.error}</span>
					)}
				</div>
			) : (
				<div className=' mb-2 lg:mb-6 relative'>
					<input
						className={` bg-[#13BAED0D] border-[#13baed4b] appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 mt-1 leading-tight focus:outline-none focus:shadow-outline placeholder:text-gray-200 ${props.className}`}
						{...field}
						{...props}
						type={'text'}
					/>
					{meta.touched && meta.error && (
						<span className='error ml-4 text-[12px] text-red-500'>{meta.error}</span>
					)}
				</div>
			)}
		</>
	);
};

export default Input;

import {
	Judge_ConcludedCases,
	Judge_Dashboard,
	Judge_MyFiles,
	Judge_Notification,
	Judge_Profile,
} from '../features/magistrate';

import Judges from '../features/magistrate/judges';
import Open from '../features/magistrate/open-judges';
import ProcessPayment from '../features/magistrate/proceed-payment';

export const magistrate_Routes = [
	{
		id: '1',
		title: 'Dashboard',
		component: Judge_Dashboard,
		path: 'magistrate',
	},
	{
		id: '2',
		title: 'Case Folders',
		component: Judge_MyFiles,
		path: 'magistrate/case-folders',
	},
	{
		id: '3',
		title: 'Notification',
		component: Judge_Notification,
		path: 'magistrate/notification',
	},
	{
		id: '4',
		title: 'Profile',
		component: Judge_Profile,
		path: 'magistrate/profile',
	},

	{
		id: '6',
		title: 'Concluded Cases',
		component: Judge_ConcludedCases,
		path: 'magistrate/concluded-cases',
	},
	{
		id: '7',
		title: 'process',
		component: ProcessPayment,
		path: 'magistrate/process',
	},
	{
		id: '8',
		title: 'Cause list',
		component: Judges,
		path: 'magistrate/cause-list',
	},
	{
		id: '9',
		title: 'Open',
		component: Open,
		path: 'magistrate/judges/open',
	},
];
